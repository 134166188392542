import { useEffect, useState } from "react";
import axios from "src/utils/axios";
import {
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Stack,
  Alert,
} from "@mui/material";
import Page from "src/components/common/Page";
import MassTexts from "src/components/MassText/MassTexts";
import Composer from "src/components/MassText/Composer";
import Loader from "src/components/common/Loader";
import { useSocketEvents, SOCKET_EVENTS } from "src/utils/socket";

const getMassTexts = async (page) => {
  try {
    const { data: massTexts } = await axios.get("/api/messages/mass-texts", {
      params: {
        page,
      },
    });

    return massTexts;
  } catch (error) {
    throw error;
  }
};

const MassText = () => {
  const [massTexts, setMassTexts] = useState([]);
  const [massTextsPage, setMassTextsPage] = useState(1);
  const [totalResults, setTotalResults] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [massTextsPageSize, setMassTextsPageSize] = useState(10);
  const [hasError, setHasError] = useState(false);

  const handleGetMassTexts = async (page) => {
    try {
      setIsLoading(true);

      setMassTextsPage(page);

      const {
        massTexts,
        totalResults: updatedTotalResults,
        pageSize,
      } = await getMassTexts(page);
      setMassTexts(massTexts);
      setMassTextsPageSize(pageSize);

      setTotalResults(updatedTotalResults);
    } catch (error) {
      console.error("Error getting mass texts", error);
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateMassTexts = (updatedMassText) => {
    setIsLoading(true);

    setMassTexts((prevMassTexts) => {
      const hasMassText = prevMassTexts.some(
        (prevMassText) => prevMassText?.id === updatedMassText?.id
      );

      if (hasMassText) {
        return prevMassTexts.map((prevMassText) => {
          if (prevMassText.id === updatedMassText.id) {
            return updatedMassText;
          }
          return prevMassText;
        });
      } else {
        return [updatedMassText, ...prevMassTexts];
      }
    });

    setIsLoading(false);
  };

  // Get the initial mass texts
  useEffect(() => {
    (async () => {
      try {
        await handleGetMassTexts(massTextsPage); // Get the initial mass texts
      } catch (error) {
        console.error("Error getting mass texts", error);
        setHasError(true);
      }
    })();
  }, [massTextsPage]);

  const socketEvents = [
    {
      eventName: SOCKET_EVENTS.CREATE_MASS_TEXT,
      handler: (newMassText) => {
        setMassTexts((prevMassTexts) => [newMassText, ...prevMassTexts]);
      },
    },
    {
      eventName: SOCKET_EVENTS.UPDATE_MASS_TEXT,
      handler: handleUpdateMassTexts,
    },
    {
      eventName: SOCKET_EVENTS.DELETE_MASS_TEXT,
      handler: (deletedMassTextIdString) => {
        const deletedMassTextId = parseInt(deletedMassTextIdString);

        setMassTexts((prevMassTexts) => {
          const newMassTexts = prevMassTexts.filter(
            (massText) => massText.id !== deletedMassTextId
          );

          return newMassTexts;
        });
      },
    },
  ];

  useSocketEvents(socketEvents);

  return (
    <Page title="Mass text">
      <Container maxWidth="md" sx={{ p: { xs: 0, sm: 3 } }}>
        <Card>
          <CardHeader
            title="Send Mass Text"
            titleTypographyProps={{ variant: "h4" }}
          />
          <Divider />
          <CardContent>
            <Stack useFlexGap spacing={3}>
              <Composer handleGetMassTexts={handleGetMassTexts} />

              {isLoading && (
                <Stack alignItems="center">
                  <Loader />
                </Stack>
              )}

              {hasError && (
                <Alert severity="error">Error getting mass texts</Alert>
              )}

              {!isLoading && !Boolean(massTexts.length) && (
                <Alert severity="info">No mass texts</Alert>
              )}

              {!hasError && Boolean(massTexts.length) && (
                <MassTexts
                  totalResults={totalResults}
                  massTexts={massTexts}
                  handleGetMassTexts={handleGetMassTexts}
                  currentPage={massTextsPage}
                  pageSize={massTextsPageSize}
                  isLoading={isLoading}
                />
              )}
            </Stack>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default MassText;
