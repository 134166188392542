import { Stack, Typography, FormHelperText, Chip } from "@mui/material";
import DisplayText from "src/components/common/Crm/DisplayText";
import Tooltip from "src/components/common/ToolTip";

const DisplayChips = ({ values, label, otherOption, ...rest }) => {
  return (
    <Stack
      {...rest}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Typography
        sx={{
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
          mb: 0.5,
        }}
        fontWeight={500}
        variant="caption"
      >
        {label}
      </Typography>

      <Stack
        direction="row"
        spacing={0.5}
        sx={{
          mb: 0.5,
          maxWidth: "50%",
          // background: "red",
        }}
      >
        {values?.length ? (
          values.map((value) => (
            <Tooltip key={value.id} title={value.label} placement="top-start">
              <Chip
                label={value.label}
                sx={{
                  fontSize: 12,
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  fontWeight: 400,
                  maxWidth: 50,
                  backgroundColor: "babyBlue",
                }}
              />
            </Tooltip>
          ))
        ) : (
          <DisplayText secondary={"-"} />
        )}
      </Stack>
      {Boolean(otherOption?.length) && (
        <>
          <Tooltip
            arrow
            title={otherOption.length > 20 ? otherOption : ""}
            placement="top-start"
          >
            <Stack direction="row" alignItems="center">
              <Typography
                sx={{
                  fontSize: 12,
                }}
                variant="body2"
              >
                Other:
              </Typography>
              {"  "}
              <FormHelperText
                sx={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  mt: 0,
                  lineHeight: "100%",
                  color: (theme) => theme.palette.text.secondary,
                }}
              >
                {otherOption}
              </FormHelperText>
            </Stack>
          </Tooltip>
        </>
      )}
    </Stack>
  );
};

export default DisplayChips;
