import { Typography, Divider, Stack } from "@mui/material";
import Loader from "src/components/common/Loader";
import PropTypes from "prop-types";

const BoxContainer = ({
  children,
  isLoading,
  hasError,
  errorMessage,
  opacity,
  sx,
}) => {
  const isCenter = isLoading || hasError;
  return (
    <Stack
      style={{ opacity }}
      sx={{
        justifyContent: isCenter && "center",
        alignItems: isCenter && "center",
        ...sx,
      }}
    >
      {/* LOADING STATE */}
      {isLoading && <Loader />}

      {/* ERROR STATE */}
      {hasError && !isLoading && (
        <Typography color="error" align="center" variant="body2">
          {errorMessage}
        </Typography>
      )}

      {!isLoading && !children && (
        <Typography variant="body1" align="center">
          No items found
        </Typography>
      )}

      {!hasError && !isLoading && children}
    </Stack>
  );
};

export default BoxContainer;

BoxContainer.propTypes = {
  children: PropTypes.node,
  isLoading: PropTypes.bool.isRequired,
  hasError: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  opacity: PropTypes.number.isRequired,
};
