import { Box, Container } from "@mui/material";

const ContentWrapper = ({ children, ...rest }) => {
  return (
    <Container
      sx={{
        flex: 1,
        display: "flex",
        justifyContent: "center",
        pt: {
          xs: 10,
          md: 30,
        },
        px: {
          xs: 0,
          md: "auto",
        },
        backgroundColor: "primary.main",
      }}
      maxWidth={false}
      {...rest}
    >
      <Box
        sx={{
          position: "relative",
          maxWidth: {
            xs: "none",
            md: "720px",
          },
          minHeight: "400px",
          width: "100%",
          p: {
            xs: "30px 20px",
            sm: "30px 80px",
          },
          mb: {
            xs: 0,
            md: "92px",
          },
          backgroundColor: "background.form",
        }}
      >
        {children}
      </Box>
    </Container>
  );
};

export default ContentWrapper;
