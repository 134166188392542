import { useState } from "react";
import { Divider, Stack, Typography, IconButton } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";

import moment from "moment";
import axios from "src/utils/axios";
import ConfirmationModal from "src/components/Chat/ConfirmationModal";

const MassTexts = ({
  currentPage,
  massTexts,
  handleGetMassTexts,
  pageSize,
  isLoading,
}) => {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] =
    useState(false);
  const [deleteMassTextId, setDeleteMassTextId] = useState(null);
  const [sendBatch, setSendBatch] = useState({});

  const handleSendMassTextBatch = async () => {
    try {
      await axios.post("/api/messages/mass-text/send-batch", sendBatch);

      setIsConfirmModalOpen(false);
    } catch (error) {
      toast.error(`Error sending mass text`);
    }
  };

  const handleDeleteMassText = async () => {
    try {
      await axios.delete(`/api/messages/mass-text/${deleteMassTextId}`);
      handleGetMassTexts(1);

      setIsConfirmDeleteModalOpen(false);
      setDeleteMassTextId(null);
    } catch (error) {
      toast.error(`Error deleting mass text`);
    }
  };

  const isMassTextSending = massTexts.some((massText) => massText?.isSending);

  return (
    <Stack>
      {Boolean(massTexts.length) && (
        <Stack spacing={2} divider={<Divider />}>
          {massTexts.map((massText) => (
            <Stack spacing={1} key={massText.id}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography>
                  Created on {`${moment(massText.createdAt).format("l")}`}
                </Typography>
                <IconButton
                  onClick={() => {
                    setDeleteMassTextId(massText.id);
                    setIsConfirmDeleteModalOpen(true);
                  }}
                  aria-label="close"
                >
                  <DeleteIcon />
                </IconButton>
              </Stack>
              <Stack direction="row" spacing={1}>
                <Stack
                  direction="row"
                  px={1}
                  py={2}
                  sx={{
                    background: (theme) => theme.palette.grey[200],
                    width: "100%",
                  }}
                >
                  <Typography>{massText.body}</Typography>
                </Stack>
              </Stack>
              <Stack direction="row" gap={2} flexWrap="wrap">
                {Object.keys(massText.batches).map((key) => {
                  const batch = massText.batches[key];
                  return (
                    <Stack
                      key={key}
                      spacing={1}
                      sx={{
                        minWidth: "200px",
                      }}
                    >
                      <Typography variant="h5">Batch #{key}</Typography>
                      <Divider />
                      <Stack
                        direction="row"
                        gap={2}
                        justifyContent="space-between"
                      >
                        <Typography sx={{ color: "grey100" }} variant="body2">
                          # Clients
                        </Typography>
                        <Typography>{batch.clients.length}</Typography>
                      </Stack>

                      <Stack
                        gap={2}
                        direction="row"
                        justifyContent="space-between"
                      >
                        <Typography sx={{ color: "grey100" }} variant="body2">
                          Completed Date
                        </Typography>
                        <Typography>{batch.completedDate || "-"}</Typography>
                      </Stack>

                      <LoadingButton
                        disabled={batch.hasSent || isMassTextSending}
                        loading={batch.isSending}
                        onClick={() => {
                          setIsConfirmModalOpen(true);
                          setSendBatch({
                            massTextId: massText.id,
                            batchKey: key,
                          });
                        }}
                        variant="contained"
                      >
                        Send
                      </LoadingButton>
                    </Stack>
                  );
                })}
              </Stack>
            </Stack>
          ))}
          <Stack
            spacing={1}
            direction="row"
            divider={<Divider />}
            justifyContent="center"
          >
            <LoadingButton
              loading={isLoading}
              color="primary"
              variant="contained"
              disabled={!(currentPage * pageSize === massTexts.length)}
              onClick={() => handleGetMassTexts(currentPage + 1)}
            >
              Load More
            </LoadingButton>
          </Stack>
        </Stack>
      )}

      <ConfirmationModal
        content="Are you sure you want to delete this mass text?"
        ctaText="Delete"
        isModalOpen={isConfirmDeleteModalOpen}
        handleSendMessageConfirmation={() => handleDeleteMassText()}
        handleSetConfirmationModal={setIsConfirmDeleteModalOpen}
      />

      <ConfirmationModal
        content="Are you sure you want to send this batch?"
        ctaText="Send"
        isModalOpen={isConfirmModalOpen}
        handleSendMessageConfirmation={() => handleSendMassTextBatch()}
        handleSetConfirmationModal={setIsConfirmModalOpen}
      />
    </Stack>
  );
};

export default MassTexts;
