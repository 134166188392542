import { createContext, useReducer } from "react";
import axios from "src/utils/axios";
import { toast } from "react-toastify";

const initialState = {
  isLoading: false,
  areThreadsHidden: false,
  threads: { threadItems: [], count: 0 },
  recipient: null,
  messageSearchResults: null,
  messagePage: { current: 1, total: 0 },
  selectedMessage: { id: null, queryPhoneNumber: null },
  textQuery: null,
  filterByUserType: "-",
};

export const ChatContext = createContext();

const chatReducer = (state, action) => {
  switch (action.type) {
    case "SET_IS_LOADING":
      return {
        ...state,
        isLoading: action.payload,
      };
    case "SET_ARE_THREADS_HIDDEN":
      return {
        ...state,
        areThreadsHidden: action.payload,
      };
    case "SET_THREADS":
      return {
        ...state,
        threads: action.payload,
      };
    case "SET_RECIPIENT":
      return {
        ...state,
        recipient: action.payload,
      };
    case "SET_MESSAGE_SEARCH_RESULTS":
      return {
        ...state,
        messageSearchResults: action.payload,
      };
    case "SET_SELECTED_MESSAGE":
      return {
        ...state,
        selectedMessage: action.payload,
      };
    case "SET_TEXT_QUERY":
      return {
        ...state,
        textQuery: action.payload,
      };
    case "SET_FILTER_BY_USER_TYPE":
      return {
        ...state,
        filterByUserType: action.payload,
      };
    case "SET_MESSAGE_PAGE":
      return {
        ...state,
        messagePage: action.payload,
      };
    case "RESET_SEARCH":
      return {
        ...state,
        messageSearchResults: initialState.messageSearchResults,
        messagePage: initialState.messagePage,
        selectedMessage: initialState.selectedMessage,
        textQuery: initialState.textQuery,
        filterByUserType: initialState.filterByUserType,
      };
    default:
      return state;
  }
};

const ChatContextProvider = (props) => {
  const [chatState, dispatch] = useReducer(chatReducer, initialState);

  const messagePageSize = 100;

  const setIsLoading = (isLoading) => {
    dispatch({ type: "SET_IS_LOADING", payload: isLoading });
  };

  const setAreThreadsHidden = (areThreadsHidden) => {
    dispatch({ type: "SET_ARE_THREADS_HIDDEN", payload: areThreadsHidden });
  };

  const setThreads = (newThreads) => {
    dispatch({ type: "SET_THREADS", payload: newThreads });
  };

  const setRecipient = (newRecipient) => {
    dispatch({ type: "SET_RECIPIENT", payload: newRecipient });
  };

  const setMessageSearchResults = (newMessageSearchResults) => {
    dispatch({
      type: "SET_MESSAGE_SEARCH_RESULTS",
      payload: newMessageSearchResults,
    });
  };

  const setSelectedMessage = (newSelectedMessage) => {
    dispatch({ type: "SET_SELECTED_MESSAGE", payload: newSelectedMessage });
  };

  const setTextQuery = (newTextQuery) => {
    handleSearchMessages({
      input: newTextQuery,
      filterByUserType: chatState.filterByUserType,
    });
    dispatch({ type: "SET_TEXT_QUERY", payload: newTextQuery });
  };

  const setFilterByUserType = (newFilterByUserType) => {
    handleSearchMessages({
      input: chatState.textQuery,
      filterByUserType: newFilterByUserType,
    });
    dispatch({
      type: "SET_FILTER_BY_USER_TYPE",
      payload: newFilterByUserType,
    });
  };

  const setMessagePage = (newMessagePage) => {
    dispatch({ type: "SET_MESSAGE_PAGE", payload: newMessagePage });
  };

  const resetSearch = () => {
    dispatch({ type: "RESET_SEARCH" });
  };

  const handleGetThreads = async ({ threadLimit }) => {
    try {
      const { data: threads } = await axios.get("/api/clients/threads", {
        params: { limit: threadLimit },
      });
      return threads;
    } catch (error) {
      return {
        threadItems: [],
        count: 0,
      };
    }
  };

  const handleReadMessage = async ({ clientId }) => {
    try {
      await axios.put("/api/messages/is-read", { clientId });
    } catch (error) {
      toast.error(`Error setting message to read`);
      return null;
    }
  };

  const handleGetMessages = async ({
    phoneNumber,
    messageId,
    pageNumber,
    limit,
    query,
  }) => {
    try {
      const { data: message } = await axios.get("/api/messages", {
        params: { phoneNumber, messageId, pageNumber, limit, ...query },
      });
      return message;
    } catch (error) {
      return { messages: [], count: null };
    }
  };

  const handleGetRecipient = async (phoneNumber) => {
    try {
      const { data: recipient } = await axios.get("/api/clients/threads", {
        params: { phoneNumber },
      });
      return recipient;
    } catch (error) {
      toast.error(`Error getting recipient`);
      return null;
    }
  };

  const handleSaveDraft = async (message, recipient) => {
    if (message === "") return;

    const clientId = recipient?.id;

    try {
      await axios.post(`/api/clients/${clientId}/draft`, {
        message: message,
      });

      toast.success("Your draft has been saved successfully!");
    } catch (error) {
      console.log(error);
      toast.error("Unable to save draft");
    }
  };

  const handleDeleteDraft = async (recipient) => {
    try {
      const clientId = recipient?.id;

      await axios.delete(`/api/clients/${clientId}/draft`);
    } catch (error) {
      console.log(error);
      toast.error("Unable to delete draft");
    }
  };

  const handleSearchMessages = async ({
    input,
    filterByUserType,
    pageNumber = 1,
    isMore = false,
  }) => {
    try {
      pageNumber === 1 && setIsLoading(true);
      if (!input && filterByUserType === "-") {
        setMessageSearchResults(null);
        setSelectedMessage(null);
        return;
      }

      const query = {
        search: input ? input : "",
        "filter-by-user-type": filterByUserType === "-" ? "" : filterByUserType,
        pageNumber: pageNumber,
        limit: messagePageSize,
      };
      const { messages, count } = await handleGetMessages({ query });
      // Add highlight markings to message bodies
      if (query?.search !== "") {
        messages.forEach((message) => {
          let { body } = message;
          const searchExp = new RegExp(query?.search, "gi");
          body = body.replaceAll(searchExp, (match) => `<mark>${match}</mark>`);
          message.body = body;
        });
      }
      // totalMessagePagesRef.current = count;
      const moreMessages = isMore
        ? [...(chatState.messageSearchResults || []), ...messages]
        : messages;
      setMessageSearchResults(moreMessages);
      const newMessagePage = {
        current: isMore ? pageNumber : 1,
        total: count,
      };
      setMessagePage(newMessagePage);
    } catch (error) {
      setIsLoading(false);
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  const value = {
    ...chatState,
    setIsLoading,
    setAreThreadsHidden,
    setThreads,
    setRecipient,
    setMessageSearchResults,
    setSelectedMessage,
    setTextQuery,
    setFilterByUserType,
    setMessagePage,
    handleGetThreads,
    handleReadMessage,
    handleGetMessages,
    handleGetRecipient,
    handleSaveDraft,
    handleDeleteDraft,
    handleSearchMessages,
    resetSearch,
    messagePageSize,
  };

  return (
    <ChatContext.Provider value={value}>{props.children}</ChatContext.Provider>
  );
};

const ChatContextConsumer = ChatContext.Consumer;

export { ChatContextProvider, ChatContextConsumer };
