import TagManager from "react-gtm-module";

const submitToGADataLayer = ({ event, userId, isTest, formFieldName }) => {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID,
    dataLayer: {
      event,
      userId,
      isTest,
      form_field_name: formFieldName,
    },
  };

  TagManager.initialize(tagManagerArgs);
};

export default submitToGADataLayer;
