import {
  Typography,
  Divider,
  Box,
  RadioGroup,
  FormControlLabel,
  Stack,
  Radio,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import useDeviceBreakpoint from "src/utils/useDeviceBreakpoint";

const useStyles = makeStyles((theme) => ({
  error: {
    border: `1px solid ${theme.palette.error.muiError}`,
  },
  icon: {
    borderRadius: "50%",
    width: 27,
    height: 27,
    backgroundColor: theme.palette.background.default,
  },
  checkedIcon: {
    "&:before": {
      display: "block",
      width: 27,
      height: 27,
      backgroundImage: `radial-gradient(${theme.palette.primary.main},${theme.palette.primary.main} 50%,transparent 55%)`,
      content: '""',
    },
  },
}));

const Row = ({
  classes,
  label,
  name,
  options,
  onChange,
  isMobile,
  hasDivider,
  hasError,
}) => {
  return (
    <>
      <Stack
        sx={{
          flexDirection: {
            xs: "column",
            sm: "row",
          },
          alignItems: {
            xs: "flex-start",
            sm: "center",
          },
          justifyContent: "space-between",
        }}
      >
        <Typography variant="brandonBody1">{label}</Typography>

        <RadioGroup
          row
          sx={{
            justifyContent: {
              xs: "space-between",
              sm: "flex-end",
            },
            width: {
              xs: "100%",
              sm: "auto",
            },
          }}
          onChange={(e) => onChange({ ...e, name })}
          name={name}
        >
          {/* TODO: enable bottom placement on mobile and pass option as label */}
          {options.map((option, index) => {
            return (
              <FormControlLabel
                key={index}
                sx={{
                  margin: 0,
                  "& .MuiButtonBase-root": {
                    padding: {
                      xs: "9px 9px",
                      sm: "9px 15px",
                    },
                  },
                }}
                control={
                  <Radio
                    disableRipple
                    key={option}
                    name={option}
                    value={option}
                    checkedIcon={
                      <span
                        className={clsx(classes.icon, classes.checkedIcon)}
                      />
                    }
                    icon={
                      <span
                        className={clsx(
                          hasError && classes.error,
                          classes.icon
                        )}
                      />
                    }
                  />
                }
                labelPlacement="bottom"
                label={
                  isMobile && (
                    <Typography variant="brandonBody2">{option}</Typography>
                  )
                }
              />
            );
          })}
        </RadioGroup>
      </Stack>
      {hasDivider && <Divider sx={{ borderColor: "black" }} />}
    </>
  );
};

const ColumnSelect = ({ title, subTitle, options, rows, onChange, errors }) => {
  const classes = useStyles();
  const { isMobile } = useDeviceBreakpoint();

  const handleChange = (e) => {
    onChange(e.name, e.target.value);
  };

  const errorsArray = Object.keys(errors);

  return (
    <Stack spacing={1}>
      <Typography paragraph variant="brandonTitle">
        {title}
      </Typography>
      <Typography variant="brandonBody1">{subTitle}</Typography>

      {!isMobile && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          {options.map((option) => (
            <Typography
              component="span"
              variant="brandonTitle"
              textAlign="center"
              key={option}
              sx={{
                minWidth: 57,
                mb: 0,
              }}
            >
              {option}
            </Typography>
          ))}
        </Box>
      )}

      {rows.map((row, index) => {
        return (
          <Row
            hasError={errorsArray.some((error) => error === row.name)}
            key={row.label}
            classes={classes}
            label={row.label}
            name={row.name}
            options={options}
            onChange={handleChange}
            isMobile={isMobile}
            hasDivider={rows.length > index + 1}
          />
        );
      })}
    </Stack>
  );
};
export default ColumnSelect;
