import React, { useContext } from "react";
import { ChatContext } from "src/context/ChatContext";
import { Box, MenuItem, Select, InputLabel, FormControl } from "@mui/material";

const Filter = () => {
  const { filterByUserType, setFilterByUserType } = useContext(ChatContext);

  return (
    <Box p={1}>
      <FormControl fullWidth variant="outlined">
        <InputLabel sx={{ zIndex: 0 }} htmlFor="message-filter">
          Filter By
        </InputLabel>
        <Select
          id="message-filter"
          label="Filter By"
          value={filterByUserType}
          onChange={(event) => {
            setFilterByUserType(event.target.value);
          }}
        >
          <MenuItem value="-">-</MenuItem>
          <MenuItem value="client">Client</MenuItem>
          <MenuItem value="coach">Coach</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

export default Filter;
