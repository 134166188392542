import PropTypes from "prop-types";
import CheckboxSelect from "./CheckboxSelect";
import { Typography, FormGroup } from "@mui/material";
import { parseInt } from "lodash";

const MultiSelect = ({
  heading,
  options,
  onChange,
  values,
  subHeadingEl,
  hasError,
  onFocus,
}) => {
  const handleChange = (data) => {
    const { checked, value: selectedId } = data;

    const selectedOption = options.find(
      (option) => option.id === parseInt(selectedId)
    );

    let newValues = [...(values ? [...values] : [])];

    if (checked) {
      newValues.push(selectedOption);
    } else {
      newValues = values.filter(
        (currentValue) => currentValue.id !== selectedOption.id
      );
    }

    onChange(newValues);
  };

  return (
    <>
      <Typography component="h6" variant="brandonTitle">
        {heading}
      </Typography>
      {subHeadingEl}
      <FormGroup
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1.625, // 13px
        }}
      >
        {options?.map((option) => {
          const isChecked = values?.some((value) => value.id === option.id);
          return (
            <CheckboxSelect
              value={option.id}
              key={option.label}
              label={option.label}
              isChecked={isChecked}
              onChange={handleChange}
              hasError={hasError}
              onFocus={onFocus}
            />
          );
        })}
      </FormGroup>
    </>
  );
};

export default MultiSelect;

MultiSelect.propTypes = {
  values: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  heading: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  subHeadingEl: PropTypes.element,
  hasError: PropTypes.bool,
  onFocus: PropTypes.func,
};
