import CloseIcon from "@mui/icons-material/Close";
import CommentModalForm from "./CommentModalForm";
import { Dialog, DialogTitle, DialogContent, IconButton } from "@mui/material";

const CommentModal = ({ client, isOpen, setIsOpen }) => {
  return (
    <Dialog
      open={isOpen}
      sx={{ width: "100%" }}
      onClose={() => setIsOpen(false)}
    >
      <IconButton
        aria-label="close"
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
        }}
        onClick={() => setIsOpen(false)}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle
        sx={{
          pt: "2rem",
          pb: "0",
        }}
        variant="h4"
      >
        {`Add Comment for ${client?.displayName}`}
      </DialogTitle>
      <DialogContent>
        <CommentModalForm client={client} setIsOpen={setIsOpen} />
      </DialogContent>
    </Dialog>
  );
};

export default CommentModal;
