import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  wellbeingPoint: Yup.object().shape({
    mindset: Yup.number().required("Required").typeError("Required"),
    physical: Yup.number().required("Required").typeError("Required"),
    emotional: Yup.number().required("Required").typeError("Required"),
    social: Yup.number().required("Required").typeError("Required"),
    financial: Yup.number().required("Required").typeError("Required"),
  }),
  wellnessStatuses: Yup.array().required("Required").min(1),
  age: Yup.number()
    .min(18, "Must be at least 18 years old")
    .max(100, "Invalid age")
    .required("Required")
    .integer()
    .typeError("Required"),
  resources: Yup.array().required("Required").min(1),
  priorDiagnoses: Yup.array().required("Required").min(1),
  otherPriorDiagnosis: Yup.string().when("priorDiagnoses", {
    is: (priorDiagnoses) => {
      const check = priorDiagnoses.some((diagnosis) =>
        ["Not listed"].some((label) => diagnosis.label === label)
      );

      if (check) {
        return true;
      }
    },
    then: Yup.string().required("Required").typeError("Required"),
    otherwise: Yup.string().nullable().optional(),
  }),
  currentTreatments: Yup.array().required("Required").min(1),
  ivfCycleId: Yup.number().required("Required").typeError("Required"),
  journeyDifficulties: Yup.array().required("Required").min(1),
  pregnancyAmount: Yup.string().required("Required").typeError("Required"),
  liveBirthAmount: Yup.string().required("Required").typeError("Required"),
  miscarriageAmount: Yup.string().required("Required").typeError("Required"),
});

export default validationSchema;
