import { Box, Typography, TextareaAutosize } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  textareaContainer: {
    position: "relative",
    width: "100%",
    marginBottom: 10,
  },
  label: {
    position: "absolute",
    lineHeight: "20px",
    fontSize: 10,
    fontWeight: 400,
    color: "#263238",
    left: 16,
    top: -10,
    background: "#ffffff",
    padding: "0 4px",
  },
  textarea: {
    width: "100%",
    border: "1px solid rgba(0, 0, 0, 0.15)",
    outline: "none",
    fontFamily: "Cereal, serif",
    lineHeight: "23px",
    fontSize: 16,
    fontWeight: 400,
    resize: "none",
    padding: "18px 10px 8px 16px",
  },
  textareaError: {
    border: "1px solid red",
  },
}));

const Textarea = ({
  name,
  value,
  label,
  onChange,
  hasError,
  maxWidth,
  onBlur = () => {},
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.textareaContainer} maxWidth={maxWidth || "none"}>
      {Boolean(label?.length) && (
        <Typography className={classes.label}>{label}</Typography>
      )}
      <TextareaAutosize
        name={name}
        value={value}
        onChange={onChange}
        className={clsx(classes.textarea, {
          [classes.textareaError]: hasError,
        })}
        minRows={5}
        onBlur={onBlur}
      />
    </Box>
  );
};

export default Textarea;
