import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import axios from "src/utils/axios";
import {
  Box,
  Card,
  CardHeader,
  TablePagination,
  Backdrop,
  CircularProgress,
  Alert,
} from "@mui/material";
import { toast } from "react-toastify";
import CommentModal from "../CommentModal";
import { ClientsContext } from "src/context/ClientsContext";
import { DataGrid } from "@mui/x-data-grid";
import Filters from "src/components/Clients/Filters";
import Columns from "src/components/Clients/Columns";

const ClientTable = ({ title, pageRef }) => {
  const {
    clientsResult,
    clientsError,
    isLoading,
    filters,
    setPage,
    setFollowUpDateSort,
    handleGetClients,
  } = useContext(ClientsContext);
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
  const [commentClient, setCommentClient] = useState(null);

  const handleHeaderClick = (column) => {
    switch (column.field) {
      case "followUpDate":
        const newSort = filters.followUpDateSort === "ASC" ? "DESC" : "ASC";
        setFollowUpDateSort(newSort);
        break;
      default:
        break;
    }
  };

  const handleEditFollowUpDate = async (value, clientId) => {
    try {
      await axios.put(`/api/clients/${clientId}`, {
        followUpDate: value,
      });

      handleGetClients();
      toast.success(`Follow up date updated successfully`);
    } catch (error) {
      toast.error(`Error updating follow up date`);
      throw error;
    }
  };

  const handleSetPage = (newPage) => {
    pageRef.current.scrollIntoView();

    setPage(newPage);
  };

  const tableColumns = Columns({
    setIsCommentModalOpen,
    setCommentClient,
    handleEditFollowUpDate,
  });

  return (
    <>
      <Card>
        <CardHeader
          title={title}
          titleTypographyProps={{ variant: "h4" }}
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",
            gap: 2,
            "& .MuiCardHeader-action": {
              display: "flex",
            },
          }}
          action={
            <>
              <Filters />
              <TablePagination
                component="div"
                count={parseInt(clientsResult.total) || 0}
                rowsPerPage={100}
                rowsPerPageOptions={[]}
                page={filters.page - 1}
                onPageChange={(e, newPage) => {
                  // Inital page is 0, so we need to add 1
                  handleSetPage(newPage + 1);
                }}
                labelRowsPerPage=""
              />
            </>
          }
        />

        <PerfectScrollbar>
          <Box minWidth={1200} position={"relative"}>
            {clientsError && (
              <Alert severity="error" sx={{ mb: 2, justifyContent: "center" }}>
                Unable to get clients
              </Alert>
            )}
            {Boolean(clientsResult.clients) && !clientsError && (
              <DataGrid
                rows={clientsResult.clients}
                columns={tableColumns}
                page={filters.page}
                rowsPerPage={100}
                filterMode="server"
                sortingMode="server"
                sortModal={[
                  {
                    field: "followUpDate",
                    sort: filters.followUpDateSort.toLowerCase(),
                  },
                ]}
                rowCount={parseInt(clientsResult.total)}
                onColumnHeaderClick={handleHeaderClick}
                disableColumnMenu
                autoHeight
                loading={isLoading}
                loadingOverlay={
                  <Backdrop open={isLoading}>
                    <CircularProgress
                      sx={{
                        position: "absolute",
                        top: 0,
                      }}
                      color="primary"
                    />
                  </Backdrop>
                }
                pagination
                sx={{
                  "& .MuiTablePagination-root": {
                    display: "none",
                  },
                  "& .MuiDataGrid-columnSeparator": {
                    color: "divider",
                  },
                  "& .MuiDataGrid-withBorderColor": {
                    borderColor: "divider",
                  },
                }}
              />
            )}
          </Box>
        </PerfectScrollbar>
      </Card>
      <CommentModal
        client={commentClient}
        isOpen={isCommentModalOpen}
        setIsOpen={(isOpen) => setIsCommentModalOpen(isOpen)}
      />
    </>
  );
};

ClientTable.propTypes = {
  title: PropTypes.string,
};

export default ClientTable;
