import { useState, useEffect } from "react";
import axios from "src/utils/axios";
import { toast } from "react-toastify";
import { Card, CardHeader, CardContent, Divider, Box } from "@mui/material";
import BoxContainer from "src/components/common/Crm/BoxContainer";
import { useSocketEvents, SOCKET_EVENTS } from "src/utils/socket";

import StatusDisplay from "./StatusDisplay";
import StatusForm from "./StatusForm";

const getClientStatuses = async (clientId) => {
  try {
    const { data: clientStatus } = await axios.get(
      `/api/clients/${clientId}/statuses`
    );

    return clientStatus;
  } catch (error) {
    throw error;
  }
};

const createClientStatus = async ({ clientId, type, status }) => {
  try {
    const { data: clientStatus } = await axios.post(
      `/api/clients/${clientId}/statuses/${type}`,
      status
    );
    return clientStatus;
  } catch (error) {
    console.error(error);
    toast.error("Failed to create client status");
  }
};

const initialClientStatuses = {
  atReStatus: {
    label: "At RE",
    type: "atReStatus",
    status: null,
  },
  atObgynStatus: {
    label: "At OBGYN",
    type: "atObgynStatus",
    status: null,
  },
  pregnantStatus: {
    label: "Pregnant",
    type: "pregnantStatus",
    status: null,
  },
  journeyPathStatus: {
    label: "Journey Path",
    type: "journeyPathStatus",
    status: null,
  },
};

const Status = ({ clientId }) => {
  const [clientStatuses, setClientStatuses] = useState(initialClientStatuses);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [editStatusType, setEditStatusType] = useState(null);

  const handleSetClientStatuses = (statuses) => {
    const newStatuses = Object.keys(initialClientStatuses).reduce(
      (acc, key) => {
        acc[key] = {
          ...initialClientStatuses[key],
          status: statuses?.[key]?.status || null,
        };
        return acc;
      },
      {}
    );

    setClientStatuses(newStatuses);
  };

  const handleSubmit = async (payload, resetForm) => {
    try {
      setIsSubmitting(true);
      const { type, ...status } = payload;

      await createClientStatus({ clientId, type, status });

      const newStatuses = await getClientStatuses(clientId);
      handleSetClientStatuses(newStatuses);

      resetForm();
      setEditStatusType(null);
    } catch (error) {
      console.error(error);
      toast.error("Failed to create client status");
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const clientStatuses = await getClientStatuses(clientId);

        handleSetClientStatuses(clientStatuses);
      } catch (error) {
        console.error(error);
        setHasError(true);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [clientId]);

  const socketHandleGetClientStatuses = async () => {
    try {
      const clientStatuses = await getClientStatuses(clientId);
      handleSetClientStatuses(clientStatuses);
    } catch (error) {
      console.error(error);
      setHasError(true);
    }
  };

  useSocketEvents(
    [
      {
        eventName: SOCKET_EVENTS.UPDATE_CLIENT_STATUS,
        handler: socketHandleGetClientStatuses,
      },
    ],
    [clientId]
  );

  return (
    <Card elevation={1}>
      <CardHeader title="Current Status" />
      <Divider />
      <CardContent>
        <BoxContainer
          isLoading={isLoading}
          hasError={hasError}
          errorMessage="Failed to load client status"
          opacity={isSubmitting ? 0.5 : 1}
        >
          {Object.values(clientStatuses).map((clientStatus) => {
            const { type, label, status } = clientStatus || {};

            if (editStatusType === type) {
              return (
                <StatusForm
                  key={type}
                  clientId={clientId}
                  type={type}
                  status={status}
                  setEditStatusType={setEditStatusType}
                  handleSubmit={handleSubmit}
                />
              );
            } else {
              return (
                <StatusDisplay
                  key={type}
                  type={type}
                  label={label}
                  status={status}
                  editStatusType={editStatusType}
                  setEditStatusType={setEditStatusType}
                />
              );
            }
          })}
        </BoxContainer>
      </CardContent>
    </Card>
  );
};

export default Status;
