import { useContext } from "react";
import { OptionContext } from "src/context/OptionContext";
import { Button, Stack } from "@mui/material";
import SingleSelect from "src/components/common/SingleSelect";
import { Formik } from "formik";
import * as Yup from "yup";
import { isEqual } from "lodash";

const validationSchema = Yup.object().shape({
  atObgynStatusId: Yup.number().required("Required"),
});

const AtObgynStatusForm = ({ status, setEditStatusType, handleSubmit }) => {
  const { atObgynStatusOptions } = useContext(OptionContext);
  const initialValues = {
    type: "at-obgyn-status",
    atObgynStatusId: status?.id || null,
  };

  return (
    <Formik
      validationSchema={validationSchema}
      enableReinitialize
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={initialValues}
      resetForm={{ values: initialValues }}
      onSubmit={(payload, { resetForm }) => handleSubmit(payload, resetForm)}
    >
      {({
        values,
        errors,
        setFieldError,
        handleSubmit,
        setFieldValue,
        isValid,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Stack alignItems="flex-end" spacing={1} useFlexGap sx={{ my: 1 }}>
              <SingleSelect
                id="at-obgyn-status"
                label="At Obgyn Status"
                options={atObgynStatusOptions}
                value={values.atObgynStatusId}
                onChange={(value) => {
                  setFieldValue("atObgynStatusId", value);
                  setFieldError("atObgynStatusId", undefined);
                }}
                hasError={Boolean(errors.atObgynStatusId)}
              />
              <Stack direction="row" spacing={1} alignItems="center">
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={() => setEditStatusType(null)}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={Boolean(!isValid || isEqual(initialValues, values))}
                  type="submit"
                >
                  Save
                </Button>
              </Stack>
            </Stack>
          </form>
        );
      }}
    </Formik>
  );
};

export default AtObgynStatusForm;
