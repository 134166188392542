import { useRef } from "react";
import { useParams } from "react-router-dom";
import Page from "src/components/common/Page";
import SurveyForm from "src/components/Forms/SurveyForm";
import ClientHeader from "src/components/common/ClientHeader";

const Survey = () => {
  const pageRef = useRef(null);
  const { clientId, surveyId } = useParams();

  return (
    <Page
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
      title="Survey Form"
      ref={pageRef}
    >
      <ClientHeader />

      <SurveyForm clientId={clientId} surveyId={surveyId} />
    </Page>
  );
};

export default Survey;
