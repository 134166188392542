import { Typography, Stack } from "@mui/material";

const Heading = ({ title, subtitle, ...rest }) => {
  return (
    <Stack
      mb={3}
      sx={{
        alignItems: "center",
      }}
      {...rest}
    >
      <Typography
        className="title"
        variant="brandonH1"
        sx={{
          mb: {
            xs: 2,
            md: 3,
          },
          position: "relative",
          zIndex: 2,

          "&:after": {
            content: "''",
            position: "absolute",
            top: "50%",
            left: "50%",
            bottom: 4,
            transform: {
              xs: "translate(-50%, -50%) skew(20deg)",
              sm: "translateX(-50%) skew(20deg)",
            },
            width: "273px",
            height: {
              xs: "34px",
              sm: "19px",
            },
            backgroundColor: "darkBeige",
            zIndex: "-1",
          },
        }}
      >
        {title}
      </Typography>

      <Typography
        className="subtitle"
        variant="brandonBody1"
        sx={{
          maxWidth: {
            xs: "320px",
            sm: "464px",
          },
          m: "0 auto",
          mb: {
            xs: 5,
            md: 4,
          },
          textAlign: {
            xs: "center",
          },
        }}
      >
        {subtitle} <b>Let&apos;s get started.</b>
      </Typography>
    </Stack>
  );
};

export default Heading;
