import {
  FormControl,
  OutlinedInput,
  FilledInput,
  Typography,
  FormHelperText,
} from "@mui/material";

const TextInput = ({
  name,
  onChange,
  error,
  value: initialValue,
  label,
  multiline,
  minRows,
  maxRows,
  inputRef,
  variant = "outlined",
  type = "text",
  rows,
  placeholder,
  inputProps,
  errorText = "Required",
  ...props
}) => {
  const value = initialValue || (type === "number" ? null : "");

  return (
    <FormControl fullWidth>
      {!!label && (
        <Typography
          sx={{
            fontSize: {
              xs: 16,
              md: 18,
            },
            lineHeight: {
              xs: "22px",
              md: "26px",
            },
            mb: "10px",
            fontFamily: "Brandon",
            fontWeight: 600,
          }}
        >
          {label}
        </Typography>
      )}
      {/* TODO: Replace Outlined and FilledInput with TextInput and use condition to render the variant */}
      {variant === "outlined" && (
        <OutlinedInput
          type={type}
          inputRef={inputRef}
          sx={{
            "&.Mui-error": {
              outline: (theme) => `1x solid ${theme.palette.error.main}`,
            },
            background: "white",
            ...props.sx,
          }}
          multiline={multiline}
          rows={rows}
          minRows={minRows}
          maxRows={maxRows}
          name={name}
          value={value}
          error={error}
          onChange={onChange}
          placeholder={placeholder}
          inputProps={inputProps}
          {...props}
        />
      )}
      {variant === "filled" && (
        <FilledInput
          type={type}
          inputRef={inputRef}
          sx={{
            backgroundColor: "background.default",
            "& fieldset": {
              border: "none !important",
            },
            "&.Mui-error": {
              outline: (theme) => `2px solid ${theme.palette.error.main}`,
            },
            ...props.sx,
          }}
          multiline={multiline}
          rows={rows}
          maxRows={maxRows}
          name={name}
          minRows={minRows}
          value={value}
          error={error}
          onChange={onChange}
          placeholder={placeholder}
          inputProps={inputProps}
          {...props}
        />
      )}

      {variant == "form" && (
        <OutlinedInput
          type={type}
          inputRef={inputRef}
          sx={{
            "&.Mui-error": {
              outline: (theme) => `1x solid ${theme.palette.error.main}`,
            },
            background: "white",
            borderRadius: 0,
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            ...(error && {
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "red",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "red",
              },
            }),

            ...props.sx,
          }}
          multiline={multiline}
          rows={rows}
          minRows={minRows}
          maxRows={maxRows}
          name={name}
          value={value}
          error={error}
          onChange={onChange}
          placeholder={placeholder}
          inputProps={inputProps}
          {...props}
        />
      )}
    </FormControl>
  );
};

export default TextInput;
