import { FormControlLabel, Checkbox, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

const checkMark =
  "<svg width='19' height='16' viewBox='0 0 19 16' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M19 3.37343L6.9084 16L0 8.86221L3.23 5.41139L6.9084 9.25512L15.77 0L19 3.37343Z' fill='#731F4A'/></svg>";

const checkMark64 = window.btoa(checkMark);

const useStyles = makeStyles((theme) => ({
  check: {
    "&:hover": {
      backgroundColor: "transparent !important",
    },
  },
  icon: {
    width: 27,
    height: 27,
    backgroundColor: "#ffffff",
  },
  checkedIcon: {
    "&:before": {
      display: "block",
      width: 27,
      height: 27,
      backgroundImage: "url('data:image/svg+xml;base64," + checkMark64 + "')",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center center",
      content: '""',
    },
  },
  error: {
    border: "1px solid red",
  },
}));

const CheckboxSelect = ({
  label,
  isChecked = false,
  onChange,
  value,
  hasError,
  onFocus,
}) => {
  const classes = useStyles();

  const handleChange = (e) => {
    const data = { checked: e.target.checked, value: e.target.value, label };
    onChange(data);
  };
  return (
    <FormControlLabel
      sx={{
        display: "flex",
        alignItems: "flex-start",
        gap: 1, // 8px
      }}
      value={value}
      onFocus={onFocus}
      control={
        <Checkbox
          color="primary"
          checked={isChecked}
          onChange={handleChange}
          disableRipple
          className={classes.check}
          checkedIcon={
            <span className={clsx(classes.icon, classes.checkedIcon)} />
          }
          icon={
            <span className={clsx(hasError && classes.error, classes.icon)} />
          }
        />
      }
      label={
        <Typography
          sx={{
            fontFamily: "Brandon",
            fontSize: 18,
            alignSelf: "center",
            mt: "3px",
          }}
        >
          {label}
        </Typography>
      }
      labelPlacement="end"
    />
  );
};
export default CheckboxSelect;
