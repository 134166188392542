import { Button, Link } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  checkButton: {
    width: 176,
    backgroundColor: "#000000",
    padding: "14px 0",
    color: "#ffffff",
    borderRadius: "0px",
    fontSize: 15,
    lineHeight: "20px",
    fontFamily: "Brandon",
    fontWeight: 500,

    "&:hover": {
      backgroundColor: "#000000",
    },
  },
}));

const ClientButton = ({ label, href, target, ...rest }) => {
  const classes = useStyles();
  return (
    <Link href={href} target={target} rel="noreferrer">
      <Button {...rest} className={classes.checkButton}>
        {label}
      </Button>
    </Link>
  );
};

export default ClientButton;
