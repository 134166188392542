import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";

const FormSuccess = ({ formName }) => (
  <Box
    justifyContent="center"
    height="100%"
    display="flex"
    alignItems="center"
    textAlign="center"
    px={4}
  >
    {formName !== "survey" && (
      <Typography variant="h4">
        Thanks for submitting the {formName}! We're reviewing it and will follow
        up with you shortly. Feel free to text us questions in the meantime!
      </Typography>
    )}

    {formName === "survey" && (
      <Typography variant="h4">
        Thanks for submitting your feedback!
        <br />
        Feel free to text us with any questions.
      </Typography>
    )}
  </Box>
);

export default FormSuccess;

FormSuccess.propTypes = {
  formName: PropTypes.string.isRequired,
};
