import { FormControlLabel, Radio, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import ToolTip from "src/components/common/ToolTip";
import { HelpOutline as QuestionMarkIcon } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  error: {
    border: `1px solid ${theme.palette.error.muiError}`,
  },
  icon: {
    borderRadius: "50%",
    width: 27,
    height: 27,
    backgroundColor: theme.palette.background.default,
  },
  checkedIcon: {
    "&:before": {
      display: "block",
      width: 27,
      height: 27,
      borderRadius: "50%",
      backgroundImage: `radial-gradient(${theme.palette.primary.main},${theme.palette.primary.main} 50%,transparent 55%)`,
      content: '""',
    },
  },
}));

const RadioSelect = ({
  label,
  description = "",
  value,
  hasError,
  tooltip = "",
}) => {
  const classes = useStyles();

  return (
    <FormControlLabel
      value={value}
      sx={{
        my: 0.7,
        display: "flex",
        alignItems: "center",
        gap: 1,
      }}
      label={
        <Stack
          direction={tooltip ? "row" : "column"}
          useFlexGap
          spacing={tooltip && 0.5}
          sx={{ alignItems: tooltip ? "center" : "flex-start" }}
        >
          <Typography
            sx={{
              fontFamily: "Brandon",
              fontSize: 18,
              display: "flex",
              alignItems: "center",
            }}
          >
            {label}
          </Typography>
          {Boolean(description) && (
            <Typography
              sx={{
                fontFamily: "Brandon",
                fontSize: 14,
              }}
            >
              {description}
            </Typography>
          )}
          {Boolean(tooltip) && (
            <ToolTip title={tooltip || ""} placement="top">
              <QuestionMarkIcon fontSize="inherit" />
            </ToolTip>
          )}
        </Stack>
      }
      control={
        <Radio
          disableRipple
          color="default"
          checkedIcon={
            <span className={clsx(classes.icon, classes.checkedIcon)} />
          }
          icon={
            <span className={clsx(hasError && classes.error, classes.icon)} />
          }
        />
      }
    />
  );
};
export default RadioSelect;
