import { IconButton, SvgIcon } from "@mui/material";
import PropTypes from "prop-types";
import { DatePicker as MUIDatePicker } from "@mui/x-date-pickers";
import { useEffect, useState } from "react";
import isPastDate from "src/utils/isPastDate";
import { CalendarToday, Clear } from "@mui/icons-material";
import { getLocalDateFromDateString } from "src/utils/get-local-date-from-date-string";

const DatePicker = ({
  fontSize,
  value: initialDate,
  label,
  handleChange,
  hasError,
  variant,
  placeholder,
  minDate,
}) => {
  const [date, setDate] = useState(null);
  const [error, setError] = useState(false);
  const [openPicker, setOpenPicker] = useState(false);

  const handleClear = () => {
    setDate(null);
    handleChange(null);
  };

  useEffect(() => {
    let startDate = initialDate || null;

    if (initialDate && typeof initialDate == "string") {
      startDate = getLocalDateFromDateString(initialDate);
    }

    setDate(startDate);
  }, [initialDate]);

  useEffect(() => {
    if (date && minDate) {
      setError(isPastDate(date));
      return;
    }
  }, [date, minDate]);

  useEffect(() => {
    setError(hasError);
  }, [hasError]);

  const handleCalendarIconClick = () => {
    setOpenPicker(true);
  };

  const DateIcons = () => {
    return (
      <>
        {Boolean(date) && (
          <IconButton
            size="small"
            sx={{ color: "black" }}
            onClick={handleClear}
          >
            <SvgIcon fontSize="small">
              <Clear />
            </SvgIcon>
          </IconButton>
        )}
        <IconButton
          size="small"
          color="primary"
          id="clearIcon"
          onClick={handleCalendarIconClick}
        >
          <SvgIcon fontSize="small">
            <CalendarToday />
          </SvgIcon>
        </IconButton>
      </>
    );
  };

  return (
    <MUIDatePicker
      label={label}
      sx={{
        width: "100%",
        position: "relative",
        zIndex: 1,
      }}
      open={openPicker}
      value={date}
      minDate={minDate}
      onAccept={(date) => handleChange(date)}
      fullWidth
      inputFormat="MM/dd/yyyy"
      onClose={() => setOpenPicker(false)}
      slots={{
        inputAdornment: DateIcons,
      }}
      slotProps={{
        textField: {
          readOnly: true,
          sx: {
            width: "100%",
            "& .MuiInputBase-root": {
              color: error && "error.main",
              fontSize: fontSize || "1rem",
            },
            "& fieldset": {
              border: variant === "outlined" ? "1px solid" : "none",
              borderColor: error ? "error.main" : "grey40",
            },
          },
          placeholder: placeholder,
        },
      }}
    />
  );
};

DatePicker.propTypes = {
  label: PropTypes.string,
  initialDate: PropTypes.any,
  placeholder: PropTypes.string,
  handleChange: PropTypes.func,
  variant: PropTypes.string,
  hasError: PropTypes.bool,
  minDate: PropTypes.object,
};

DatePicker.defaultProps = {
  placeholder: "",
  hasError: false,
  initialDate: null,
  label: "",
  variant: "standard",
  minDate: null,
};

export default DatePicker;
