import React, { useRef, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container, Grid, Typography, Box } from "@mui/material";

import {
  useSocketEvents,
  useSocketRooms,
  SOCKET_ROOMS,
  SOCKET_EVENTS,
} from "src/utils/socket";
import Page from "src/components/common/Page";
import Info from "src/components/Client/Info";
import Details from "src/components/Client/Details";
import TopicSelect from "src/components/Client/TopicSelect";

import VirtualSessions from "src/components/Client/VirtualSessions";
import Status from "src/components/Client/Status";
import ClientJourney from "src/components/Client/ClientJourney";
import Forms from "src/components/Client/Forms";
import Loader from "src/components/common/Loader";
import axios from "src/utils/axios";

const getClient = async (clientId) => {
  try {
    const { data: client } = await axios.get(`/api/clients/${clientId}`);
    return client;
  } catch (error) {
    throw error;
  }
};

const Client = () => {
  const pageRef = useRef(null);
  const { clientId } = useParams();

  const [client, setClient] = useState(null);
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleGetClient = async (clientId) => {
    const client = await getClient(clientId);

    if (!client) {
      setHasError(true);
      return;
    }
    setClient(client);
  };

  useEffect(() => {
    (async () => {
      try {
        await handleGetClient(clientId);
      } catch (error) {
        setHasError(true);
      } finally {
        setIsLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId]);

  // call back function for the socket event to refetch client data
  const socketHandleGetClient = async () => {
    await handleGetClient(clientId);
  };

  // Event Listener for any server events to update client data listed below

  useSocketRooms(
    [
      {
        roomName: SOCKET_ROOMS.JOIN_CONVERSATION,
        roomId: clientId,
      },
    ],
    clientId
  );

  useSocketEvents(
    [
      {
        eventName: SOCKET_EVENTS.UPDATE_CLIENT_PAGE,
        handler: socketHandleGetClient,
      },
    ],
    clientId
  );

  const {
    createdAt,
    isActive,
    inactiveReason,
    firstName,
    displayName,
    email,
    phoneNumber,
    zipCode,
    age,
    journeyStage,
    ttcDuration,
    ageRange,
    ethnicIdentities,
    genderIdentity,
    reDuration,
    clinicAmount,
    ivfCycle,
    miscarriageAmount,
    liveBirthAmount,
    pregnancyAmount,
    hasInsurance,
    resources,
    wellnessStatuses,
    currentTreatments,
    priorDiagnosises,
    otherPriorDiagnosis,
    journeyDifficulties,
    insuranceBenefits,
    bmi,
    height,
    weight,
    topics,
    subtopics,
    followUpDate,
    agent,
    forms,
    threadFlags,
    tags,
    hasOBGYN,
    hasInsuranceBenefit,
    hasRE,
    relationshipStatus,
    signupAtReStatus,
    signupAtObgynStatus,
  } = client || {};

  return (
    <Page
      sx={{
        py: 2,
      }}
      title={`${displayName || "Client"}`}
      ref={pageRef}
    >
      <Container sx={{ p: 0 }}>
        {hasError && !isLoading && (
          <Typography align="center" error>
            Client not found
          </Typography>
        )}

        {!client && isLoading && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight={300}
          >
            <Loader />
          </Box>
        )}

        {client && !isLoading && (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Info
                firstName={firstName}
                clientId={clientId}
                displayName={displayName}
                email={email}
                phoneNumber={phoneNumber}
                zipCode={zipCode}
                isActive={isActive}
                inactiveReason={inactiveReason}
                createdAt={createdAt}
                followUpDate={followUpDate}
                agent={agent}
                threadFlags={threadFlags}
                tags={tags}
              />
            </Grid>

            <Grid item xs={12}>
              <Details
                journeyStage={journeyStage?.label || ""}
                ttcDuration={ttcDuration}
                age={age}
                ageRange={ageRange}
                ethnicIdentities={ethnicIdentities}
                genderIdentity={genderIdentity}
                reDuration={reDuration}
                clinicAmount={clinicAmount}
                ivfCycle={ivfCycle}
                miscarriageAmount={miscarriageAmount}
                liveBirthAmount={liveBirthAmount}
                pregnancyAmount={pregnancyAmount}
                hasInsurance={hasInsurance}
                wellnessStatuses={wellnessStatuses}
                resources={resources}
                currentTreatments={currentTreatments}
                priorDiagnosises={priorDiagnosises}
                otherPriorDiagnosis={otherPriorDiagnosis}
                journeyDifficulties={journeyDifficulties}
                insuranceBenefits={insuranceBenefits}
                bmi={bmi}
                height={height}
                weight={weight}
                hasOBGYN={hasOBGYN}
                hasInsuranceBenefit={hasInsuranceBenefit}
                hasRE={hasRE}
                relationshipStatus={relationshipStatus}
                signupAtReStatus={signupAtReStatus}
                signupAtObgynStatus={signupAtObgynStatus}
              />
            </Grid>

            <Grid
              container
              item
              lg={7}
              sm={12}
              spacing={1}
              alignContent="flex-start"
            >
              <Grid item xs={12} md={6}>
                <Status clientId={clientId} />
              </Grid>

              <Grid item xs={12} md={6}>
                <TopicSelect
                  topics={topics}
                  subtopics={subtopics}
                  clientId={clientId}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <VirtualSessions clientId={clientId} />
              </Grid>

              <Grid item md={6} xs={12}>
                <Forms forms={forms} clientId={clientId} />
              </Grid>
            </Grid>

            <Grid
              container
              item
              spacing={1}
              lg={5}
              sm={12}
              alignItems="flex-start"
            >
              <Grid item xs={12}>
                <ClientJourney clientId={clientId} displayName={displayName} />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Container>
    </Page>
  );
};

export default Client;
