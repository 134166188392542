import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, useTheme } from "@mui/material";
import NavBar from "src/components/common/NavBar";
import TopBar from "src/components/common/TopBar";

const DashboardLayout = ({ children, hasActions }) => {
  const theme = useTheme();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  return (
    <Box
      sx={{
        backgroundColor: "background.dark",
        display: "flex",
        height: "100%",
        overflow: "hidden",
        width: "100%",
      }}
    >
      <TopBar onMobileNavOpen={() => setMobileNavOpen(!isMobileNavOpen)} />
      {!hasActions && (
        <>
          <NavBar
            onMobileClose={() => setMobileNavOpen(false)}
            openMobile={isMobileNavOpen}
          />
          <Box
            sx={{
              display: "flex",
              flex: "1 1 auto",
              overflow: "hidden",
              paddingTop: theme.spacing(8),
            }}
          >
            <Box
              sx={{
                flex: "1 1 auto",
                height: "100%",
                overflow: "auto",
              }}
            >
              {children}
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node,
  hasActions: PropTypes.bool,
};

DashboardLayout.defaultProps = {
  hasActions: false,
};

export default DashboardLayout;
