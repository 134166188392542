import { useEffect, useContext } from "react";
import { UserContext } from "src/context/UserContext";
import { SOCKET_EVENTS } from "../constants";

/**
 * @param {{ eventName?: SOCKET_EVENTS, handler: CallableFunction }[]} socketEvents - Array of objects with name and handler properties
 * @param {any} dependency - Dependency to watch for changes
 */
const useSocketEvents = (socketEvents, dependency = null) => {
  const { socket } = useContext(UserContext);

  const handleAddSocketEventListener = (name, handler) => {
    socket.on(name, handler);
  };

  const handleRemoveSocketEventListener = (name, handler) => {
    socket.off(name, handler);
  };

  useEffect(() => {
    if (!socket) return;
    if (dependency !== null && !dependency) return;

    socketEvents.forEach(({ eventName, handler }) => {
      if (!eventName) {
        console.error(
          "Connection eventName is required for each socket connection"
        );
        return;
      }

      if (Object.values(SOCKET_EVENTS).includes(eventName)) {
        handleAddSocketEventListener(eventName, handler);
      } else {
        console.error(`Event name: ${eventName} is not a valid socket event`);
      }
    });

    return () => {
      socketEvents.forEach(({ eventName, handler }) => {
        handleRemoveSocketEventListener(eventName, handler);
      });
    };
  }, [socket, ...(Array.isArray(dependency) ? dependency : [dependency])]); // eslint-disable-line
};

export default useSocketEvents;
