import axios from "src/utils/axios";
import { createContext, useEffect, useReducer, useContext } from "react";
import { toast } from "react-toastify";
import { UserContext } from "src/context/UserContext";

const initialOption = {
  ageRangeOptions: [],
  activePhysicianOptions: [],
  currentTreatmentOptions: [],
  threadFlagOptions: [],
  agentOptions: [],
  journeyStageOptions: [],
  journeyUpdateEventOptions: [],
  tagOptions: [],
  topicOptions: [],
  subtopicOptions: [],
  notAtReReasonOptions: [],
  noReReasonOptions: [],
  stoppedReReasonOptions: [],
  journeyPathStatusOptions: [],
  pregnantStatusOptions: [],
  atReStatusOptions: [],
  atObgynStatusOptions: [],
};

const optionReducer = (state, action) => {
  switch (action.type) {
    case "SET_AGE_RANGE_OPTIONS":
      return {
        ...state,
        ageRangeOptions: action.payload,
      };

    case "SET_ACTIVE_PHYSICIAN_OPTIONS":
      return {
        ...state,
        activePhysicianOptions: action.payload,
      };

    case "SET_CURRENT_TREATMENT_OPTIONS":
      return {
        ...state,
        currentTreatmentOptions: action.payload,
      };

    case "SET_AGENT_OPTIONS":
      return {
        ...state,
        agentOptions: action.payload,
      };

    case "SET_THREAD_FLAG_OPTIONS":
      return {
        ...state,
        threadFlagOptions: action.payload,
      };

    case "SET_JOURNEY_STAGE_OPTIONS":
      return {
        ...state,
        journeyStageOptions: action.payload,
      };

    case "SET_JOURNEY_EVENT_OPTIONS":
      return {
        ...state,
        journeyEventOptions: action.payload,
      };

    case "SET_TAG_OPTIONS":
      return {
        ...state,
        tagOptions: action.payload,
      };
    case "SET_TOPIC_OPTIONS":
      return {
        ...state,
        topicOptions: action.payload,
      };
    case "SET_SUBTOPIC_OPTIONS":
      return {
        ...state,
        subtopicOptions: action.payload,
      };
    case "SET_NOT_AT_RE_REASON_OPTIONS":
      return {
        ...state,
        notAtReReasonOptions: action.payload,
      };
    case "SET_NO_RE_REASON_OPTIONS":
      return {
        ...state,
        noReReasonOptions: action.payload,
      };
    case "SET_STOPPED_RE_REASON_OPTIONS":
      return {
        ...state,
        stoppedReReasonOptions: action.payload,
      };
    case "SET_JOURNEY_PATH_STATUS_OPTIONS":
      return {
        ...state,
        journeyPathStatusOptions: action.payload,
      };
    case "SET_PREGNANT_STATUS_OPTIONS":
      return {
        ...state,
        pregnantStatusOptions: action.payload,
      };
    case "SET_AT_RE_STATUS_OPTIONS":
      return {
        ...state,
        atReStatusOptions: action.payload,
      };
    case "SET_AT_OBGYN_STATUS_OPTIONS":
      return {
        ...state,
        atObgynStatusOptions: action.payload,
      };
    default:
      return state;
  }
};

export const OptionContext = createContext();

const OptionContextProvider = (props) => {
  const userState = useContext(UserContext);
  const [options, optionDispatch] = useReducer(optionReducer, initialOption);

  // GET PRIVATE OPTIONS:
  useEffect(() => {
    if (!userState.agent) return;
    (async () => {
      const privateSlugs = [
        "age-range", // 0
        "active-physician", // 1
        "current-treatment", // 2
        "thread-flag", // 3
        "journey-stage", // 4
        "journey-event", // 5
        "tag", // 6
        "topic", // 7
        "subtopic", // 8
        "not-at-re-reason", // 9
        "no-re-reason", // 10
        "stopped-re-reason", // 11
        "journey-path-status", // 12
        "pregnant-status", // 13
        "at-re-status", // 14
        "at-obgyn-status", // 15
      ];

      const promises = privateSlugs.map((slug) =>
        axios.get(`/api/crm/options/${slug}`)
      );

      const results = await Promise.allSettled(promises);

      results.forEach((result, index) => {
        if (result.status === "fulfilled") {
          const payload = [{ id: null, label: "-" }, ...result.value.data];
          switch (index) {
            case 0:
              optionDispatch({ type: "SET_AGE_RANGE_OPTIONS", payload });
              break;
            case 1:
              optionDispatch({
                type: "SET_ACTIVE_PHYSICIAN_OPTIONS",
                payload: result.value.data,
              });
              break;
            case 2:
              optionDispatch({
                type: "SET_CURRENT_TREATMENT_OPTIONS",
                payload: result.value.data,
              });
              break;
            case 3:
              optionDispatch({
                type: "SET_THREAD_FLAG_OPTIONS",
                payload: result.value.data,
              });
              break;
            case 4:
              optionDispatch({ type: "SET_JOURNEY_STAGE_OPTIONS", payload });
              break;
            case 5:
              optionDispatch({ type: "SET_JOURNEY_EVENT_OPTIONS", payload });
              break;
            case 6:
              optionDispatch({
                type: "SET_TAG_OPTIONS",
                payload: result.value.data,
              });
              break;
            case 7:
              optionDispatch({
                type: "SET_TOPIC_OPTIONS",
                payload: result.value.data,
              });
              break;
            case 8:
              optionDispatch({
                type: "SET_SUBTOPIC_OPTIONS",
                payload: result.value.data,
              });
              break;
            case 9:
              optionDispatch({
                type: "SET_NOT_AT_RE_REASON_OPTIONS",
                payload: result.value.data,
              });
              break;
            case 10:
              optionDispatch({
                type: "SET_NO_RE_REASON_OPTIONS",
                payload: result.value.data,
              });
              break;
            case 11:
              optionDispatch({
                type: "SET_STOPPED_RE_REASON_OPTIONS",
                payload: result.value.data,
              });
              break;
            case 12:
              optionDispatch({
                type: "SET_JOURNEY_PATH_STATUS_OPTIONS",
                payload: result.value.data,
              });
              break;
            case 13:
              optionDispatch({
                type: "SET_PREGNANT_STATUS_OPTIONS",
                payload: result.value.data,
              });
              break;
            case 14:
              optionDispatch({
                type: "SET_AT_RE_STATUS_OPTIONS",
                payload: result.value.data,
              });
              break;
            case 15:
              optionDispatch({
                type: "SET_AT_OBGYN_STATUS_OPTIONS",
                payload: result.value.data,
              });
              break;
            default:
              break;
          }
        } else {
          toast.error(`Error getting ${privateSlugs[index]} options`);
        }
      });

      try {
        const { data: agents } = await axios.get(`/api/agents`, {
          params: {
            role: "Coach",
          },
        });

        if (agents?.length === 0) {
          throw new Error("No agents found");
        }

        optionDispatch({
          type: "SET_AGENT_OPTIONS",
          payload: [{ id: null, name: "-" }, ...agents],
        });
      } catch (error) {
        toast.error(`Error getting agent options`);
        console.error("Error getting agent options", error.message);
      }
    })();
  }, [userState.agent]);

  return (
    <OptionContext.Provider value={options}>
      {props.children}
    </OptionContext.Provider>
  );
};

const OptionContextConsumer = OptionContext.Consumer;

export { OptionContextProvider, OptionContextConsumer };
