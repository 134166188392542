import { useEffect, useContext } from "react";
import { UserContext } from "src/context/UserContext";
import { SOCKET_ROOMS } from "../constants";
import { toast } from "react-toastify";

/**
 * @param {{ roomName?: SOCKET_ROOMS, roomId: string | number}[]} socketRooms - Array of objects with name and handler properties
 * @param {any} dependency - Dependency to watch for changes
 */
const useSocketRooms = (socketRooms, dependency = null) => {
  const { socket } = useContext(UserContext);

  const handleJoinRoom = (name, roomId) => {
    socket.emit(name, roomId, (error) => {
      if (error) {
        console.error(`Error joining room ${name}: ${error}`);
      }
    });
  };

  const handleLeaveRoom = () => {
    socket.emit(SOCKET_ROOMS.LEAVE_ALL_ROOMS, (error) => {
      if (error) {
        console.error(`Error leaving room: ${error}`);
      }
    });
  };

  useEffect(() => {
    if (!socket) return;
    if (dependency !== null && !dependency) return;

    socketRooms.forEach(({ roomName, roomId }) => {
      if (!roomName && !roomId) {
        console.error(
          "RoomName and roomId are required for each socket connection"
        );
        return;
      }

      if (Object.values(SOCKET_ROOMS).includes(roomName)) {
        handleJoinRoom(roomName, roomId);
      } else {
        console.error(`Room name: ${roomName} is not a valid socket room`);
      }

      socket.on("error", (error) => {
        toast.error(error);
      });
    });

    return () => {
      handleLeaveRoom();
      socket.off("error", (error) => {
        toast.error(error);
      });
    };
  }, [socket, ...(Array.isArray(dependency) ? dependency : [dependency])]); // eslint-disable-line
};

export default useSocketRooms;
