import { Typography } from "@mui/material";

const StyledTypography = ({ children, sx, ...props }) => {
  return (
    <Typography
      sx={{
        fontFamily: "Brandon",
        fontSize: "18px",
        marginBottom: "1.5rem",
        fontWeight: 400,
        "& a": {
          fontWeight: 600,
          color: (theme) => theme.palette.skyBlue,
          textDecoration: "underline",
          "&:hover": {
            textDecoration: "none",
            fontWeight: 800,
          },
        },
        ...sx,
      }}
      {...props}
    >
      {children}
    </Typography>
  );
};

export default StyledTypography;
