import { Typography, Button, Stack } from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import AgentSelect from "src/components/common/AgentSelect";

const validationSchema = Yup.object().shape({
  agent: Yup.object().required("Must select an agent").typeError("Required"),
});

const VirtualSessionsForm = ({
  handleCreateVirtualSession,
  virtualSessions,
}) => {
  return (
    <Formik
      validationSchema={validationSchema}
      enableReinitialize
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={{
        agent: { id: null, name: "-" },
      }}
      onSubmit={({ agent }, { resetForm }) => {
        handleCreateVirtualSession(agent.id, resetForm);
      }}
    >
      {({
        values,
        setFieldError,
        setFieldValue,
        errors,
        handleSubmit,
        isValid,
      }) => {
        let hasActiveSession = false;

        if (values.agent.id && virtualSessions) {
          hasActiveSession = !virtualSessions.every(
            (session) => session.isCompleted
          );
        }

        return (
          <form onSubmit={handleSubmit}>
            <Stack
              sx={{
                flexDirection: { xs: "column", md: "row" },
                gap: 2,
              }}
            >
              <Stack width="100%">
                <AgentSelect
                  fullWidth
                  label="Coach Booking Link"
                  initialAgent={values.agent}
                  error={errors.agent}
                  handleSetAgent={(agent) => {
                    setFieldValue("agent", agent);
                    setFieldError("agent", undefined);
                  }}
                />
              </Stack>

              <Button
                disabled={!values.agent.id || !isValid || hasActiveSession}
                type="submit"
                variant="contained"
                color="primary"
                sx={{
                  minHeight: 56,
                }}
              >
                Create
              </Button>
            </Stack>
            {values.agent.id && hasActiveSession && (
              <Typography color="error" variant="body2">
                * Only one active session is permitted at a time.
              </Typography>
            )}
          </form>
        );
      }}
    </Formik>
  );
};

export default VirtualSessionsForm;
