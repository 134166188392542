import { AppBar, Toolbar, Link } from "@mui/material";
import useDeviceBreakpoint from "src/utils/useDeviceBreakpoint";

const ClientHeader = () => {
  const { isMobile } = useDeviceBreakpoint();

  return (
    <AppBar
      sx={{
        position: "fixed",
        backgroundColor: "white",
        mt: {
          xs: 0,
          md: 2, // 16 px
        },
        display: "flex",
        justifyContent: "center",
        alignItems: {
          xs: "center",
          md: "flex-start",
        },
        pl: {
          xs: 0,
          md: 25, // 196 px
        },
        minHeight: {
          xs: 90,
          md: 130,
        },
      }}
      elevation={0}
      position="fixed"
    >
      <Toolbar
        sx={{
          display: "flex",
          alignItems: "center",
          gap: {
            xs: 1.5,
            md: 3,
          },
        }}
      >
        <Link
          href="http://outreach.fertilityoutloud.com/"
          target="_blank"
          rel="noreferrer"
        >
          <img
            width={isMobile ? 100 : 200}
            height={isMobile ? 50 : 86}
            src="/static/images/FOR.svg"
            alt="logo"
          />
        </Link>
        <Link
          href="https://www.fertilityoutloud.com/"
          target="_blank"
          rel="noreferrer"
          sx={{
            pl: {
              xs: 0,
              md: -4,
            },
          }}
        >
          <img
            src="/static/images/poweredByFOL.svg"
            width={isMobile ? 58 : 110}
            height={isMobile ? 50 : 86}
            alt="logo"
          />
        </Link>
      </Toolbar>
    </AppBar>
  );
};

export default ClientHeader;
