import { Stack, Typography } from "@mui/material";
import Tooltip from "src/components/common/ToolTip";

const setValueOrNa = (value) => {
  return value ? value : "-";
};

const DisplayText = ({ primary, secondary, sx }) => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      height="28px"
      {...sx}
    >
      {primary && (
        <Typography fontWeight={500} variant="caption">
          {primary}
        </Typography>
      )}
      <Tooltip
        arrow
        title={secondary === "-" ? "" : secondary}
        placement="top-start"
      >
        <Typography
          noWrap
          variant="body2"
          sx={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "noWrap",
          }}
        >
          {setValueOrNa(secondary)}
        </Typography>
      </Tooltip>
    </Stack>
  );
};

export default DisplayText;
