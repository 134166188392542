import React, { useContext } from "react";
import { ChatContext } from "src/context/ChatContext";
import { Box, TextField, InputAdornment } from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import _ from "lodash";

const Search = () => {
  const { setTextQuery } = useContext(ChatContext);
  const debouncedSetSearchQuery = _.debounce(setTextQuery, 1000);

  const handleChange = ({ target }) => {
    const { value } = target;
    debouncedSetSearchQuery(value);
  };

  return (
    <Box
      sx={{
        p: 1,
      }}
    >
      <TextField
        placeholder="Search messages"
        fullWidth
        onChange={handleChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon color="primary" />
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default Search;
