import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Divider,
  IconButton,
} from "@mui/material";
import InactiveReasonForm from "../InactiveReasonForm";

const InactiveReasonModal = ({
  inactiveReasonModalOpen,
  setInactiveReasonModalOpen,
  handleStatusUpdate,
}) => {
  const handleInactiveReasonFormSubmit = async (inactiveReason) => {
    handleStatusUpdate("Inactive", inactiveReason);
    setInactiveReasonModalOpen(false);
  };

  return (
    <Dialog
      fullWidth
      open={inactiveReasonModalOpen}
      onClose={() => setInactiveReasonModalOpen(false)}
    >
      <DialogTitle
        disableTypography
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h4">Deactivate Client</Typography>
        <IconButton onClick={() => setInactiveReasonModalOpen(false)}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <InactiveReasonForm
          onSubmit={handleInactiveReasonFormSubmit}
          onCancel={() => setInactiveReasonModalOpen(false)}
        />
      </DialogContent>
    </Dialog>
  );
};

export default InactiveReasonModal;
