import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Avatar, Box, Stack, Typography } from "@mui/material";
import {
  Face as FaceIcon,
  ErrorOutline as ErrorOutlineIcon,
} from "@mui/icons-material";
import Linkifyjs from "linkifyjs/react";

const MessageItem = ({ message, ...rest }) => {
  const { createdByClient, createdByAgent } = message || {};
  const createdBy = createdByClient || createdByAgent || {};
  const createdByName =
    createdBy.displayName || createdBy.firstName + " " + createdBy.lastName;
  const isAgent = !!createdByAgent;

  return (
    <Stack
      direction="row"
      sx={{
        "& a": {
          color: "white",
        },
        justifyContent: isAgent ? "flex-end" : "flex-start",
      }}
      {...rest}
    >
      <Stack
        direction="row"
        spacing={2}
        sx={{
          maxWidth: "500px",
          mx: 2,
          pb: 1,
        }}
      >
        <Box pt={3}>
          <Avatar
            size="small"
            sx={{
              backgroundColor: "white",
              color: "primary.main",
              border: (theme) => `1px solid ${theme.palette.secondary.main}`,
              height: 36,
              width: 36,
            }}
            src={createdBy.avatarLink}
          >
            {isAgent && <FaceIcon color="primary" />}
            {!isAgent && createdByName[0]}
          </Avatar>
        </Box>
        <Stack>
          <Stack>
            <Typography noWrap color="textSecondary" variant="caption">
              {moment(message.createdAt).format("l, h:mm:ss a")}
            </Typography>
          </Stack>
          <Stack
            sx={{
              wordBreak: "break-word",
              backgroundColor: isAgent ? "primary.main" : "white",
              color: isAgent ? "white" : "text.primary",
              py: 1,
              px: 2,
              borderRadius: 1,
              boxShadow: 1,
            }}
            spacing={1}
          >
            <Typography variant="h6">{createdByName || "N/A"}</Typography>
            <Typography color="inherit" variant="body1">
              <Linkifyjs>{message.body}</Linkifyjs>
            </Typography>
          </Stack>
          {message.isDelivered === false && (
            <Stack
              direction="row"
              sx={{
                mt: 1,
                justifyContent: "flex-end",
                alignItems: "center",
                gap: 0.5,
              }}
            >
              <ErrorOutlineIcon fontSize="small" color="error" />
              <Typography
                sx={{
                  lineHeight: 1,
                }}
                color="error"
                noWrap
                variant="caption"
              >
                Not Delivered
              </Typography>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

MessageItem.propTypes = {
  message: PropTypes.object.isRequired,
};

export default MessageItem;
