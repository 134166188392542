import {
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  Stack,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import useDeviceBreakpoint from "src/utils/useDeviceBreakpoint";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  error: {
    border: `1px solid ${theme.palette.error.muiError}`,
  },
  icon: {
    borderRadius: "50%",
    width: 27,
    height: 27,
    backgroundColor: theme.palette.background.default,
  },
  checkedIcon: {
    "&:before": {
      display: "block",
      width: 27,
      height: 27,
      backgroundImage: `radial-gradient(${theme.palette.primary.main},${theme.palette.primary.main} 50%,transparent 55%)`,
      content: '""',
    },
  },
}));

const Row = ({
  isMobile,
  classes,
  label,
  name,
  options,
  onChange,
  hasError,
}) => {
  return (
    <Stack spacing={2}>
      <Typography variant="brandonTitle">{label}</Typography>

      <RadioGroup
        row={!isMobile}
        sx={{
          justifyContent: "space-between",
        }}
        onChange={(e) => onChange({ ...e, name })}
        name={name}
      >
        {options.map((option, idx) => (
          <FormControlLabel
            key={`column-select-widely-row-${option?.id}`}
            control={
              // TODO: move Radio to a styled component OR the checked nad icons to a styled component
              <Radio
                disableRipple
                key={option?.id}
                name={name}
                value={`${option?.id}`}
                checkedIcon={
                  <span className={clsx(classes.icon, classes.checkedIcon)} />
                }
                icon={
                  <span
                    className={clsx(hasError && classes.error, classes.icon)}
                  />
                }
              />
            }
            labelPlacement={isMobile ? "end" : "bottom"}
            label={
              <Typography variant="brandonBody1">{option?.label}</Typography>
            }
          />
        ))}
      </RadioGroup>
    </Stack>
  );
};

// TODO: identify a way to columnselect and rowselect components
const RowSelect = ({
  title,
  subTitle = null,
  options,
  rows,
  onChange,
  errors,
}) => {
  const classes = useStyles();
  const { isMobile } = useDeviceBreakpoint();

  const handleChange = (e) => {
    onChange(e.name, e.target.value);
  };

  const errorsArray = Object.keys(errors);

  return (
    <Stack spacing={3}>
      <Typography variant="brandonTitle">{title}</Typography>
      {Boolean(subTitle) && (
        <Typography variant="brandonBody1">{subTitle}</Typography>
      )}

      {rows.map((row, index) => {
        return (
          <Row
            isMobile={isMobile}
            hasError={errorsArray.some((error) => error === row.name)}
            key={row.label}
            classes={classes}
            label={row.label}
            name={row.name}
            options={options}
            onChange={handleChange}
          />
        );
      })}
    </Stack>
  );
};
export default RowSelect;
