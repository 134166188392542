import { useAuth0 } from "@auth0/auth0-react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import {
  AppBar,
  Hidden,
  IconButton,
  Toolbar,
  SvgIcon,
  Box,
  Button,
} from "@mui/material";
import { Menu as MenuIcon, LogOut as LogOutIcon } from "react-feather";

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const { isAuthenticated, logout } = useAuth0();

  return (
    <AppBar
      className={className}
      sx={{
        backgroundColor: "ternary.main",
      }}
      {...rest}
    >
      <Toolbar sx={{ minHeight: 64 }}>
        <>
          <Hidden lgUp>
            <IconButton color="inherit" onClick={onMobileNavOpen}>
              <SvgIcon fontSize="small">
                <MenuIcon />
              </SvgIcon>
            </IconButton>
          </Hidden>
          <Hidden mdDown>
            <RouterLink to="/">
              <img
                src="/static/images/FOR_Main_Logo_KO.svg"
                alt="logo"
                height={55}
              />
            </RouterLink>
          </Hidden>
        </>
        <Box ml={2} flexGrow={1} />
        {isAuthenticated && (
          <Hidden mdDown>
            <Button
              color="inherit"
              onClick={() => logout({ returnTo: window.location.origin })}
              startIcon={<LogOutIcon />}
            >
              Log Out
            </Button>
          </Hidden>
        )}
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
};

TopBar.defaultProps = {
  onMobileNavOpen: () => {},
};

export default TopBar;
