import React from "react";
import { Box, Typography } from "@mui/material";
import Loader from "../Loader";

const LoadingContent = ({ description, loaderSize }) => {
  return (
    <Box
      display="flex"
      height="100vh"
      justifyContent="center"
      alignItems="center"
    >
      <Loader size={loaderSize} />
      {description && (
        <Typography
          color="text.secondary"
          variant="body2"
          sx={{ marginLeft: (theme) => theme.spacing(2) }}
        >
          {description}
        </Typography>
      )}
    </Box>
  );
};

export default LoadingContent;
