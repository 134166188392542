import propTypes from "prop-types";
import {
  Paper,
  FormHelperText,
  TextField,
  Stack,
  Typography,
} from "@mui/material";

const AutoResizeInput = ({
  inputRef,
  id,
  label,
  name,
  value,
  handleChange,
  handleKeyDown,
  placeholder,
  hasCharacterCount,
  errorMessage,
  ...rest
}) => {
  return (
    <>
      <Paper variant="outlined">
        <TextField
          inputRef={inputRef}
          id={id}
          name={name}
          label={label}
          value={value}
          onChange={handleChange}
          onKeyDown={(Boolean(handleKeyDown) && handleKeyDown) || null}
          placeholder={placeholder}
          multiline
          maxRows={10}
          variant="outlined"
          aria-hidden="false"
          fullWidth
          sx={{
            "& label": {
              backgroundColor: "background.default",
              px: 1,
            },
          }}
          InputProps={{
            sx: {
              "& textarea": {
                resize: "none",
                "&::placeholder": {
                  color: "lightgrey",
                },
              },
              "& fieldset": {
                border: (theme) =>
                  errorMessage
                    ? `1px solid ${theme.palette.error.main}`
                    : "none",
              },
            },
          }}
          {...rest}
        />
      </Paper>
      {Boolean(hasCharacterCount || errorMessage) && (
        <Stack
          direction="row"
          sx={{
            justifyContent: errorMessage ? "space-between" : "flex-end",
          }}
        >
          {errorMessage && (
            <FormHelperText error>{errorMessage || "Error *"}</FormHelperText>
          )}
          {hasCharacterCount && (
            <Typography
              variant="body2"
              sx={{
                alignSelf: "flex-end",
              }}
            >
              {`${value?.length || 0} characters`}
            </Typography>
          )}
        </Stack>
      )}
    </>
  );
};

export default AutoResizeInput;

AutoResizeInput.propTypes = {
  id: propTypes.string,
  label: propTypes.string,
  message: propTypes.string,
  handleChange: propTypes.func.isRequired,
  handleKeyDown: propTypes.func,
  placeholder: propTypes.string,
  errorMessage: propTypes.string,
  hasCharacterCount: propTypes.bool,
};

AutoResizeInput.defaultProps = {
  id: "",
  label: "",
  name: "",
  value: "",
  handleKeyDown: null,
  placeholder: "",
  errorMessage: null,
  hasCharacterCount: false,
};
