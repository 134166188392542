import { Tooltip as MuiTooltip } from "@mui/material";

const ToolTip = (props) => {
  return (
    <MuiTooltip
      arrow={true}
      PopperProps={{
        sx: {
          "& .MuiTooltip-tooltip": {
            fontSize: 14,
            backgroundColor: "black",
            color: "white",
          },
          "& .MuiTooltip-arrow": {
            color: "black",
          },
        },
      }}
      {...props}
    >
      {props.children}
    </MuiTooltip>
  );
};

export default ToolTip;
