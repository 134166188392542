import { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { Formik } from "formik";
import validationSchema from "./validationSchema";
import axios from "src/utils/axios";
import {
  ColumnSelect,
  SingleRadioSelect,
  HiddenLogicComponent,
  TextInput,
  SubmitButton,
  RobynDisclaimer,
  ContentWrapper,
  Heading,
  FormWrapper,
  ErrorBanner,
  MultiSelect,
  FivePointList,
} from "src/components/Forms/common";

const defaultValues = {
  formJourneyPathId: null,
  wellbeingPoint: {
    mindset: null,
    physical: null,
    emotional: null,
    social: null,
    financial: null,
  },
  atReStatus: {
    id: null,
    label: "",
    notAtReReasons: [],
    otherNotAtReReasonContent: "",
  },
  journeyEvent: {
    id: null,
    label: "",
    atReStatusId: null,
    otherContent: "",
  },
};

const getFormOptions = async () => {
  try {
    const { data: formOptions } = await axios.get("/api/crm/form-options", {
      params: {
        formName: "follow-up",
      },
    });
    return formOptions;
  } catch (error) {
    console.error("Error at fetching form options =>", error);
    throw error;
  }
};

const getClient = async (clientId) => {
  try {
    const { data: client } = await axios.get("/api/clients/public", {
      params: {
        clientId,
      },
    });
    return client;
  } catch (error) {
    throw error;
  }
};

const getForm = async (clientId, formId) => {
  try {
    const { data: form } = await axios.get(
      "/api/clients/public/form/follow-up",
      {
        params: {
          clientId,
          formId,
        },
      }
    );

    return form;
  } catch (error) {
    throw error;
  }
};

const FollowUpForm = ({ clientId, formId }) => {
  const [formStatus, setFormStatus] = useState("IS_LOADING");
  const [client, setClient] = useState({});
  const [formOptions, setFormOptions] = useState({});
  const [initialValues, setInitialValues] = useState(defaultValues);

  const handleSubmit = async (values) => {
    setFormStatus("IS_LOADING");
    try {
      delete values.formJourneyPathId;

      await axios.put(`/api/clients/${clientId}/follow-up/${formId}`, {
        displayName: client.displayName,
        ...values,
      });

      setFormStatus("SUCCESS");
    } catch (error) {
      console.error(error);

      if (error.response.status === 409) {
        setFormStatus("FORM_ALREADY_SUBMITTED");
        return;
      }
      setFormStatus("SUBMIT_ERROR");
    }
  };

  useEffect(() => {
    (async () => {
      try {
        // IF MISSING QUERY PARAMETERS SHOW LOAD ERROR
        if (!clientId || !formId) {
          setClient(null);
          setFormStatus("LOAD_ERROR");
          return;
        }

        const client = await getClient(clientId);
        // IF CLIENT DOES NOT EXIST SHOW SIGN UP FORM
        if (!client) {
          setFormStatus("SHOW_SIGN_UP");
          return;
        }

        setClient(client);

        // IF CLIENT HAS ALREADY SUBMITTED FORM SHOW FORM ALREADY SUBMITTED
        const followUpForm = await getForm(clientId, formId);

        if (!followUpForm) {
          throw new Error("Form not found");
        }

        if (followUpForm.isComplete) {
          setFormStatus("FORM_ALREADY_SUBMITTED");
          return;
        }

        const newFormOptions = await getFormOptions();

        const currentFormOptions = {
          ...newFormOptions,
        };

        setFormOptions(currentFormOptions);
      } catch (error) {
        console.error(error);
        setFormStatus("LOAD_ERROR");
      }
    })();
  }, [clientId, formId]);

  useEffect(() => {
    // TODO: handle load error state when client exists but form path is missing
    // This was handled on the API side - if no journey path status is found, it defaults to 1 (TTC)
    if (!client?.formJourneyPathId) return;
    if (Object.keys(formOptions).length === 0) return;

    const formJourneyPathId = parseInt(client.formJourneyPathId);

    setInitialValues((prevValues) => ({
      ...prevValues,
      formJourneyPathId,
    }));

    setFormStatus("SHOW_FORM");
  }, [client?.formJourneyPathId, formOptions]);

  return (
    <ContentWrapper>
      <FormWrapper formName="Follow Up Form" formStatus={formStatus}>
        {/* TODO: Prevent FormWrapper children from rendering without the following conditional wrapper below */}
        {Boolean(formStatus === "SHOW_FORM") && (
          <>
            <Heading
              title={`Welcome ${client.firstName || ""}!`}
              subtitle=" We're just checking in! This helps ensure that our fertility
                coaches are continuing to provide the best, personalized support
                just for you."
            />
            <Formik
              validateOnBlur={false}
              validateOnChange={false}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({
                values,
                setFieldValue,
                errors,
                handleSubmit,
                isValid,
                setFieldError,
              }) => {
                const setField = (fieldName, value) => {
                  setFieldValue(fieldName, value);
                  setFieldError(fieldName, undefined);
                };

                const clearFields = (fieldNames) => {
                  fieldNames.forEach((fieldName) => {
                    setFieldValue(fieldName, initialValues[fieldName]);
                    setFieldError(fieldName, undefined);
                  });
                };

                const formPath = values.formJourneyPathId;

                return (
                  <form onSubmit={handleSubmit}>
                    <Grid
                      container
                      spacing={2}
                      sx={{ gap: 4.25 }}
                      justifyContent="flex-start"
                    >
                      <Grid item xs={12}>
                        <ColumnSelect
                          errors={errors?.wellbeingPoint || {}}
                          onChange={(name, value) => {
                            setField("wellbeingPoint", {
                              ...values.wellbeingPoint,
                              [name]: parseInt(value),
                            });
                          }}
                          title={
                            "Please rate how you feel on a scale of 1-5 in terms of each of the following 5 points of wellbeing:"
                          }
                          subTitle={<FivePointList />}
                          options={["1", "2", "3", "4", "5"]}
                          rows={[
                            {
                              name: "mindset",
                              label: "Mindset",
                            },
                            {
                              name: "physical",
                              label: "Physical Self",
                            },
                            {
                              name: "emotional",
                              label: "Emotional Self",
                            },
                            {
                              name: "social",
                              label: "Social Self",
                            },
                            {
                              name: "financial",
                              label: "Financial Self",
                            },
                          ]}
                        />
                      </Grid>

                      {/* CONDITIONAL: IF formPath = 1 (TTC) then show TTC input fields */}
                      <HiddenLogicComponent
                        value={formPath === 1}
                        clearFields={clearFields}
                        clearFieldNames={[
                          "atReStatus",
                          "journeyEvent",
                          "ttcDuration",
                        ]}
                      >
                        {/* FIELD: Has RE */}
                        <Grid item xs={12}>
                          <SingleRadioSelect
                            heading="Are you currently seeing a fertility specialist (reproductive endocrinologist/RE)?"
                            value={values.atReStatus?.id}
                            options={formOptions.atReStatus}
                            onChange={(status) => {
                              setField("atReStatus", {
                                ...values.atReStatus,
                                ...status,
                              });
                            }}
                            hasError={Boolean(errors.atReStatus)}
                          />
                        </Grid>

                        {/* CONDITIONAL: IF "Yes" was selected for Are you currently seeing a fertility specialist */}
                        <HiddenLogicComponent
                          value={values.atReStatus?.label === "Yes"}
                          clearFields={clearFields}
                          clearFieldNames={["journeyEvent"]}
                        >
                          <Grid item xs={12}>
                            <SingleRadioSelect
                              heading="Where are you on the fertility treatment journey?"
                              value={values.journeyEvent?.id}
                              onChange={({ id, label }) => {
                                setField("journeyEvent", {
                                  ...values.journeyEvent,
                                  id,
                                  label,
                                });

                                setFieldError("journeyEvent.label", undefined);
                              }}
                              // Form Options Journey Update Event could be written as journeyEvent[values.journeyPathStatusLabel]
                              // however, we are separating these fields so we do not need to create a CMS file for each journey path
                              options={formOptions.journeyEvent.ttc}
                              hasError={Boolean(errors.journeyEvent)}
                            />
                          </Grid>

                          {/* CONDITIONAL: IF "Other" was selected for Fertility Treatment Journey */}
                          <HiddenLogicComponent
                            value={values.journeyEvent?.label === "Other"}
                            clearFields={clearFields}
                            clearFieldNames={["journeyEvent.otherContent"]}
                          >
                            {/* FIELD: Other Fertility Treatment Journey (otherJourneyEventContent) */}
                            <Grid item xs={12}>
                              <TextInput
                                variant="form"
                                label="Please tell us where you are on the fertility treatment journey"
                                name="journeyEventOtherContent"
                                value={values.journeyEvent?.otherContent || ""}
                                onChange={(e) => {
                                  const content = e.target.value;
                                  setField("journeyEvent", {
                                    ...values.journeyEvent,
                                    otherContent: content,
                                  });
                                  setFieldError(
                                    "journeyEvent.otherContent",
                                    undefined
                                  );
                                }}
                                error={Boolean(
                                  errors.journeyEvent?.otherContent
                                )}
                              />
                            </Grid>
                          </HiddenLogicComponent>
                        </HiddenLogicComponent>
                        {/* CLOSURE: IF "Yes" was selected for Are you currently seeing a fertility specialist */}

                        {/* CONDITIONAL: If "No" was selected for Are you currently Seeing a fertility specialist */}
                        <HiddenLogicComponent
                          value={values.atReStatus?.label === "No"}
                          clearFields={clearFields}
                          clearFieldNames={["atReStatus.notAtReReasons"]}
                        >
                          {/* FIELD: Not At RE Reason */}
                          <Grid item xs={12}>
                            <MultiSelect
                              heading="Why are you not seeing a fertility specialist?"
                              onChange={(notAtReReasons) => {
                                setField("atReStatus", {
                                  ...values.atReStatus,
                                  notAtReReasons,
                                });
                                setFieldError(
                                  "atReStatus.notAtReReasons",
                                  undefined
                                );
                              }}
                              values={values.atReStatus.notAtReReasons}
                              options={formOptions.ttcNotAtReReasons}
                              hasError={Boolean(
                                errors.atReStatus?.notAtReReasons
                              )}
                            />
                          </Grid>
                          {/* CONDITIONAL: IF "Other" is selected for Not At RE Reason */}
                          <HiddenLogicComponent
                            value={values.atReStatus?.notAtReReasons?.some(
                              (reason) => reason.label === "Other"
                            )}
                            clearFields={clearFields}
                            clearFieldNames={[
                              "atReStatus.otherNotAtReReasonContent",
                            ]}
                          >
                            {/* FIELD: Other Not At RE Reason */}
                            <Grid item xs={12}>
                              <TextInput
                                label="Please tell us why you are not seeing a fertility specialist"
                                name="otherNotAtReReasonContent"
                                value={
                                  values.atReStatus
                                    ?.otherNotAtReReasonContent || ""
                                }
                                onChange={(e) => {
                                  const content = e.target.value;
                                  setField("atReStatus", {
                                    ...values.atReStatus,
                                    otherNotAtReReasonContent: content,
                                  });
                                  setFieldError(
                                    "atReStatus.otherNotAtReReasonContent",
                                    undefined
                                  );
                                }}
                                error={Boolean(
                                  errors.atReStatus?.otherNotAtReReasonContent
                                )}
                                variant="form"
                              />
                            </Grid>
                          </HiddenLogicComponent>
                          {/* CLOSURE: IF "Other" is selected for Not At RE Reason */}
                        </HiddenLogicComponent>
                        {/* CLOSURE: If "No" was selected for Are you currently Seeing a fertility specialist */}
                      </HiddenLogicComponent>
                      {/* CLOSE: IF formPath = 1 (TTC) then show TTC input fields */}

                      {/* CONDITIONAL: IF formPath = 2 (Egg Freezing) then show Egg Freezing input fields */}
                      <HiddenLogicComponent
                        value={formPath === 2}
                        clearFields={clearFields}
                        clearFieldNames={["atReStatus", "journeyEvent"]}
                      >
                        {/* FIELD: Has RE */}
                        <Grid item xs={12}>
                          <SingleRadioSelect
                            heading="Are you currently seeing a fertility specialist (reproductive endocrinologist/RE)?"
                            value={values.atReStatus?.id}
                            options={formOptions.atReStatus}
                            onChange={(status) => {
                              console.log("status", status);
                              setField("atReStatus", {
                                ...values.atReStatus,
                                ...status,
                              });
                            }}
                            hasError={Boolean(errors.atReStatus)}
                          />
                        </Grid>

                        {/* CONDITIONAL: IF "Yes" was selected for 'Are you currently seeing a fertility specialist (reproductive endocrinologist/RE)?' */}
                        <HiddenLogicComponent
                          value={values.atReStatus?.label === "Yes"}
                          clearFields={clearFields}
                          clearFieldNames={["journeyEvent"]}
                        >
                          {/* FIELD: Egg Freezing Journey (journeyEvent) */}
                          <Grid item xs={12}>
                            <SingleRadioSelect
                              heading="Where are you on the egg freezing journey?"
                              value={values.journeyEvent?.id}
                              onChange={({ id, label }) => {
                                setField("journeyEvent", {
                                  ...values.journeyEvent,
                                  id,
                                  label,
                                });
                                setFieldError("journeyEvent", undefined);
                              }}
                              options={formOptions.journeyEvent.eggFreezing}
                              hasError={Boolean(errors.journeyEvent)}
                            />
                          </Grid>

                          {/* CONDITIONAL: IF "Other" was selected for Egg Freezing Journey */}
                          <HiddenLogicComponent
                            value={values.journeyEvent?.label === "Other"}
                            clearFields={clearFields}
                            clearFieldNames={["journeyEvent.otherContent"]}
                          >
                            {/* FIELD: Other Egg Freezing Journey (journeyEventOtherContent) */}
                            <Grid item xs={12}>
                              <TextInput
                                label="Please tell us where you are on your egg freezing journey"
                                name="journeyEventOtherContent"
                                value={values.journeyEvent?.otherContent}
                                onChange={(e) => {
                                  const content = e.target.value;
                                  setField("journeyEvent", {
                                    ...values.journeyEvent,
                                    otherContent: content,
                                  });
                                  setFieldError(
                                    "journeyEventOtherContent",
                                    undefined
                                  );
                                }}
                                error={Boolean(
                                  errors.journeyEvent?.otherContent
                                )}
                                variant="form"
                              />
                            </Grid>
                          </HiddenLogicComponent>
                          {/* CLOSURE: IF "Other" was selected for Egg Freezing Journey */}
                        </HiddenLogicComponent>
                        {/* CLOSURE: IF "Yes" was selected for 'Are you currently seeing a fertility specialist (reproductive endocrinologist/RE)?' */}

                        {/* CONDITIONAL: IF "No" was selected for 'Are you currently seeing a fertility specialist (reproductive endocrinologist/RE)?' */}
                        <HiddenLogicComponent
                          value={values.atReStatus?.label === "No"}
                          clearFields={clearFields}
                          clearFieldNames={[
                            "atReStatus.notAtReReasons",
                            "atReStatus.otherNotAtReReasonContent",
                          ]}
                        >
                          {/* FIELD: Not At RE Reason */}
                          <Grid item xs={12}>
                            <MultiSelect
                              heading="Why are you not seeing a fertility specialist?"
                              onChange={(notAtReReasons) => {
                                setField("atReStatus", {
                                  ...values.atReStatus,
                                  notAtReReasons,
                                });
                                setFieldError(
                                  "atReStatus.notAtReReasons",
                                  undefined
                                );
                              }}
                              values={values.atReStatus?.notAtReReasons}
                              options={formOptions.eggFreezingNotAtReReasons}
                              hasError={Boolean(
                                errors.atReStatus?.notAtReReasons
                              )}
                            />
                          </Grid>
                          {/* CONDITIONAL: IF "Other" is selected for Not At RE Reason */}
                          <HiddenLogicComponent
                            value={values.atReStatus?.notAtReReasons?.some(
                              (reason) => reason.label === "Other"
                            )}
                            clearFields={clearFields}
                            clearFieldNames={[
                              "atReStatus.otherNotAtReReasonContent",
                            ]}
                          >
                            {/* FIELD: Other Not At RE Reason */}
                            <Grid item xs={12}>
                              <TextInput
                                label="Please tell us why you are not seeing a fertility specialist"
                                name="otherNotAtReReasonContent"
                                value={
                                  values.atReStatus
                                    ?.otherNotAtReReasonContent || ""
                                }
                                onChange={(e) => {
                                  const content = e.target.value;
                                  setField("atReStatus", {
                                    ...values.atReStatus,
                                    otherNotAtReReasonContent: content,
                                  });
                                  setFieldError(
                                    "atReStatus.otherNotAtReReasonContent",
                                    undefined
                                  );
                                }}
                                error={Boolean(
                                  errors.atReStatus?.otherNotAtReReasonContent
                                )}
                                variant="form"
                              />
                            </Grid>
                          </HiddenLogicComponent>
                          {/* CLOSURE: IF "Other" is selected for Not At RE Reason */}
                        </HiddenLogicComponent>
                        {/* CLOSURE: IF "No" was selected for 'Are you currently seeing a fertility specialist (reproductive endocrinologist/RE)?' */}
                      </HiddenLogicComponent>
                      {/* CLOSE: IF formPath = 2 (Egg Freezing) then show Egg Freezing input fields */}

                      {!isValid && (
                        <Grid item xs={12}>
                          <ErrorBanner />
                        </Grid>
                      )}
                      <Grid container justifyContent="center" item xs={12}>
                        <SubmitButton disabled={!isValid} />{" "}
                      </Grid>
                      <Grid item xs={12}>
                        <RobynDisclaimer
                          hasHeading
                          veevaCode={"10/24 US-RMMH-2300235 v4"}
                        />
                      </Grid>
                    </Grid>
                  </form>
                );
              }}
            </Formik>
          </>
        )}
      </FormWrapper>
    </ContentWrapper>
  );
};

export default FollowUpForm;
