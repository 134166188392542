import React, { useState } from "react";
import axios from "src/utils/axios";
import Loader from "src/components/common/Loader";
import { useHistory } from "react-router-dom";
import {
  Dialog,
  DialogTitle,
  Divider,
  DialogActions,
  DialogContent,
  Grid,
  Button,
  Box,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";

const DeleteConfirmModal = ({ client, isOpen, setIsOpen }) => {
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleDeletePatient = async () => {
    try {
      await axios.delete(`/api/clients/${client.clientId}`);
      handleClose();
    } catch (error) {
      throw new Error(error.message);
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      await handleDeletePatient();
      setIsLoading(false);
      history.push("/clients");
    } catch (error) {
      toast.error(`Error deleting ${client.firstName} ${client.lastName}`);
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>Delete Client</DialogTitle>
      <Divider />
      <DialogContent>
        {isLoading && (
          <Box
            minHeight={300}
            minWidth={400}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Loader />
          </Box>
        )}
        {!isLoading && (
          <Grid container spacing={2}>
            <Grid item>
              <Typography gutterBottom>
                Are you sure you want to delete {client?.firstName}?
              </Typography>
            </Grid>
          </Grid>
        )}
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={() => handleSubmit()}
            type="submit"
            color="primary"
            variant="contained"
          >
            Ok
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteConfirmModal;
