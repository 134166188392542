import PropTypes from "prop-types";

import { Stack, Chip, Box } from "@mui/material";

const AtObgynStatus = ({ status }) => {
  return (
    <Stack spacing={0.5} direction="column" alignItems="flex-start">
      <Chip
        sx={{
          py: 1.75,
          minWidth: 160,
          backgroundColor: (theme) => theme.palette.brightPurple,
          borderRadius: 2,
          color: "white",
        }}
        label={status}
      />
    </Stack>
  );
};

export default AtObgynStatus;

AtObgynStatus.propTypes = {
  status: PropTypes.string,
};
