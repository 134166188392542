const nonUsZipCodes = ["00000", "11111", "66666", "77777", "99999"];

const zipCodeValidator = (zipCode) => {
  if (!zipCode) {
    return false;
  }

  if (nonUsZipCodes.includes(zipCode)) {
    return false;
  }

  const zipCodeToNumber = parseInt(zipCode, 10);

  const isOutOfRange = zipCodeToNumber < 501 && zipCodeToNumber > 99950;

  if (isOutOfRange) {
    return false;
  }

  // Puerto Rico's zip codes range from 00600–00799 and 00900–00999
  const isPuertoRicoZipCode =
    (zipCodeToNumber >= 600 && zipCodeToNumber <= 799) ||
    (zipCodeToNumber >= 900 && zipCodeToNumber <= 999);

  if (isPuertoRicoZipCode) {
    return false;
  }

  return true;
};

export default zipCodeValidator;
