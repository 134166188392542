import React from "react";
import PropTypes from "prop-types";
import { ListItem, Box, Typography, Stack } from "@mui/material";
import moment from "moment";
import ReactMarkdown from "react-markdown";

const SearchItem = ({ result, onSelect, isActive }) => {
  const createdBy = result.createdByAgent || result.createdByClient || {};

  const handleSelectedSearchItem = (result) => {
    onSelect(result);
  };

  return (
    <ListItem
      divider
      sx={{
        flexDirection: "column",
        alignItems: "flex-start",
        backgroundColor: isActive && "grey10",
        cursor: "pointer",
      }}
      onClick={() => handleSelectedSearchItem(result)}
    >
      <Stack
        direction="row"
        sx={{ justifyContent: "space-between", width: "100%" }}
      >
        <Typography variant="body2" gutterBottom>
          {createdBy.displayName ||
            `${createdBy.firstName} ${createdBy.lastName}`}
        </Typography>
        <Typography variant="caption" color="text.secondary">
          {moment(result.createdAt).format("L")}
        </Typography>
      </Stack>
      <Box
        id={`${result.id}-body`}
        sx={{
          color: "text.secondary",
          fontSize: (theme) => theme.typography.body2.fontSize,
          wordBreak: "break-word",
          width: "100%",
        }}
      >
        <ReactMarkdown allowDangerousHtml>{result.body}</ReactMarkdown>
      </Box>
    </ListItem>
  );
};

SearchItem.propTypes = {
  isActive: PropTypes.bool,
  onSelect: PropTypes.func,
  result: PropTypes.object,
};

SearchItem.defaultProps = {
  isActive: false,
  onSelect: () => {},
};

export default SearchItem;
