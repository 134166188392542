import { useEffect, useState } from "react";
import { MOBILE_BREAKPOINT, DESKTOP_BREAKPOINT } from "src/utils/constants";

const useDeviceBreakpoint = () => {
  const [width, setWidth] = useState(0);

  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    const handleResize = () => {
      try {
        updateWidth();
      } catch (error) {
        // Handle or log the error as needed
        console.error("Error updating width:", error);
      }
    };

    try {
      window.addEventListener("resize", handleResize);
      updateWidth();
    } catch (error) {
      // Handle or log the error as needed
      console.error("Error setting up resize listener:", error);
    }

    return () => {
      try {
        window.removeEventListener("resize", handleResize);
      } catch (error) {
        // Handle or log the error as needed
        console.error("Error removing resize listener:", error);
      }
    };
  }, []);

  const isMobile = width < MOBILE_BREAKPOINT;
  const isTablet = width >= MOBILE_BREAKPOINT && width < DESKTOP_BREAKPOINT;
  const isDesktop = width >= DESKTOP_BREAKPOINT;

  return { isMobile, isTablet, isDesktop };
};

export default useDeviceBreakpoint;
