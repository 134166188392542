import {
  Typography,
  FormControlLabel,
  IconButton,
  Checkbox,
  Avatar,
  Stack,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  SvgIcon,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DeleteOutline } from "@mui/icons-material";
import { Copy } from "react-feather";

import Tooltip from "src/components/common/ToolTip";

const useStyles = makeStyles((theme) => ({
  checkboxCompleteClass: {
    "&.MuiCheckbox-colorSecondary.Mui-checked": {
      color: theme.palette.green,
    },
  },
}));

const VirtualSession = ({
  session,
  handleCopyLink,
  handleUpdateVirtualSession,
  handleDeleteVirtualSession,
  clientId,
}) => {
  const classes = useStyles();

  const { checkboxCompleteClass } = classes;
  const {
    id: sessionId,
    agent,
    isSent,
    isBooked,
    isCompleted,
    survey,
  } = session;

  const { id: surveyId, isCompleted: isSurveyCompleted } = survey || {};

  const surveyLink = `${process.env.REACT_APP_BASE_URL}/clients/${clientId}/survey/${surveyId}`;

  const statuses = [
    {
      label: "Sent",
      statusType: "isSent",
      isChecked: isSent,
    },
    {
      label: "Booked",
      statusType: "isBooked",
      isChecked: isBooked,
    },
    {
      label: "Completed",
      statusType: "isCompleted",
      isChecked: isCompleted,
    },
  ];

  return (
    <List dense>
      <ListItem
        sx={{
          flexDirection: "column",
          gap: 1,
          px: 0.25,
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ width: "100%" }}
        >
          <ListItemAvatar
            sx={{
              minWidth: 42,
              minHeight: 32,
            }}
          >
            <Avatar
              sx={{
                width: 32,
                height: 32,
              }}
              src={agent.avatarLink}
            />
          </ListItemAvatar>
          <Tooltip title={agent.virtualSessionBookingLink || ""}>
            <ListItemText
              primary={`${agent.firstName} ${agent.lastName}`}
              secondary={agent.virtualSessionBookingLink || "missing link"}
              secondaryTypographyProps={{
                variant: "body2",
                noWrap: true,
                sx: { cursor: "pointer" },
                onClick: () => {
                  if (agent.virtualSessionBookingLink) {
                    handleCopyLink(
                      "Coach Link",
                      agent.virtualSessionBookingLink
                    );
                  }
                },
              }}
            />
          </Tooltip>
          <Tooltip
            title={agent.virtualSessionBookingLink ? "Copy Coach Link" : ""}
          >
            <IconButton
              size="small"
              disabled={!agent.virtualSessionBookingLink}
              onClick={() => {
                handleCopyLink("Coach Link", agent.virtualSessionBookingLink);
              }}
            >
              <Copy fontSize="inherit" />
            </IconButton>
          </Tooltip>

          <Tooltip title="Delete Virtual Session">
            <IconButton
              size="small"
              color="primary"
              onClick={() => handleDeleteVirtualSession(sessionId, surveyId)}
            >
              <DeleteOutline />
            </IconButton>
          </Tooltip>
        </Stack>
        <Stack
          direction="row"
          sx={{
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between",
            "& label": {
              m: 0,
            },
          }}
        >
          {statuses.map(({ statusType, isChecked, label }) => {
            return (
              <FormControlLabel
                key={statusType}
                name={statusType}
                checked={isChecked}
                control={
                  <Checkbox
                    size="small"
                    className={checkboxCompleteClass}
                    color={
                      statusType !== "isCompleted" ? "primary" : "secondary"
                    }
                  />
                }
                label={
                  <Typography
                    sx={{
                      fontSize: 12,
                    }}
                    variant="h6"
                    noWrap
                  >
                    {label}
                  </Typography>
                }
                labelPlacement="top"
                onChange={(e) => {
                  handleUpdateVirtualSession(
                    sessionId,
                    e.target.name,
                    e.target.checked
                  );
                }}
              />
            );
          })}

          {isSurveyCompleted ? (
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={true}
                  className={checkboxCompleteClass}
                  color="secondary"
                  disableRipple
                />
              }
              label={
                <Typography
                  sx={{
                    fontSize: 12,
                  }}
                  variant="h6"
                  noWrap
                >
                  Survey
                </Typography>
              }
              labelPlacement="top"
            />
          ) : (
            <FormControlLabel
              disabled={!isCompleted}
              onClick={
                isCompleted ? () => handleCopyLink("Survey", surveyLink) : null
              }
              control={
                <Tooltip title={isCompleted ? "Copy Survey Link" : ""}>
                  <IconButton size="small">
                    <Copy />
                  </IconButton>
                </Tooltip>
              }
              label={
                <Typography
                  sx={{
                    fontSize: 12,
                  }}
                  variant="h6"
                  noWrap
                >
                  Survey
                </Typography>
              }
              labelPlacement="top"
            />
          )}
        </Stack>
      </ListItem>
    </List>
  );
};

export default VirtualSession;
