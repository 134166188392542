import React, { Fragment, useContext, useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Dashboard from "src/layouts/Dashboard";
import { UserContext } from "src/context/UserContext";
import { useSocketEvents, SOCKET_EVENTS } from "src/utils/socket";
import { CircularProgress, Box } from "@mui/material";
import { toast } from "react-toastify";
// Pages
import Login from "src/pages/Login";
import Chat from "src/pages/Chat";
import Clients from "src/pages/Clients";
import Coaches from "src/pages/Coaches";
import Client from "src/pages/Client";
import SignUp from "./pages/SignUp";
import ThankYou from "./pages/ThankYou";
import NotFound from "./pages/NotFound";
import Intake from "./pages/Intake";
import FollowUp from "./pages/FollowUp";
import Survey from "./pages/Survey";
import MassText from "./pages/MassText";
import Settings from "./pages/Settings";

const LoadingContent = () => (
  <Box
    position="absolute"
    left="50%"
    top="50%"
    style={{
      transform: "translate(-50%, -50%)",
    }}
  >
    <CircularProgress />
  </Box>
);

export const renderRoutes = (routes = []) => {
  return (
    <Switch>
      {routes.map((route, i) => {
        const Page = route.page;
        const Layout = route.layout || Fragment;

        const PageWithLayout = () => {
          const { authToken, setAuthToken, agent, setAgent } =
            useContext(UserContext);

          const socketHandleBroadcastNotification = (message) => {
            toast.success(message);
          };

          useEffect(() => {
            (async () => {
              try {
                if (!authToken) {
                  const token = await setAuthToken();
                  await setAgent(token);
                }
              } catch (error) {
                const errorText = "Error setting agent";
                toast.error(errorText);
                console.log(errorText, error);
              }
            })();
            // eslint-disable-next-line react-hooks/exhaustive-deps
          }, []);

          useSocketEvents([
            {
              eventName: SOCKET_EVENTS.BROADCAST_NOTIFICATION,
              handler: socketHandleBroadcastNotification,
            },
          ]);

          return <Layout>{agent ? <Page /> : <LoadingContent />}</Layout>;
        };

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            component={
              route.isPrivate
                ? withAuthenticationRequired(PageWithLayout, {
                    onRedirecting: () => <LoadingContent />,
                  })
                : Page
            }
          />
        );
      })}
      <Route path="*" render={() => <NotFound hasActions={false} />} />
    </Switch>
  );
};

const routes = [
  {
    exact: true,
    path: "/",
    page: Login,
    isPrivate: false,
  },
  {
    exact: false,
    path: "/chat",
    page: Chat,
    isPrivate: true,
    layout: Dashboard,
  },
  {
    exact: true,
    path: "/mass-text",
    page: MassText,
    isPrivate: true,
    layout: Dashboard,
  },
  {
    exact: true,
    path: "/clients",
    page: Clients,
    isPrivate: true,
    layout: Dashboard,
  },
  {
    exact: true,
    path: "/coaches",
    page: Coaches,
    isPrivate: true,
    layout: Dashboard,
  },
  {
    exact: true,
    path: "/clients/:clientId",
    page: Client,
    isPrivate: true,
    layout: Dashboard,
  },
  {
    exact: false,
    path: "/sign-up",
    page: SignUp,
    isPrivate: false,
  },
  {
    exact: false,
    path: "/thank-you",
    page: ThankYou,
    isPrivate: false,
  },
  {
    exact: true,
    path: "/clients/:clientId/forms/intake/:formId",
    page: Intake,
    isPrivate: false,
  },
  //  Legacy route
  {
    exact: false,
    path: "/forms/intake",
    page: Intake,
    isPrivate: false,
  },
  {
    exact: true,
    path: "/clients/:clientId/forms/follow-up/:formId",
    page: FollowUp,
    isPrivate: false,
  },

  //  Legacy route
  {
    exact: false,
    path: "/forms/follow-up",
    page: FollowUp,
    isPrivate: false,
  },
  {
    exact: true,
    path: "/clients/:clientId/survey/:surveyId",
    page: Survey,
    isPrivate: false,
  },
  {
    exact: false,
    path: "/settings",
    page: Settings,
    isPrivate: true,
    layout: Dashboard,
  },
];

export default routes;
