import PropTypes from "prop-types";

import { Stack, Chip, Box } from "@mui/material";

const JourneyPathStatus = ({ status }) => {
  return (
    <Stack spacing={0.5} direction="column" alignItems="flex-start">
      <Chip
        sx={{
          py: 1.75,
          minWidth: 160,
          borderRadius: 2,
          backgroundColor: (theme) =>
            status === "TTC"
              ? theme.palette.lightBlue
              : theme.palette.lightPink,
        }}
        label={status}
      />
    </Stack>
  );
};

export default JourneyPathStatus;

JourneyPathStatus.propTypes = {
  status: PropTypes.string,
};
