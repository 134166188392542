import React, { forwardRef } from "react";
import { default as ReactPhoneInput } from "react-phone-number-input";
import { TextField } from "@mui/material";
import TextInput from "../TextInput";

const textInput = forwardRef((props, ref) => {
  return <TextInput {...props} inputRef={ref} />;
});

const textField = forwardRef((props, ref) => {
  return <TextField {...props} inputRef={ref} />;
});

const PhoneInput = ({ value, onChange, inputComponent, ...rest }) => {
  return (
    <ReactPhoneInput
      value={value}
      inputComponent={
        inputComponent === "mui-textfield" ? textField : textInput
      }
      country="US"
      defaultCountry="US"
      onChange={(value) => onChange(value || "")}
      {...rest}
    />
  );
};

export default PhoneInput;
