import { Card, Grid, CardContent, Stack } from "@mui/material";
import DisplayText from "src/components/common/Crm/DisplayText";
import DisplayChips from "src/components/common/Crm/DisplayChips";

const Stats = ({
  ttcDuration,
  ethnicIdentities,
  genderIdentity,
  age,
  ageRange,
  ivfCycle,
  miscarriageAmount,
  liveBirthAmount,
  pregnancyAmount,
  hasInsurance,
  insuranceBenefits,
  wellnessStatuses,
  resources,
  currentTreatments,
  priorDiagnosises,
  otherPriorDiagnosis,
  journeyDifficulties,
  bmi,
  height,
  weight,
  hasInsuranceBenefit,
  relationshipStatus,
  signupAtReStatus,
  signupAtObgynStatus,
}) => {
  return (
    <Card
      elevation={1}
      sx={{
        pb: 0,
      }}
    >
      <CardContent
        sx={{
          pb: "8px !important",
        }}
      >
        <Grid container spacing={2}>
          {/* ROW 1 */}
          <Grid
            item
            xs={12}
            md={3}
            container
            spacing={0.75}
            alignContent="start"
          >
            <Grid item xs={12}>
              <DisplayText
                primary={"Relationship Status"}
                secondary={relationshipStatus}
              />
            </Grid>
            <Grid item xs={12}>
              <DisplayText primary={"Age"} secondary={age} />
            </Grid>

            <Grid item xs={12}>
              <DisplayText primary={"Age Range"} secondary={ageRange} />
            </Grid>

            <Grid item xs={12}>
              <DisplayText
                primary="Gender Identity"
                secondary={genderIdentity}
              />
            </Grid>

            <Grid item xs={12}>
              <DisplayChips
                label={"Ethnic Identities"}
                values={ethnicIdentities}
              />
            </Grid>
          </Grid>

          {/* ROW 2 */}
          <Grid
            item
            xs={12}
            md={3}
            container
            spacing={0.75}
            alignContent="start"
          >
            <Grid item xs={12}>
              <DisplayText primary={"TTC Duration"} secondary={ttcDuration} />
            </Grid>

            <Grid item xs={12}>
              <DisplayText primary="BMI" secondary={bmi} />
            </Grid>

            <Grid item xs={12}>
              <DisplayText
                primary="# of Pregnancies"
                secondary={pregnancyAmount}
              />
            </Grid>

            <Grid item xs={12}>
              <DisplayText
                primary={"# of Live Births"}
                secondary={liveBirthAmount}
              />
            </Grid>

            <Grid item xs={12}>
              <DisplayText
                primary="# of Miscarriages"
                secondary={miscarriageAmount}
              />
            </Grid>
          </Grid>

          {/* ROW 3 */}
          <Grid
            item
            xs={12}
            md={3}
            container
            spacing={0.75}
            alignContent="start"
          >
            <Grid item xs={12}>
              <DisplayText primary="Has Insurance" secondary={hasInsurance} />
            </Grid>

            <Grid item xs={12}>
              <DisplayText
                primary="Has Benefits"
                secondary={hasInsuranceBenefit}
              />
            </Grid>

            <Grid item xs={12}>
              <DisplayText primary={"IVF Cycles"} secondary={ivfCycle} />
            </Grid>

            <Grid item xs={12}>
              <DisplayText
                primary="Had RE At Signup "
                secondary={signupAtReStatus}
              />
            </Grid>

            <Grid item xs={12}>
              <DisplayText
                primary="Had OBGYN At Signup"
                secondary={signupAtObgynStatus}
              />
            </Grid>
          </Grid>

          {/* ROW 4 */}
          <Grid
            item
            xs={12}
            md={3}
            container
            spacing={0.75}
            alignContent="start"
          >
            <Grid item xs={12}>
              <DisplayChips
                label={"Current Treatments"}
                values={currentTreatments}
              />
            </Grid>

            <Grid item xs={12}>
              <DisplayChips
                label={"Wellness Status"}
                values={wellnessStatuses}
              />
            </Grid>

            <Grid item xs={12}>
              <DisplayChips
                label={"Journey Difficulties"}
                values={journeyDifficulties}
              />
            </Grid>

            <Grid item xs={12}>
              <DisplayChips
                label={"Fertility Services/Resources"}
                values={resources}
              />
            </Grid>

            <Grid item xs={12}>
              <DisplayChips
                label={"Prior Diagnoses"}
                values={priorDiagnosises}
              />
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default Stats;
