import AtReStatusForm from "./AtReStatusForm";
import AtObgynStatusForm from "./AtObgynStatusForm";
import JourneyPathStatusForm from "./JourneyPathStatusForm";
import PregnantStatusForm from "./PregnantStatusForm";

const StatusForm = ({ type, status, setEditStatusType, handleSubmit }) => {
  switch (type) {
    case "atReStatus":
      return (
        <AtReStatusForm
          status={status}
          setEditStatusType={setEditStatusType}
          handleSubmit={handleSubmit}
        />
      );
    case "atObgynStatus":
      return (
        <AtObgynStatusForm
          status={status}
          setEditStatusType={setEditStatusType}
          handleSubmit={handleSubmit}
        />
      );
    case "pregnantStatus":
      return (
        <PregnantStatusForm
          status={status}
          setEditStatusType={setEditStatusType}
          handleSubmit={handleSubmit}
        />
      );
    case "journeyPathStatus":
      return (
        <JourneyPathStatusForm
          status={status}
          setEditStatusType={setEditStatusType}
          handleSubmit={handleSubmit}
        />
      );
    default:
      return null;
  }
};

export default StatusForm;
