import { Typography, IconButton, SvgIcon, Stack, Divider } from "@mui/material";
import { Trash as DeleteIcon, Edit as EditIcon } from "react-feather";

import moment from "moment";

const TimelineItemContainer = ({
  hasEditAction,
  hasDeleteAction,
  handleSetEditId,
  handleDeleteItem,
  currentEditId,
  createdAt,
  index,
  children,
  itemId,
  itemType,
}) => {
  const editId = index + 1;

  return (
    <Stack mb={1} id={`journey-item-${editId}`}>
      <Stack direction="row">
        <Typography
          color="secondary"
          variant="caption"
          flex={3}
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {moment.utc(createdAt).format("L")}
        </Typography>

        <Stack direction="row">
          {hasEditAction && (
            <IconButton
              color="primary"
              size="small"
              disabled={Boolean(currentEditId)}
              onClick={() => handleSetEditId(editId)}
              sx={{
                p: 0,
              }}
            >
              <SvgIcon>
                <EditIcon size={16} />
              </SvgIcon>
            </IconButton>
          )}
          {hasDeleteAction && (
            <IconButton
              color="primary"
              size="small"
              disabled={Boolean(currentEditId)}
              onClick={() =>
                handleDeleteItem({
                  itemId,
                  itemType,
                })
              }
              sx={{
                p: 0,
              }}
            >
              <SvgIcon>
                <DeleteIcon size={16} />
              </SvgIcon>
            </IconButton>
          )}
        </Stack>
      </Stack>

      {children}
    </Stack>
  );
};

export default TimelineItemContainer;
