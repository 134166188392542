import { Link as RouterLink } from "react-router-dom";
import {
  Avatar,
  Box,
  IconButton,
  Link,
  SvgIcon,
  Tooltip,
  Typography,
  Grid,
  Stack,
} from "@mui/material";
import { AddCircleOutline } from "@mui/icons-material";
import ChatIcon from "@mui/icons-material/Chat";
import DatePicker from "src/components/common/DatePicker";
import getInitials from "src/utils/getInitials";
import { useHistory } from "react-router-dom";
import Tag from "src/components/common/Tag";

import moment from "moment";

const Columns = ({
  setIsCommentModalOpen,
  setCommentClient,
  handleEditFollowUpDate,
}) => {
  const history = useHistory();

  return [
    {
      field: "displayName",
      headerName: "Name",
      editable: false,
      sortable: false,
      flex: 1,
      renderCell: ({ row: client }) => {
        return (
          <Box key={client.id} sx={{ display: "flex", alignItems: "center" }}>
            <Avatar
              sx={{
                fontSize: "1rem",
                width: 30,
                height: 30,
              }}
            >
              {getInitials(`${client.displayName}`)}
            </Avatar>
            <Box ml={2}>
              <Link
                variant="body1"
                color={client.isActive ? "text.primary" : "error.main"}
                component={RouterLink}
                underline="none"
                to={`/clients/${client.id}`}
              >
                {client.displayName}
              </Link>
            </Box>
          </Box>
        );
      },
    },
    {
      field: "agent",
      headerName: "Coach",
      editable: false,
      sortable: false,
      flex: 1,
      renderCell: ({ row: client }) => {
        return (
          <>
            {client.agent?.id ? (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Avatar
                  src={client.agent.avatarLink}
                  sx={{
                    width: 30,
                    height: 30,
                  }}
                />
                <Box ml={2}>
                  <Link
                    variant="body1"
                    color="textPrimary"
                    component={RouterLink}
                    underline="none"
                    to={`/clients/${client.id}`}
                  >
                    {client.agent?.firstName} {client.agent?.lastName}
                  </Link>
                </Box>
              </Box>
            ) : (
              <Typography>N/A</Typography>
            )}
          </>
        );
      },
    },
    {
      field: "followUpDate",
      headerName: "Follow Up Date",
      editable: false,
      flex: 1.3,
      sortable: true,
      renderCell: ({ row: client }) => {
        return (
          <DatePicker
            fontSize={(theme) => theme.typography.body2.fontSize}
            minDate={new Date()}
            value={client.followUpDate}
            handleChange={(date) => handleEditFollowUpDate(date, client.id)}
          />
        );
      },
    },

    {
      field: "tags",
      headerName: "Tags",
      sortable: false,
      flex: 1,
      renderCell: ({ row: client }) => (
        <Stack direction="row" spacing={1}>
          {client.tagsNew?.map((tag) => {
            return <Tag key={tag.id} label={tag.label} />;
          })}
        </Stack>
      ),
    },

    {
      field: "Age Range",
      headerName: "Age Range",
      sortable: false,
      flex: 1,
      renderCell: ({ row: client }) => {
        return (
          <Typography variant="body1">
            {client.ageRange ? client.ageRange?.label : "N/A"}
          </Typography>
        );
      },
    },
    {
      field: "Journey Stage",
      headerName: "Journey Stage",
      sortable: false,
      flex: 1,
      renderCell: ({ row: client }) => {
        return (
          <Tooltip title={client.journeyStage || ""} arrow placement="top">
            <Typography
              sx={{
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
              variant="body1"
            >
              {client.journeyStage ? client.journeyStage : "N/A"}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      headerName: "Comments",
      field: "comment",
      sortable: false,
      flex: 1.5,
      renderCell: ({ row: client }) => {
        const lastCommentBody = client.lastComment
          ? `${moment(client.lastComment.createdAt).format("L")} - ${
              client.lastComment?.comment
            }`
          : "";

        return (
          <Grid
            container
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "nowrap",
            }}
          >
            <Grid
              item
              sx={{
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              <Tooltip title={lastCommentBody} arrow placement="top">
                <Typography
                  sx={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  {lastCommentBody}
                </Typography>
              </Tooltip>
            </Grid>
            <Grid item>
              <IconButton
                color="primary"
                variant="contained"
                size="small"
                sx={{
                  p: 0,
                }}
                onClick={() => {
                  setIsCommentModalOpen(true);
                  setCommentClient(client);
                }}
              >
                <SvgIcon fontSize="small" color="primary">
                  <AddCircleOutline />
                </SvgIcon>
              </IconButton>
            </Grid>
          </Grid>
        );
      },
    },
    {
      field: "Chat",
      headerName: "",
      sortable: false,
      flex: 0.3,
      renderCell: ({ row: client }) => {
        return (
          <IconButton
            size="small"
            onClick={() => {
              history.push(
                `/chat?phoneNumber=${encodeURIComponent(client.phoneNumber)}`,
                {
                  displayName: client.displayName,
                }
              );
            }}
          >
            <SvgIcon fontSize="small" color="primary">
              <ChatIcon />
            </SvgIcon>
          </IconButton>
        );
      },
    },
  ];
};

export default Columns;
