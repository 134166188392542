import {
  Container,
  Typography,
  Link,
  Card,
  CardContent,
  CardHeader,
} from "@mui/material";
import { useRef } from "react";
import Page from "src/components/common/Page";
import TopBar from "src/components/common/TopBar";
import { Link as RouterLink } from "react-router-dom";

const NotFound = ({ hasActions }) => {
  const pageRef = useRef();

  return (
    <Page
      sx={{ backgroundColor: "background.dark" }}
      title="NotFound"
      ref={pageRef}
    >
      <TopBar hasActions={hasActions} />
      <Container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
        maxWidth={"sm"}
      >
        <Card
          sx={{
            py: 10,
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CardHeader
            title="404"
            titleTypographyProps={{
              variant: "h1",
              fontWeight: 700,
              color: "primary.main",
              align: "center",
            }}
          />
          <CardContent sx={{ textAlign: "center" }}>
            <Typography variant="h5" gutterBottom>
              This page could not be found.
            </Typography>

            <Link color="primary" component={RouterLink} to={`/chat`}>
              Back to chat
            </Link>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default NotFound;
