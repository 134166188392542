import { useEffect } from "react";

const HiddenLogicComponent = ({
  value,
  children,
  clearFieldNames,
  clearFields,
}) => {
  useEffect(() => {
    return () => {
      // cleanup

      if (clearFields) {
        clearFields(clearFieldNames);
      }
    };
  }, [value]);

  if (!value) {
    return null;
  } else {
    return children;
  }
};

export default HiddenLogicComponent;
