import React, { useState, useEffect, useContext, useRef } from "react";
import { toast } from "react-toastify";
import axios from "src/utils/axios";
import { ChatContext } from "src/context/ChatContext";
import PropTypes from "prop-types";
import { IconButton, SvgIcon, Tooltip, Stack } from "@mui/material";
import { Send as SendIcon, Save as SaveIcon } from "react-feather";
import { useAuth0 } from "@auth0/auth0-react";
import ConfirmationModal from "src/components/Chat/ConfirmationModal";
import AutoResizeInput from "src/components/common/AutoResizeInput";

const MessageComposer = ({ selectedPhoneNumber, ...rest }) => {
  const { recipient, handleSaveDraft, handleDeleteDraft, handleReadMessage } =
    useContext(ChatContext);
  const { user } = useAuth0();
  const { displayName, draft } = recipient || {};
  const [message, setMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef(null);

  const sendMessage = async ({ body, phoneNumber, auth0Id }) => {
    try {
      await axios.post("/api/messages/out", {
        body,
        phoneNumber,
        auth0Id,
      });
    } catch (error) {
      toast.error(`Error sending test message`);
    }
  };

  const handleChange = (event) => {
    event.persist();

    setMessage(event.target.value);
    if (draft && event.target.value === "") {
      handleDeleteDraft(recipient);
    }
  };

  const handleKeyDown = (event) => {
    if (!!message.trim() && event.keyCode === 13 && !event.shiftKey) {
      setIsModalOpen(true);
      event.preventDefault();
    }
  };

  const handleSend = () => {
    if (!!message.trim()) {
      setIsModalOpen(true);
    }
  };

  const handleSendMessageConfirmation = () => {
    sendMessage({
      auth0Id: user.sub,
      body: message,
      phoneNumber: selectedPhoneNumber,
    });
    setMessage("");
    setIsModalOpen(false);
  };

  useEffect(() => {
    (async () => {
      // Set draft if recipient has one
      if (draft) {
        setMessage(draft);
      } else {
        setMessage("");
      }
    })();
  }, [selectedPhoneNumber, draft]);

  // Explainer for the how handleReadMessage works:
  // 1. InputRef sets focus on the input field when the recipient changes
  // 2. AutoResizeInput triggers the onFocus event when the input field is focused and sets isFocused to true
  // 3. When this focus changes and if recipient has an unreadMessage, the handleReadMessage function is called to set the unreadMessage to read
  useEffect(() => {
    if (isFocused && recipient?.hasUnreadClientMessageIds) {
      handleReadMessage({
        clientId: recipient.id,
      });
    }
  }, [isFocused, recipient?.hasUnreadClientMessageIds]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
    // Uses recipient id to in dependency array to refocus on recipient change
  }, [recipient?.id, inputRef]);

  return (
    <>
      <Stack
        direction="row"
        sx={{
          px: 2,
          py: 1,
        }}
        {...rest}
      >
        {/* Move to its own component */}
        <Stack
          sx={{
            flexGrow: 1,
            pl: 2,
          }}
        >
          <AutoResizeInput
            inputRef={inputRef}
            name="Message"
            value={message}
            handleChange={handleChange}
            handleKeyDown={handleKeyDown}
            onBlur={() => setIsFocused(false)}
            onFocus={() => setIsFocused(true)}
            hasCharacterCount
            placeholder={`Send a message to ${displayName || "..."}`}
            autoFocus
          />
        </Stack>
        <Stack
          direction="row"
          sx={{
            alignItems: "center",
            mb: 2.75,
          }}
        >
          <Tooltip title={message ? "Send" : ""}>
            <IconButton
              color="primary"
              disabled={!message}
              onClick={handleSend}
            >
              <SvgIcon fontSize="small">
                <SendIcon />
              </SvgIcon>
            </IconButton>
          </Tooltip>
          <Tooltip title={message ? "Save" : ""}>
            <IconButton
              color="primary"
              disabled={!message}
              onClick={() => handleSaveDraft(message, recipient)}
            >
              <SvgIcon fontSize="small">
                <SaveIcon />
              </SvgIcon>
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>

      <ConfirmationModal
        content={`Are you sure you want to send a message to ${displayName}?`}
        ctaText="Send"
        handleSetConfirmationModal={setIsModalOpen}
        handleSendMessageConfirmation={handleSendMessageConfirmation}
        isModalOpen={isModalOpen}
      />
    </>
  );
};

MessageComposer.propTypes = {
  disabled: PropTypes.bool,
  handleSetConfirmationModal: PropTypes.func,
};

MessageComposer.defaultProps = {};

export default MessageComposer;
