import { useState, useEffect } from "react";
import { Stack, Typography } from "@mui/material";
import { TextInput } from "src/components/Forms/common";
import axios from "src/utils/axios";
import Loader from "src/components/common/Loader";
import { toast } from "react-toastify";

const saveComment = async (item) => {
  try {
    const { clientId, ...values } = item;

    await axios.put(`/api/clients/${clientId}/journey/${values.id}`, values);
  } catch (error) {
    throw new Error("Failed to save comment", error);
  }
};

const CommentItem = ({ item: initialItem }) => {
  const [item, setItem] = useState({ comment: { id: null, content: "" } });
  const [isLoading, setIsLoading] = useState(false);

  const { comment } = item;

  const handleSaveComment = () => {
    try {
      setIsLoading(true);

      if (item.comment.content.length < 3) {
        setItem(initialItem);
        toast.error(
          "Failed to save comment: Comment must be at least 3 characters"
        );
        setIsLoading(false);
        return;
      }

      if (initialItem.comment.content !== comment.content) {
        saveComment(item);
      }
    } catch (error) {
      console.error(error);
      toast.error(error.message);
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  };

  useEffect(() => {
    if (!initialItem) return;

    setItem(initialItem);
  }, [initialItem]);

  return (
    <Stack>
      <Typography
        sx={{
          fontSize: 12,
        }}
        component="label"
        variant="h6"
      >
        Comment
      </Typography>
      <TextInput
        variant="filled"
        name="comment"
        multiline
        value={comment.content}
        onChange={(e) =>
          setItem({ ...item, comment: { ...comment, content: e.target.value } })
        }
        onBlur={handleSaveComment}
        sx={{
          p: 0,
          "& textarea": {
            p: 0.75,
            background: (theme) => theme.palette.grey[100],
          },
        }}
        endAdornment={
          isLoading && (
            <Loader
              size="16px"
              style={{ position: "absolute", top: 3, right: 3 }}
            />
          )
        }
      />
    </Stack>
  );
};

export default CommentItem;
