import StyledTypography from "./StyledTypography";

const Robyn = ({ hasHeading }) => {
  return (
    <>
      {hasHeading && (
        <StyledTypography
          variant="h6"
          sx={{ fontWeight: 600, marginBottom: (theme) => theme.spacing(1) }}
        >
          Terms & Conditions
        </StyledTypography>
      )}

      <StyledTypography>
        Fertility Outreach is brought to you by Fertility Out Loud in
        partnership with Robyn. By submitting your information to sign up and
        use Fertility Outreach, you're agreeing to{" "}
        <a
          href="https://wearerobyn.co/terms-of-use"
          target="_blank"
          rel="noreferrer"
        >
          Robyn's Terms of Use
        </a>{" "}
        and{" "}
        <a
          href="https://wearerobyn.co/privacy-policy"
          target="_blank"
          rel="noreferrer"
        >
          Privacy Notice
        </a>
        . Personal information collected and used by Robyn for Fertility
        Outreach will not be shared with Ferring. Robyn offers resources and
        providers for the full path to parenthood.
      </StyledTypography>
      <StyledTypography>
        The information we provide is not intended or implied to be a substitute
        for professional medical advice, diagnosis or treatment. For medical
        emergencies, contact your primary care physician or dial 911
        immediately.
      </StyledTypography>
      <StyledTypography>
        I understand and agree that my personal information will be treated as
        described in Robyn's Privacy Notice and used to deliver the Fertility
        Outreach service. I understand that details regarding my privacy rights
        and contact information for privacy questions about Robyn, Fertility
        Outreach and my privacy rights are in Robyn's privacy notice.
      </StyledTypography>
    </>
  );
};

export default Robyn;
