import { useRef, useContext, useState, useEffect } from "react";
import { ChatContext } from "src/context/ChatContext";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import SearchList from "../SearchList";
import { Box, Typography } from "@material-ui/core";

const SearchMessageResults = ({ handleSelectMessage }) => {
  const barRef = useRef(null);

  const {
    messageSearchResults,
    textQuery,
    filterByUserType,
    messagePage,
    handleSearchMessages,
    isLoading,
  } = useContext(ChatContext);

  const [isScrollBottom, setIsScrollBottom] = useState(false);

  const handleFetchMoreMessages = async () => {
    handleSearchMessages({
      input: textQuery,
      filterByUserType,
      pageNumber: messagePage.current + 1,
      isMore: true,
    });
  };

  const handleBottomMessagePagination = () => {
    if (
      messagePage.current < messagePage.total &&
      messageSearchResults.length > 0
    ) {
      handleFetchMoreMessages();
    }
  };

  // Reset scroll position to be false when loading to prevent scroll to bottom from triggering handleFetchMoreMessages
  useEffect(() => {
    if (isLoading) {
      setIsScrollBottom(false);
    }
  }, [isLoading]);

  return (
    // FIXME: Once user scrolls to the bottom, the Perfect Scrollbar fetches more messages until it reaches the end of the results
    <PerfectScrollbar
      containerRef={(ref) => {
        barRef.current = ref;
      }}
      options={{ suppressScrollX: true }}
      onYReachEnd={() => {
        setIsScrollBottom(true);
        handleBottomMessagePagination();
      }}
    >
      <SearchList handleSelectMessage={handleSelectMessage} />
      {Boolean(isScrollBottom && messagePage.current >= messagePage.total) && (
        <Box display="flex" justifyContent="center">
          <Typography variant="overline" align="center">
            End of Results
          </Typography>
        </Box>
      )}
    </PerfectScrollbar>
  );
};

SearchMessageResults.propTypes = {
  threads: PropTypes.array,
  selectedPhoneNumber: PropTypes.string,
};

export default SearchMessageResults;
