import { useContext, useMemo } from "react";
import { ChatContext } from "src/context/ChatContext";
import { Stack, Divider } from "@mui/material";
import MessageList from "../MessageList";
import MessageComposer from "../MessageComposer";

const Thread = ({ selectedPhoneNumber }) => {
  const { selectedMessage } = useContext(ChatContext);

  return (
    <Stack
      sx={{
        flexGrow: 1,
      }}
    >
      {useMemo(() => {
        return <MessageList selectedPhoneNumber={selectedPhoneNumber} />;
      }, [selectedPhoneNumber])}
      <Divider />

      {Boolean(selectedMessage) && (
        <MessageComposer selectedPhoneNumber={selectedPhoneNumber} />
      )}
    </Stack>
  );
};

export default Thread;
