import PropTypes from "prop-types";

import { Stack, Chip, Box } from "@mui/material";

const PregnantStatus = ({ status }) => {
  return (
    <Stack spacing={0.5} direction="column" alignItems="flex-start">
      <Chip
        sx={{
          backgroundColor: (theme) => theme.palette.brightYellow,
          py: 1.75,
          minWidth: 160,
          borderRadius: 2,
        }}
        label={status}
      />
    </Stack>
  );
};

export default PregnantStatus;

PregnantStatus.propTypes = {
  status: PropTypes.string,
};
