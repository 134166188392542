import { Box, Typography } from "@mui/material";

const ErrorBanner = () => {
  return (
    <Box
      sx={{
        backgroundColor: "error.muiError",
        textAlign: "center",
        color: "white",
        px: { xs: 5, md: 1 },
        py: 1,
      }}
    >
      <Typography variant="brandonTitle">
        Please make sure you filled out all required fields above.
      </Typography>
    </Box>
  );
};

export default ErrorBanner;
