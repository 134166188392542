import React, { useState } from "react";
import axios from "src/utils/axios";
import { Formik } from "formik";
import * as Yup from "yup";
import Loader from "src/components/common/Loader";
import {
  Dialog,
  DialogTitle,
  Divider,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
  Button,
  Box,
  FormHelperText,
} from "@mui/material";

const getValidationSchema = (isEditing) =>
  Yup.object().shape({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    email: Yup.string().email().required("Required"),
    password: isEditing ? "" : Yup.string().required("Required"),
  });

const addCoach = async ({ values, token }) => {
  try {
    const { data: newCoach } = await axios.post(`/api/users/coaches`, values);
    return newCoach;
  } catch (error) {
    throw error;
  }
};

const updateCoach = async ({ userId, values, token }) => {
  const { data: updatedCoach } = await axios.put(
    `/api/agents/${userId}`,
    values
  );
  return updatedCoach;
};

const AddEditModal = ({
  isOpen,
  setIsOpen,
  coaches,
  setCoach,
  coach,
  setCoaches,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const isEditing = coach ? true : false;

  const handleAddCoach = async (values) => {
    try {
      const newCoach = await addCoach({ values });
      const updatedCoaches = [...coaches, newCoach];
      setCoaches(updatedCoaches);
      setIsOpen(false);
    } catch (error) {
      throw error;
    }
  };

  const handleUpdateCoach = async (values) => {
    const updatedCoach = await updateCoach({
      userId: coach.id,
      values,
    });
    const updatedCoaches = [...coaches];
    const providerIndex = updatedCoaches.findIndex(
      (coach) => coach.id === updatedCoach.id
    );
    updatedCoaches[providerIndex] = updatedCoach;

    //   Reset Form
    setCoach(null);
    setCoaches(updatedCoaches);
    setIsOpen(false);
  };

  const handleSubmit = async (values) => {
    setIsLoading(true);
    try {
      if (isEditing) {
        await handleUpdateCoach(values);
      } else {
        await handleAddCoach(values);
      }
    } catch (error) {
      let errorMessage = error.message;
      if (error.response) {
        const { data: message } = error.response;
        errorMessage = message;
      }
      setErrorMessage(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setCoach(null);
    setIsOpen(false);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        handleClose();
        setErrorMessage(null);
      }}
    >
      <DialogTitle>{isEditing ? "Edit" : "Add"} Coach</DialogTitle>
      <Divider />
      <DialogContent>
        {isLoading && (
          <Box
            minHeight={300}
            minWidth={400}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Loader />
          </Box>
        )}
        {!isLoading && (
          <Formik
            validationSchema={getValidationSchema(isEditing)}
            enableReinitialize
            validateOnBlur={false}
            validateOnChange={false}
            initialValues={
              coach
                ? coach
                : {
                    firstName: "",
                    lastName: "",
                    email: "",
                    password: "",
                    avatar: "",
                    modalityId: "",
                  }
            }
            onSubmit={handleSubmit}
          >
            {({
              values,
              handleChange,
              errors,
              handleSubmit,
              setFieldValue,
            }) => {
              return (
                <form
                  onSubmit={(e) => {
                    handleSubmit(e);
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="First Name *"
                        name="firstName"
                        value={values.firstName}
                        error={Boolean(errors.firstName)}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="Last Name *"
                        name="lastName"
                        value={values.lastName}
                        onChange={handleChange}
                        error={Boolean(errors.lastName)}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="Email *"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        error={Boolean(errors.email)}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        name="avatar"
                        label="Profile Image Link"
                        value={values.avatar}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  </Grid>
                  <FormHelperText error>{errorMessage}</FormHelperText>
                  <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button type="submit" color="primary" variant="contained">
                      {isEditing ? "Save Changes" : "Add Coach"}
                    </Button>
                  </DialogActions>
                </form>
              );
            }}
          </Formik>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default AddEditModal;
