import { Chip } from "@mui/material";
import PropTypes from "prop-types";

function ThreadChip({ label }) {
  return (
    <Chip
      sx={{
        fontSize: 10,
        backgroundColor: label === "INACTIVE" ? "error.main" : "info.light",
        fontWeight: 500,
        color: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textTransform: "uppercase",
        ellipsis: true,
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      }}
      size="small"
      label={label}
    />
  );
}

export default ThreadChip;

ThreadChip.propTypes = {
  label: PropTypes.string.isRequired,
};
