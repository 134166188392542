import { useRef, useContext, useEffect } from "react";
import { ChatContext } from "src/context/ChatContext";
import { useSocketEvents, SOCKET_EVENTS } from "src/utils/socket";
import PerfectScrollbar from "react-perfect-scrollbar";
import ThreadList from "../ThreadList";
import PropTypes from "prop-types";

const RecentThreads = ({ handleSelectThread, selectedPhoneNumber }) => {
  const { threads, setThreads, setIsLoading, handleGetThreads } =
    useContext(ChatContext);

  const threadPageSize = 100;
  const totalPagesRef = useRef();
  const currentPagesRef = useRef(1);
  const barRef = useRef(null);

  const handleScrollTop = () => {
    if (barRef.current) {
      barRef.current.scrollTop = 0;
    }
  };

  const handleFetchMoreThreads = async (threadLimit) => {
    const threads = await handleGetThreads({
      threadLimit,
    });
    setThreads(threads);
    return threads;
  };

  const handleTopThreadPagination = () => {
    if (currentPagesRef.current > 1) {
      handleFetchMoreThreads(threadPageSize);
      currentPagesRef.current = 1;
    }
  };

  const handleBottomThreadPagination = () => {
    if (
      currentPagesRef.current < totalPagesRef.current &&
      threads.threadItems.length > 0
    ) {
      currentPagesRef.current = currentPagesRef.current + 1;
      const limit = currentPagesRef.current * threadPageSize;
      handleFetchMoreThreads(limit);
    }
  };

  const socketHandleUpdateThreads = async () => {
    const threads = await handleGetThreads({
      threadLimit: currentPagesRef.current * threadPageSize,
    });

    setThreads(threads);
  };

  // Render Methods
  useEffect(() => {
    totalPagesRef.current = threads.count / threadPageSize;
  });

  // Get the Initial batch of threads
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const threads = await handleGetThreads({
        threadLimit: threadPageSize,
      });
      setThreads(threads);
      setIsLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useSocketEvents(
    [
      {
        eventName: SOCKET_EVENTS.UPDATE_THREADS,
        handler: socketHandleUpdateThreads,
      },
    ],
    selectedPhoneNumber
  );

  return (
    <PerfectScrollbar
      containerRef={(ref) => {
        barRef.current = ref;
      }}
      options={{ suppressScrollX: true }}
      onYReachStart={() => {
        handleTopThreadPagination();
      }}
      onYReachEnd={() => {
        handleBottomThreadPagination();
      }}
    >
      <ThreadList
        handleSelectThread={(recipient) => {
          handleSelectThread(recipient);
          handleScrollTop();
        }}
        selectedPhoneNumber={selectedPhoneNumber}
      />
    </PerfectScrollbar>
  );
};

RecentThreads.propTypes = {
  selectedPhoneNumber: PropTypes.string,
  handleSelectThread: PropTypes.func,
};

export default RecentThreads;
