import React, { useEffect, useRef, useState, useContext } from "react";
import axios from "src/utils/axios";
import { Container } from "@mui/material";
import Page from "src/components/common/Page";
import CoachesTable from "src/components/Coaches/Table";
import { UserContext } from "src/context/UserContext";

const getCoaches = async () => {
  try {
    const { data: coaches } = await axios.get("/api/agents", {
      params: {
        role: "Coach",
      },
    });

    return coaches;
  } catch (error) {
    console.log(error.message);
    return [];
  }
};

const Coaches = () => {
  const pageRef = useRef(null);
  const [coaches, setCoaches] = useState([]);
  const { isAgentAdmin } = useContext(UserContext);

  useEffect(() => {
    (async () => {
      const coaches = await getCoaches();
      setCoaches(coaches);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page title="Coaches" ref={pageRef}>
      <Container
        sx={{
          p: { xs: 0, md: 3 },
        }}
        maxWidth="xl"
      >
        <CoachesTable
          isAgentAdmin={isAgentAdmin}
          coaches={coaches}
          setCoaches={setCoaches}
        />
      </Container>
    </Page>
  );
};

export default Coaches;
