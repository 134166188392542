exports.MOBILE_WIDTH = 960;

exports.filterOptions = [
  {
    id: 1,
    label: "Comments",
    type: "hasComment",
    isChecked: true,
  },
  {
    id: 2,
    label: "Events",
    type: "hasEvent",
    isChecked: true,
  },
  {
    id: 4,
    label: "Five Points",
    type: "hasWellbeingPoint",
    isChecked: true,
  },
];

exports.wellnessStatusDescriptions = [
  {
    id: 1,
    description:
      "(anticipating the worst, worrying about the future when trying to grow your family)",
  },
  {
    id: 2,
    description:
      "(hard time choosing what is right or good for you even when trying to make change, rebelling or against what you know is right or good for you)",
  },
  {
    id: 3,
    description:
      "(from social media sources, groups or others to rely on rather than yourself and feeling overwhelmed)",
  },
  {
    id: 4,
    description:
      "(to actions and/or comments from others who share their views or opinions and compare experiences with you)",
  },
  {
    id: 5,
    description: "(headaches, fatigue, muscle tension and/or restlessness)",
  },
  {
    id: 6,
    description: "(during sleep, unable to calm racing thoughts)",
  },
  {
    id: 7,
    description:
      "(situations in your daily life that make it difficult to relax, exercise, eat healthy or engage in intimacy, social events or gatherings)",
  },
  {
    id: 8,
    description:
      "(struggle with managing day to day financial responsibilities due to fertility costs)",
  },
];

exports.resourceDescriptions = [
  {
    id: 1,
    description: "(menstrual cycles, ovulation, BBT, cervical mucus)",
  },
  {
    id: 10,
    description: "(exercise, yoga, pilates, swimming, cardio)",
  },
  {
    id: 12,
    description: "(psychiatrist, social worker, counselor, psychologist)",
  },
  {
    id: 13,
    description: "(virtual or in person)",
  },
];

exports.currentTreatmentDescriptions = [
  {
    id: 2,
    description: "(bloodwork and ultrasounds)",
  },
  {
    id: 3,
    description: "(HSG, HSN)",
  },
];

exports.AGE_QUALIFICATIONS = [
  "25-29 years old",
  "30-34 years old",
  "35-39 years old",
];

exports.TTC_QUALIFICATIONS = ["6-12 months", "More than 12 months"];

module.exports.STATIC_OPACITY = 1;
module.exports.LOADING_OPACITY = 0.5;

module.exports.THEMES = {
  LIGHT: "LIGHT",
  ONE_DARK: "ONE_DARK",
  UNICORN: "UNICORN",
};

module.exports.MODALITIES = {
  1: "Acupuncture",
  2: "Birth Education",
  3: "Chiropractic",
  4: "Coaching",
  5: "Doula",
  6: "Exercise",
  7: "Fertility Coaching",
  8: "Infant Sleep",
  9: "Lactation",
  10: "Massage Therapy",
  11: "Mental Health",
  12: "Nutrition",
  13: "Occupational Therapy",
  14: "Physical Therapy",
};

module.exports.STATIC_OPACITY = 1;
module.exports.LOADING_OPACITY = 0.5;

module.exports.MOBILE_BREAKPOINT = 600;
module.exports.TABLET_BREAKPOINT = 960;
module.exports.DESKTOP_BREAKPOINT = 1200;
module.exports.LARGE_DESKTOP_BREAKPOINT = 1460;

// Socket Event Constants:
module.exports.SOCKET_EVENTS = {
  // Client Page
  UPDATE_CLIENT_PAGE: "update_client_page",
  UPDATE_CLIENT_JOURNEY: "update_client_journey",
  UPDATE_VIRTUAL_SESSIONS: "update_virtual_sessions",
  UPDATE_CLIENT_FORMS: "update_client_forms",
  UPDATE_TOPIC_SELECT: "update_topic_select",
  // Mass Text Page
  CREATE_MASS_TEXT: "create_mass_text",
  DELETE_MASS_TEXT: "delete_mass_text",
  UPDATE_MASS_TEXT: "update_mass_text",
  // Chat Page
  MESSAGE: "message",
  UPDATE_THREADS: "update_threads",
  // Settings Page
  UPDATE_SETTINGS: "update_settings",
  UPDATE_AUTOMATED_MESSAGE: "update_automated_message",
  // Global Events
  BROADCAST_NOTIFICATION: "broadcast_notification",
  // CLIENT_ID: accepts clientId as event name
  UPDATE_CLIENT_STATUS: "update_client_status",
};

// Socket Room Constants:
module.exports.SOCKET_ROOMS = {
  JOIN_CONVERSATION: "join_conversation",
  JOIN_CLIENT: "join_client",
  LEAVE_ALL_ROOMS: "leave_all_rooms",
};
