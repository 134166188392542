import { useRef } from "react";
import { useLocation } from "react-router-dom";
import Page from "src/components/common/Page";
import SignupForm from "src/components/Forms/SignUp";
import FolFooter from "src/components/Forms/common/FolFooter";
import ClientHeader from "src/components/common/ClientHeader";

const SignUp = () => {
  const pageRef = useRef(null);
  const { search } = useLocation();

  return (
    <Page
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
      title="Sign Up"
      ref={pageRef}
    >
      <ClientHeader />
      <SignupForm search={search} />
      <FolFooter veevaCode="10/24 US-RMMH-2300233 v6" />
    </Page>
  );
};

export default SignUp;
