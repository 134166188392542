import React from "react";
import { CircularProgress } from "@mui/material";

const Loader = ({ size, style = {}, color }) => {
  return (
    <CircularProgress
      size={size}
      sx={{ color: color || "primary.main" }}
      style={style}
    />
  );
};

export default Loader;
