import StyledTypography from "./StyledTypography";

const RobynFerring = () => {
  return (
    <>
      <StyledTypography>
        Fertility Outreach is brought to you by Fertility Out Loud in
        partnership with Robyn. By submitting your information to receive email
        communications and to sign up and use Fertility Outreach, you're
        agreeing to{" "}
        <a
          href="https://ferringusa.com/legal-disclaimer"
          target="_blank"
          rel="noreferrer"
        >
          Ferring's Terms and Conditions
        </a>{" "}
        and{" "}
        <a
          href="https://ferringusa.com/privacy/"
          target="_blank"
          rel="noreferrer"
        >
          Privacy Notice
        </a>
        , as well as{" "}
        <a
          href="https://wearerobyn.co/terms-of-use"
          target="_blank"
          rel="noreferrer"
        >
          Robyn's Terms of Use{" "}
        </a>
        and{" "}
        <a
          href="https://wearerobyn.co/privacy-policy"
          target="_blank"
          rel="noreferrer"
        >
          Privacy Notice
        </a>
        . Robyn offers resources and providers for the full path to parenthood.
      </StyledTypography>
      <StyledTypography>
        The information we provide is not intended or implied to be a substitute
        for professional medical advice, diagnosis or treatment. For medical
        emergencies, contact your primary care physician or dial 911
        immediately. By providing the information requested, I am giving Ferring
        and Robyn permission to store and use the information that I have
        provided.
      </StyledTypography>
      <StyledTypography>
        I understand and agree that my contact information will be used by
        Ferring (or companies working on Ferring's behalf) to contact me by
        telephone, email, or mail to provide me with information that may be of
        interest to me or to invite me to participate in research, educational,
        and marketing initiatives. I understand that my information will be
        treated as described in{" "}
        <a
          href="https://ferringusa.com/privacy/"
          target="_blank"
          rel="noreferrer"
        >
          Ferring's Privacy Notice
        </a>{" "}
        which provides details about my privacy rights. Also, I understand that
        I may opt out of the database at any time by making the request at{" "}
        <a
          href="https://ferring.ethicspoint.eu/custom/ferring/forms/data/form_data.asp"
          target="_blank"
          rel="noreferrer"
        >
          Data Subject Form
        </a>{" "}
        or by calling 1-888-FERRING, Monday through Friday, 8AM to 7PM ET to
        speak to a representative or leave a voicemail. This statement may be
        updated from time to time.
      </StyledTypography>
      <StyledTypography>
        I also understand and agree that my personal information will be treated
        as described in Robyn's Privacy Notice and used to deliver the Fertility
        Outreach service. Other than contact information, the personal
        information provided to use the Fertility Outreach service will not be
        shared with Ferring. I understand the details regarding my privacy
        rights and contact information for privacy questions about Robyn,
        Fertility Outreach and my privacy rights are in Robyn's Privacy Notice.
      </StyledTypography>
    </>
  );
};

export default RobynFerring;
