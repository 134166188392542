import { useHistory } from "react-router-dom";
import { IconButton, SvgIcon } from "@mui/material";
import { Person as PersonIcon } from "@mui/icons-material";

function Profile({ thread }) {
  const history = useHistory();

  const handleProfileClick = (e, thread) => {
    e.stopPropagation();
    const { id } = thread;
    history.push(`/clients/${id}`);
  };

  return (
    <IconButton
      sx={{
        p: 0,
      }}
      size="small"
      onClick={(e) => handleProfileClick(e, thread)}
    >
      <SvgIcon
        sx={{
          color: "text.disabled",

          "&:hover": {
            color: "primary.main",
          },
        }}
      >
        <PersonIcon />
      </SvgIcon>
    </IconButton>
  );
}

export default Profile;
