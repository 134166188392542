import { useRef } from "react";
import Page from "src/components/common/Page";
import {
  Divider,
  Card,
  CardHeader,
  Container,
  CardContent,
} from "@mui/material";
import AwayToggle from "src/components/Settings/AwayToggle";
import MessagesForm from "src/components/Settings/MessagesForm";

const Settings = () => {
  const pageRef = useRef(null);

  return (
    <Page title="Settings" ref={pageRef}>
      <Container
        maxWidth="sm"
        sx={{
          p: {
            xs: 0,
            sm: 3,
          },
        }}
      >
        <Card>
          <CardHeader
            title="Settings"
            titleTypographyProps={{ variant: "h4" }}
            action={<AwayToggle />}
          />
          <Divider />
          <CardContent>
            <MessagesForm />
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default Settings;
