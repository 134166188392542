import "./index.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import React from "react";
import ReactDOM from "react-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import App from "src/App";

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

ReactDOM.render(
  <Auth0Provider
    domain={domain}
    clientId={clientId}
    redirectUri={`${window.location.origin}`}
    audience={"https://fertility-outreach/api"}
  >
    {/* {process.env.REACT_APP_ENVIRONMENT !== "production" && (
      <div className="staging">👋 You're are using the Staging App</div>
    )} */}

    <App />
  </Auth0Provider>,
  document.getElementById("root")
);
