import React from "react";
import PropTypes from "prop-types";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  labelClass: {
    fontSize: ".7rem",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  borderRight: {
    borderRight: "1px solid #e0e0e0",
  },
}));

const FivePoints = ({ item }) => {
  const { emotional, financial, mindset, physical, social } = item;

  const fivePoints = [
    { label: "Mindset", value: mindset },
    { label: "Physical", value: physical },
    { label: "Emotional", value: emotional },
    { label: "Social", value: social },
    { label: "Financial", value: financial },
  ];

  const { labelClass } = useStyles();

  return (
    <Box>
      <Typography
        sx={{
          fontSize: 12,
        }}
        component="label"
        variant="h6"
      >
        Five Points of Wellbeing
      </Typography>
      <Grid container justifyContent="space-between">
        {fivePoints.map((point, index) => (
          <React.Fragment key={index}>
            <Grid item container xs={2}>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontSize: 14,
                  }}
                  align="center"
                >
                  {point?.value}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  align="center"
                  sx={{
                    fontSize: 14,
                  }}
                  className={labelClass}
                >
                  {point.label}
                </Typography>
              </Grid>
            </Grid>
            {Boolean(index + 1 < fivePoints.length) && (
              <Divider orientation="vertical" flexItem />
            )}
          </React.Fragment>
        ))}
      </Grid>
    </Box>
  );
};

FivePoints.propTypes = {
  item: PropTypes.object.isRequired,
};

export default FivePoints;
