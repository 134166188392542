const customerSatisfaction = {
  heading: {
    title: "Welcome",
    subtitle:
      "Tell us how it's going! That we can continue to improve Fertility Outreach.",
  },
  serviceSupportive: {
    heading:
      "Do you find the Fertility Outreach service to be supportive on your fertility journey?",
  },
  agreeLevel: {
    title: "What have you found helpful about the Fertility Outreach service?",
    agreeInformation: {
      label:
        "The information and resources shared have been helpful on my fertility journey",
    },
    agreeCommunication: {
      label:
        "Communicating with a fertility coach over text message has helped me better navigate my fertility journey",
    },
    agreeWellbeing: {
      label:
        "I feel like my overall wellbeing is improving by communicating with a fertility coach",
    },
    agreeMoveForward: {
      label:
        "Connecting with a fertility coach has helped me move forward on my fertility journey",
    },
  },
  feedback:
    "Please share any additional feedback you have about Fertility Outreach:",
  hasRobynDisclaimerVeevaCode: false,
  veevaCode: "06/24 US-RMMH-2300154 v2",
};

const virtualSession = {
  heading: {
    title: "Tell us how it went!",
    subtitle:
      "We would love to hear feedback about your recent Fertility Outreach virtual session. That way we can continue to improve the service.",
  },
  serviceSupportive: {
    heading:
      "Did you find the Fertility Outreach virtual session to be supportive on your fertility journey?",
  },
  agreeLevel: {
    title:
      "What did you find most helpful about the Fertility Outreach virtual session?",
    agreeInformation: {
      label:
        "The information and resources shared are helpful for my fertility journey",
    },
    agreeCommunication: {
      label:
        "Communicating with a fertility coach over video helps me better navigate my fertility journey",
    },
    agreeWellbeing: {
      label:
        "I feel like my overall wellbeing is improving by communicating with a fertility coach over video",
    },
    agreeMoveForward: {
      label:
        "Connecting with a fertility coach over video has helped me more forward on my fertility journey",
    },
  },
  feedback:
    "Please share any additional feedback you have about Fertility Outreach virtual sessions:",
  hasRobynDisclaimerVeevaCode: true,
  veevaCode: "US-RMMH-2300238",
};

/**
 * @description gets the content for the survey page
 * @param {string} surveyType "Customer Satisfaction", "Virtual Session"
 */
const getPageContent = async (surveyType) => {
  switch (surveyType) {
    case "Customer Satisfaction": {
      return customerSatisfaction;
    }
    case "Virtual Session": {
      return virtualSession;
    }
    default: {
      return null;
    }
  }
};

export default getPageContent;
