import { useContext } from "react";
import { UserContext } from "src/context/UserContext";
import { ClientsContext } from "src/context/ClientsContext";
import { toast } from "react-toastify";
import axios from "src/utils/axios";
import PropTypes from "prop-types";
import { Box, Button, Grid } from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import AutoResizeInput from "src/components/common/AutoResizeInput";

const validationSchema = Yup.object().shape({
  body: Yup.string()
    .required("Write an comment...")
    .min(5, "Should be at least 5 characters long"),
});

const initialValues = {
  body: "",
};

const CommentModalForm = ({ setIsOpen, client }) => {
  const { agent } = useContext(UserContext);
  const { handleGetClients } = useContext(ClientsContext);

  const handleCancel = (resetForm) => {
    setIsOpen(false);
    resetForm();
  };

  const handleSubmit = async (comment, resetForm) => {
    try {
      const payload = {
        agentId: agent.id,
        comment: comment.body,
      };
      // Set createAt of payload to have 0 hours
      const createdAt = new Date();
      createdAt.setHours(0, 0, 0, 0);
      payload.createdAt = createdAt;

      await axios.post(`/api/clients/${client.id}/journey`, {
        ...payload,
        agentId: agent.id,
      });

      toast.success(`Your comment has been created.`);
      setIsOpen(false);
      handleGetClients();
      resetForm();
    } catch (error) {
      toast.error(`Error creating comment.`);
    }
  };

  return (
    <Box pt={1} pb={1}>
      <Formik
        validationSchema={validationSchema}
        enableReinitialize
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={initialValues}
        onSubmit={(comment, { resetForm }) => handleSubmit(comment, resetForm)}
      >
        {({
          values,
          errors,
          handleChange,
          setErrors,
          handleSubmit,
          isValid,
          resetForm,
        }) => {
          return (
            <form
              onSubmit={(e) => {
                handleSubmit(e);
              }}
            >
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <AutoResizeInput
                    name="body"
                    errorMessage={errors.body}
                    value={values.body}
                    handleChange={(e) => {
                      handleChange(e);
                      setErrors({});
                    }}
                    placeholder="Write a comment..."
                  />
                </Grid>

                <Grid
                  container
                  item
                  justifyContent="flex-end"
                  xs={12}
                  spacing={1}
                >
                  <Grid item>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => handleCancel(resetForm)}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={!values?.body.length || !isValid}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          );
        }}
      </Formik>
    </Box>
  );
};

CommentModalForm.propTypes = {
  client: PropTypes.object,
  handleCreate: PropTypes.func,
  setIsOpen: PropTypes.func,
  onCancel: PropTypes.func,
};

export default CommentModalForm;
