import PropTypes from "prop-types";
import { useContext } from "react";
import { OptionContext } from "src/context/OptionContext";
import { Formik } from "formik";
import { Stack, Typography, Button } from "@mui/material";
import DatePicker from "src/components/common/DatePicker";
import TextInput from "src/components/Forms/common/TextInput";
import HiddenLogicComponent from "src/components/Forms/common/HiddenLogicComponent";
import MultiSelectDropdown from "src/components/common/MultiSelect";
import SingleSelect from "src/components/common/SingleSelect";
import validationSchema from "./validation-schema";

const ClientJourneyForm = ({
  onSubmit,
  onCancel,
  initialValues: previousValues,
  buttonText,
  isEditing,
}) => {
  const initialValues = {
    createdAt: new Date(),
    comment: {
      id: null,
      content: "",
    },
    journeyEvent: {
      id: null,
      label: "",
      otherContent: "",
    },
    atReStatus: null,
  };

  const { journeyEventOptions, notAtReReasonOptions } =
    useContext(OptionContext);

  const handleSubmit = ({ comment, ...payload }, resetForm) => {
    try {
      return onSubmit(
        {
          ...payload,
          comment,
        },
        resetForm
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Formik
      validationSchema={validationSchema}
      enableReinitialize
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={previousValues || initialValues}
      resetForm={{ values: initialValues }}
      onSubmit={(payload, { resetForm }) => handleSubmit(payload, resetForm)}
    >
      {({
        values,
        errors,
        setFieldError,
        handleSubmit,
        setFieldValue,
        isValid,
      }) => {
        return (
          <form
            onSubmit={(e) => {
              handleSubmit(e);
            }}
          >
            <Stack spacing={2}>
              <DatePicker
                variant="outlined"
                value={values?.createdAt}
                fullWidth
                handleChange={(value) => {
                  setFieldValue("createdAt", value);
                  setFieldError("createdAt", undefined);
                }}
                hasError={Boolean(errors?.createdAt)}
                placeholder="Event Date"
              />

              <TextInput
                name="commentContent"
                variant="outlined"
                multiline
                minRows={2}
                maxRows={4}
                value={values?.comment?.content || ""}
                onChange={(e) => {
                  setFieldValue("comment", {
                    ...values.comment,
                    content: e.target.value,
                  });
                  setFieldError("comment", undefined);
                  setFieldError("journeyEvent", undefined);
                }}
                placeholder="Write a comment..."
                error={Boolean(errors?.comment?.content)}
              />

              {/* 
                We're preventing the ability to edit client re status here. 
                We'll allow those to be edited in a explicit RE Status Item component
              */}
              {!isEditing && (
                <>
                  <SingleSelect
                    id="JourneyEvent"
                    label="Add Journey Event"
                    value={values.journeyEvent?.id || ""}
                    options={journeyEventOptions}
                    name="journey-update-event"
                    onChange={(valueId) => {
                      const value = journeyEventOptions.find(
                        (option) => option.id === valueId
                      );

                      setFieldValue("journeyEvent", value);
                      setFieldError("comment", undefined);
                      setFieldError("journeyEvent", undefined);

                      if (value?.atReStatusId) {
                        setFieldValue("atReStatus", {
                          ...values.atReStatus,
                          id: value.atReStatusId,
                        });
                      }
                    }}
                    hasError={Boolean(errors?.journeyEvent)}
                  />

                  <HiddenLogicComponent
                    value={values.journeyEvent?.label === "Other"}
                    names={["otherJourneyEvent"]}
                    clearFields={() => {
                      setFieldValue("journeyEvent.otherContent");
                      setFieldError("journeyEvent.otherContent", undefined);
                    }}
                  >
                    <TextInput
                      multiline={true}
                      placeholder={"Please specify other Event"}
                      name="journeyEvent.otherContent"
                      variant="outlined"
                      onChange={(e) => {
                        const content = e.target.value;
                        setFieldValue("journeyEvent", {
                          ...values.journeyEvent,
                          otherContent: content,
                        });
                        setFieldError("journeyEvent.otherContent", undefined);
                      }}
                      value={values.journeyEvent?.otherContent}
                    />
                  </HiddenLogicComponent>

                  <HiddenLogicComponent
                    value={values.journeyEvent?.atReStatusId === 2}
                    names={["atReStatus.notAtReReasons"]}
                    clearFields={() => {
                      setFieldValue("notAtReReasons", []);
                      setFieldError("notAtReReasons", undefined);
                    }}
                  >
                    <MultiSelectDropdown
                      id="notAtReReason"
                      variant="outlined"
                      values={values.atReStatus?.notAtReReasons}
                      options={notAtReReasonOptions}
                      setValues={(notAtReReasons) => {
                        setFieldValue("atReStatus", {
                          ...values.atReStatus,
                          notAtReReasons,
                        });
                        setFieldError("atReStatus.notAtReReasons", undefined);
                      }}
                      label={"Not At RE Reasons"}
                      name={"Not at RE Reason"}
                      hasError={Boolean(errors?.notAtReReasons)}
                    />
                    <HiddenLogicComponent
                      value={values.atReStatus?.notAtReReasons?.some((arr) =>
                        ["Other"].some((value) => arr.label === value)
                      )}
                      names={["otherNotAtReReason"]}
                      clearFields={() => {
                        setFieldValue("atReStatus.otherNotAtReReason", "");
                        setFieldError(
                          "atReStatus.otherNotAtReReasonContent",
                          undefined
                        );
                      }}
                    >
                      <Stack>
                        <Typography variant="overline">
                          Other No RE Reason
                        </Typography>
                        <TextInput
                          multiline={true}
                          placeholder={
                            "Please specify other reason for not seeing RE"
                          }
                          name="otherNotAtReReasonContent"
                          variant="outlined"
                          onChange={(e) => {
                            const content = e.target.value;
                            setFieldValue("atReStatus", {
                              ...values.atReStatus,
                              otherNotAtReReasonContent: content,
                            });
                            setFieldError(
                              "atReStatus.otherNotAtReReasonContent",
                              undefined
                            );
                          }}
                          value={values.atReStatus?.otherNotAtReReasonContent}
                          error={Boolean(
                            errors?.atReStatus?.otherNotAtReReasonContent
                          )}
                        />
                      </Stack>
                    </HiddenLogicComponent>
                  </HiddenLogicComponent>
                </>
              )}
              <Stack direction="row" spacing={1} justifyContent="flex-end">
                {onCancel != null && (
                  <Button variant="outlined" onClick={onCancel}>
                    Cancel
                  </Button>
                )}
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={!isValid}
                >
                  {buttonText}
                </Button>
              </Stack>
            </Stack>
          </form>
        );
      }}
    </Formik>
  );
};

ClientJourneyForm.propTypes = {
  initialValues: PropTypes.object,
  buttonText: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  clientId: PropTypes.string.isRequired,
};

export default ClientJourneyForm;
