import { Divider, Stack, Typography, useMediaQuery } from "@mui/material";

const FolFooter = ({ veevaCode }) => {
  const isMobile = useMediaQuery("(max-width:600px)");

  const currentYear = new Date().getFullYear();

  return (
    <Stack
      sx={{
        justifyContent: "center",
        alignItems: "center",
        padding: {
          xs: 3,
          md: 8,
        },
        background: "#000000",
        fontSize: 14,
        color: "#ffffff",
        textAlign: "center",
      }}
    >
      <Stack
        direction={isMobile ? "column" : "row"}
        useFlexGap
        spacing={isMobile ? 1 : 3}
        divider={!isMobile && <Divider orientation="vertical" />}
        sx={{
          mb: 1,
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          "& a": {
            fontFamily: "Brandon",
            fontWeight: "Bold",
            fontSize: 16,
            textTransform: "uppercase",
            color: "#ffffff",
            textDecoration: "underline",
            textUnderlineOffset: (theme) => theme.spacing(0.5),
            "&:hover": {
              textDecoration: "none",
            },
          },
        }}
      >
        <a
          href="https://ferringusa.com/privacy/"
          target="_blank"
          rel="noreferrer"
        >
          Privacy Notice
        </a>
        <a
          href="https://ferringusa.com/legal-disclaimer/"
          target="_blank"
          rel="noreferrer"
        >
          Terms of Use
        </a>
        {/* TODO: Would this be a Ferring or Robyn disclaimer: */}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.fertilityoutloud.com/sitemap/"
        >
          Site Map
        </a>
      </Stack>
      <Typography
        variant={"inherit"}
        sx={{
          fontFamily: "Brandon",
          maxWidth: (theme) => theme.breakpoints.values.sm,
        }}
      >
        This site is intended for U.S. residents only. Sponsored by Ferring
        Pharmaceuticals Inc.
        <br />
        {`© ${currentYear} Ferring. Ferring and the Ferring Pharmaceuticals logo and, unless otherwise specified, all product names and service names are trademarks owned by, or licensed to, the Ferring group of companies.`}
        <br />
        All other trademarks are the property of their respective owner(s).
        <br />
        {veevaCode}
      </Typography>
    </Stack>
  );
};

export default FolFooter;
