import React, { useRef, useState, useContext } from "react";
import axios from "src/utils/axios";
import {
  Container,
  Button,
  CircularProgress,
  SvgIcon,
  Stack,
  Grid,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { Download as DownloadIcon, FileText as FileIcon } from "react-feather";
import Page from "src/components/common/Page";
import ClientTable from "src/components/Clients/Table";
import { UserContext } from "src/context/UserContext";
import { ClientsContextProvider } from "src/context/ClientsContext";
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";
import { DatePicker } from "@mui/x-date-pickers";
import { CalendarToday as CalendarTodayIcon } from "@mui/icons-material";
import ToolTip from "src/components/common/ToolTip";

const getCsvExport = async (apiSlug) => {
  try {
    const { data } = await axios.get(`/api/clients${apiSlug}`);
    return data;
  } catch (error) {
    throw error;
  }
};

const Clients = () => {
  const pageRef = useRef(null);
  const { isAgentAdmin } = useContext(UserContext);
  const [typeLoading, setTypeLoading] = useState("");
  const [csvExport, setCsvExport] = useState([]);
  const clientCsvLink = useRef();
  const surveyCsvLink = useRef();
  const eventsCsvLink = useRef();
  const [targetMonth, setTargetMonth] = useState(new Date());

  const handleCsvExport = async ({ type, apiSlug, linkRef }) => {
    try {
      setTypeLoading(type);

      const csvExport = await getCsvExport(apiSlug);
      setCsvExport(csvExport);
      linkRef.current.link.click();
    } catch (error) {
      console.log(error.message);
      toast.error(`Downloading ${type}`);
    } finally {
      setTypeLoading("");
    }
  };

  const handleUpdateReport = async (targetMonth) => {
    const monthIndex = targetMonth.getMonth() + 1;
    const year = targetMonth.getFullYear();

    try {
      setTypeLoading("report");
      await axios.post(`/api/data`, {
        month: `${monthIndex}/${year}`,
      });
    } catch (error) {
      console.log(error.message);
      toast.error(`Updating report`);
    } finally {
      setTypeLoading("");
    }
  };

  const buttons = [
    {
      text: "Clients CSV",
      type: "clients",
      linkRef: clientCsvLink,
      apiSlug: "/export",
    },
    {
      text: "Surveys CSV",
      type: "surveys",
      linkRef: surveyCsvLink,
      apiSlug: "/export/surveys",
    },
    {
      text: "Events CSV",
      type: "events",
      linkRef: eventsCsvLink,
      apiSlug: "/export/events",
    },
    {
      text: "Journey Updates CSV",
      type: "journey-updates",
      linkRef: eventsCsvLink,
      apiSlug: "/export/journeys",
    },
  ];

  return (
    <ClientsContextProvider>
      <Page
        sx={{
          backgroundColor: "background.dark",
          minHeight: "100%",
        }}
        title="Clients"
        ref={pageRef}
      >
        <Container
          sx={{
            p: {
              xs: 0,
              md: 2,
            },
          }}
          maxWidth="xl"
        >
          <Stack
            useFlexGap
            spacing={2}
            sx={{
              my: {
                xs: 2,
                md: 0,
              },
            }}
          >
            {isAgentAdmin && (
              <Grid container spacing={1} justifyContent="space-between">
                <Grid item container xs={12} md="auto" spacing={1}>
                  {buttons.map(({ type, text, linkRef, apiSlug }) => (
                    <Grid item xs={6} md={"auto"} key={type}>
                      <Button
                        fullWidth
                        startIcon={
                          <>
                            {typeLoading === type ? (
                              <CircularProgress
                                sx={{ color: "white" }}
                                size={20}
                              />
                            ) : (
                              <SvgIcon fontSize="small">
                                <DownloadIcon />
                              </SvgIcon>
                            )}
                          </>
                        }
                        onClick={() =>
                          handleCsvExport({ type, apiSlug, linkRef })
                        }
                        color="primary"
                        variant="contained"
                        sx={{
                          height: "100%",
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          component="span"
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            width: "100%",
                          }}
                        >
                          {text}
                        </Typography>
                      </Button>
                      <CSVLink
                        data={csvExport}
                        filename={`${type}.csv`}
                        ref={linkRef}
                        target="_blank"
                      />
                    </Grid>
                  ))}
                </Grid>

                {/* Update Report Month Select and Button */}
                <Grid item container xs={12} md="auto" spacing={1}>
                  <Grid item xs={6} md="auto">
                    <DatePicker
                      views={["month", "year"]}
                      value={targetMonth}
                      onChange={setTargetMonth}
                      minDate={new Date("2022-10-01")}
                      maxDate={new Date()}
                      placeholder="Select Month"
                      slotProps={{
                        textField: {
                          placeholder: "Select Month",
                          sx: {
                            "& input": {
                              padding: (theme) => theme.spacing(1),
                            },
                            maxWidth: {
                              xs: "100%",
                              md: "160px",
                            },
                            width: {
                              xs: "100%",
                            },
                            backgroundColor: (theme) =>
                              theme.palette.background.paper,
                            "& fieldset": {
                              border: {
                                xs: (theme) =>
                                  `1px solid ${theme.palette.divider}`,
                                md: "none",
                              },
                            },
                          },
                        },
                        openPickerButton: {
                          sx: {
                            color: "primary.main",
                            "&:hover": {
                              cursor: "pointer",
                            },
                          },
                        },
                      }}
                      slots={{
                        openPickerButton: (props) => (
                          <ToolTip
                            title="Open Month Picker"
                            sx={{ cursor: "pointer" }}
                          >
                            <CalendarTodayIcon {...props} />
                          </ToolTip>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} md="auto">
                    <Button
                      sx={{
                        height: "100%",
                        "& .MuiButton-label": {
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        },
                      }}
                      fullWidth
                      aria-label="Update Report"
                      startIcon={
                        <>
                          {typeLoading === "report" ? (
                            <CircularProgress
                              sx={{ color: "white" }}
                              size={20}
                            />
                          ) : (
                            <SvgIcon fontSize="small">
                              <DownloadIcon />
                            </SvgIcon>
                          )}
                        </>
                      }
                      disabled={!targetMonth}
                      onClick={() => handleUpdateReport(targetMonth)}
                      color="primary"
                      variant="contained"
                    >
                      <Typography
                        variant="subtitle2"
                        component="span"
                        sx={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: "100%",
                        }}
                      >
                        Create Report
                      </Typography>
                    </Button>
                  </Grid>
                  <Grid item xs={6} md="auto">
                    <Button
                      sx={{
                        height: "100%",
                        backgroundColor: "#0D7813",
                        "&:hover": {
                          backgroundColor: "#0D7813",
                        },
                      }}
                      component={Link}
                      target="_blank"
                      to={{
                        pathname:
                          "https://docs.google.com/spreadsheets/d/1DYjTCk5psJ5LvJlNZToYIH4rV5nyT_lO59wCRaNBOGA/edit?gid=1725231610#gid=1725231610",
                      }}
                      variant="contained"
                      startIcon={
                        <SvgIcon fontSize="small">
                          <FileIcon />
                        </SvgIcon>
                      }
                    >
                      Reports
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            )}
            <ClientTable title="Clients" pageRef={pageRef} />
          </Stack>
        </Container>
      </Page>
    </ClientsContextProvider>
  );
};

export default Clients;
