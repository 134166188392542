import { Stack, Typography } from "@mui/material";
import ClientButton from "src/components/common/ClientButton";

const FormSignUp = () => {
  return (
    <Stack
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
        m: 2,
      }}
    >
      <Typography style={{ marginBottom: 20 }} variant="h3">
        Looks like you do not have an account yet.
      </Typography>

      <ClientButton variant="contained" label="Sign up!" href="/sign-up" />
    </Stack>
  );
};

export default FormSignUp;
