import * as Yup from "yup";

const validationSchema = Yup.object().shape(
  {
    createdAt: Yup.date().required("Required"),
    comment: Yup.object().when(["journeyEvent"], {
      is: (journeyEvent) => {
        return !Boolean(journeyEvent?.id);
      },
      then: Yup.object().shape({
        content: Yup.string().required("Required"),
      }),
      otherwise: Yup.object().nullable().optional(),
    }),
    journeyEvent: Yup.object().when(["comment"], {
      is: (comment) => {
        return !Boolean(comment?.content);
      },
      then: Yup.object().shape({
        id: Yup.number().required("Required"),
        label: Yup.string().required("Required"),
        otherContent: Yup.string().when("label", {
          is: (label) => label === "Other",
          then: Yup.string().required("Required"),
          otherwise: Yup.string().nullable().optional(),
        }),
      }),
      otherwise: Yup.object().nullable().optional(),
    }),
    atReStatus: Yup.object().when(["journeyEvent"], {
      is: (journeyEvent) => {
        return journeyEvent?.atReStatusId;
      },
      then: Yup.object().shape({
        id: Yup.number().required("Required").typeError("Required"),
        notAtReReasons: Yup.array().when(["label"], {
          is: (atReStatusLabel) => {
            return atReStatusLabel === "No";
          },
          then: Yup.array().min(1).required("Required"),
          otherwise: Yup.array().nullable().optional(),
        }),
        otherNotAtReReasonContent: Yup.string().when(["notAtReReasons"], {
          is: (notAtReReasons) => {
            return notAtReReasons?.some((reason) => reason.label === "Other");
          },
          then: Yup.string().required("Required"),
          otherwise: Yup.string().nullable().optional(),
        }),
      }),
      otherwise: Yup.object().nullable().optional(),
    }),
  },
  ["comment", "journeyEvent"]
);

export default validationSchema;
