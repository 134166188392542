export const formTypography = {
  brandonH1: {
    fontFamily: "Brandon",
    fontSize: 40,
    lineHeight: "44px",
    fontWeight: 300,
  },
  brandonTitle: {
    fontFamily: "Brandon",
    fontSize: 18,
    lineHeight: "26px",
    fontWeight: 600,
    marginBottom: "8px",
  },
  brandonBody1: {
    fontFamily: "Brandon",
    fontSize: 18,
    lineHeight: "26px",
    fontWeight: 400,
    "& b": {
      fontWeight: 600,
    },
  },
  brandonBody2: {
    fontFamily: "Brandon",
    fontSize: 14,
    lineHeight: "20px",
    fontWeight: 400,
  },
};
