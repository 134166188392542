import React, { useState, useContext, useMemo } from "react";
import { ChatContext } from "src/context/ChatContext";
import PropTypes from "prop-types";
import { List } from "@mui/material";
import ThreadItem from "../ThreadItem";

const ThreadList = ({ selectedPhoneNumber, handleSelectThread }) => {
  const { recipient, threads } = useContext(ChatContext);
  const [selectedClientId, setSelectedClientId] = useState(null);

  const sortedThreads = useMemo(() => {
    const threadsWithoutRecipient = threads.threadItems.filter(
      (item) => item.phoneNumber !== selectedPhoneNumber
    );

    return threadsWithoutRecipient;
  }, [threads.threadItems, selectedPhoneNumber]);

  return (
    <List sx={{ pt: 0 }}>
      {/* If there's a recipient put on top */}
      {recipient && (
        <ThreadItem
          key={recipient?.id}
          onSelect={() => handleSelectThread(recipient)}
          thread={recipient}
          active={selectedPhoneNumber === recipient?.phoneNumber ? true : false}
          selectedClientId={selectedClientId}
          setSelectedClientId={setSelectedClientId}
        />
      )}
      {sortedThreads.map((thread) => {
        // Filter out receipient
        if (thread.id === recipient?.id) return null;
        return (
          <ThreadItem
            key={thread?.id}
            onSelect={() => handleSelectThread(thread)}
            thread={thread}
            active={selectedPhoneNumber === thread?.phoneNumber ? true : false}
            selectedClientId={selectedClientId}
            setSelectedClientId={setSelectedClientId}
          />
        );
      })}
    </List>
  );
};

ThreadList.propTypes = {
  selectedPhoneNumber: PropTypes.string,
  handleSelectThread: PropTypes.func,
};

export default ThreadList;
