import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  wellbeingPoint: Yup.object().shape({
    mindset: Yup.number().required("Required").typeError("Required"),
    physical: Yup.number().required("Required").typeError("Required"),
    emotional: Yup.number().required("Required").typeError("Required"),
    social: Yup.number().required("Required").typeError("Required"),
    financial: Yup.number().required("Required").typeError("Required"),
  }),

  // Event Journey:
  journeyEvent: Yup.object().when("atReStatus", {
    is: (atReStatus) => {
      return atReStatus.label === "Yes";
    },
    then: Yup.object().shape({
      id: Yup.number().required("Required").typeError("Required"),
      label: Yup.string().required("Required"),
      otherContent: Yup.string().when(["label"], {
        is: (journeyEventLabel) => {
          const journeyEventCheck = journeyEventLabel === "Other";
          return journeyEventCheck;
        },
        then: Yup.string().required("Required"), // Directly validate otherContent
        otherwise: Yup.string().optional(),
      }),
    }),
  }),

  // RE Status
  atReStatus: Yup.object().shape({
    id: Yup.number().required("Required").typeError("Required"),
    label: Yup.string().required("Required"),
    notAtReReasons: Yup.array().when(["label"], {
      is: (atReStatusLabel) => {
        return atReStatusLabel === "No";
      },
      then: Yup.array().min(1).required("Required"),
      otherwise: Yup.array().optional(),
    }),
    otherNotAtReReasonContent: Yup.string().when(["notAtReReasons"], {
      is: (notAtReReasons) => {
        return notAtReReasons?.some((reason) => reason.label === "Other");
      },
      then: Yup.string().required("Required"),
      otherwise: Yup.string().optional(),
    }),
  }),
});

export default validationSchema;
