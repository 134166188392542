import io from "socket.io-client";

/**
 * @name createSocketInstance
 * @description Creates a new socket instance with the provided authentication token.
 * @param {string} authToken - The authentication token to be used for the socket connection.
 * @returns {SocketIOClient.Socket} - The socket instance.
 */
const createSocketInstance = (authToken) => {
  const socket = io(process.env.REACT_APP_API_URL, {
    auth: {
      token: authToken || null,
    },
    transports: ["websocket"],
    reconnection: true,
    reconnectionAttempts: 3,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
  });

  return socket;
};

export default createSocketInstance;
