import { createContext, useEffect, useReducer } from "react";
import axios from "src/utils/axios";
import { useAuth0 } from "@auth0/auth0-react";
import { createSocketInstance } from "src/utils/socket";

export const UserContext = createContext();

const userReducer = (state, action) => {
  switch (action.type) {
    case "SET_AGENT":
      return {
        ...state,
        agent: action.payload,
        isAgentAdmin: action?.payload?.auth0Role === "admin",
      };
    case "SET_AUTH_TOKEN":
      return {
        ...state,
        authToken: action.payload,
      };
    case "SET_SOCKET":
      return {
        ...state,
        socket: action.payload,
      };
    default:
      return state;
  }
};

const UserContextProvider = (props) => {
  const { getAccessTokenSilently, getIdTokenClaims, user } = useAuth0();
  const initialState = {
    agent: null,
    isAgentAdmin: false,
    authToken: null,
    socket: null,
  };

  const [userState, dispatch] = useReducer(userReducer, initialState);

  const setAgent = async (authToken) => {
    try {
      const idTokenClaims = await getIdTokenClaims();

      if (!idTokenClaims.role) {
        throw new Error("no role assigned");
      }
      const { data: agent } = await axios.get("/api/agents", {
        params: {
          auth0Id: user?.sub,
        },
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      if (agent) {
        dispatch({
          type: "SET_AGENT",
          payload: {
            auth0Role: idTokenClaims.role,
            ...agent,
          },
        });
      } else {
        dispatch({ type: "SET_AGENT", payload: false });
      }
    } catch (error) {
      console.log("error ==> ", error);
      // Rerouting user incase they were not added to the database
      // or have no auth0 roles and somehow got through loginFlow
      window.location = `/?error=${encodeURIComponent(
        "User not setup correctly"
      )}&error_description=${encodeURIComponent(
        "User is not setup in the database or does not have role assigned."
      )}`;
    }
  };

  const setAuthToken = async () => {
    const authToken = await getAccessTokenSilently();
    // Set Bearer token globally across all axios request
    axios.defaults.headers["Authorization"] = `Bearer ${authToken}`;
    dispatch({ type: "SET_AUTH_TOKEN", payload: authToken });
    return authToken;
  };

  // once the user is authenticated, create a socket instance
  useEffect(() => {
    if (!userState.authToken) return;

    const socket = createSocketInstance(userState.authToken);

    dispatch({ type: "SET_SOCKET", payload: socket });

    return () => {
      socket.disconnect();

      dispatch({ type: "SET_SOCKET", payload: null });
    };
  }, [userState.authToken]);

  const value = {
    ...userState,
    setAgent,
    setAuthToken,
  };

  return (
    <UserContext.Provider value={value}>{props.children}</UserContext.Provider>
  );
};

const UserContextConsumer = UserContext.Consumer;

export { UserContextProvider, UserContextConsumer };
