import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Typography,
  Avatar,
  Button,
  Grid
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Page from "../../components/common/Page";
import Loader from "src/components/common/Loader";
import queryString from 'query-string'
import { useHistory } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },

  cardContainer: {
    paddingBottom: 80,
    paddingTop: 80,
  },
  cardContent: {
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
  },
  avatar: {
    position: 'absolute',
    top: -25,
    left: '50%',
    transform: 'translateX(-50%)',
    width: 70,
  },
}));
const Login = () => {
  const classes = useStyles();
  const { loginWithRedirect, logout, isLoading, isAuthenticated } = useAuth0();
  const history = useHistory();
  const { error, error_description: errorDescription } =
    queryString.parse(window.location.search) || {};
  useEffect(() => {
    if (!isLoading && !error && isAuthenticated) {
      history.push('/chat');
    }
  }, [isLoading]);
  if (isLoading) {
    return (
      <Page className={classes.root} title="Login">
        <Container className={classes.cardContainer} maxWidth="sm">
          <Grid justifyContent="center" container>
            <Loader />
          </Grid>
        </Container>
      </Page>
    );
  }
  const ErrorContent = () => (
    <CardContent className={classes.cardContent}>
      <Box>
        <Typography
          color="textPrimary"
          align="center"
          gutterBottom
          variant="h2"
        >
          There was a problem signing you in.
        </Typography>
      </Box>
      <Box mb={3}>
        <Divider />
      </Box>
      <Box>
        <Typography
          color="textPrimary"
          align="center"
          gutterBottom
          variant="h3"
        >
          {errorDescription ? errorDescription : error}
        </Typography>
      </Box>
      <Button
        onClick={() =>
          logout({
            logoutParams: { returnTo: window.location.origin },
          })
        }
        variant="contained"
        color="primary"
        align="center"
      >
        Try Again
      </Button>
    </CardContent>
  );

  const LoginContent = () => (
    <CardContent className={classes.cardContent}>
      <Box>
        <Typography
          color="textPrimary"
          align="center"
          gutterBottom
          variant="h2"
        >
          Sign into Fertility Outreach
        </Typography>
      </Box>

      <Box mb={3}>
        <Divider />
      </Box>

      <Button
        onClick={() => loginWithRedirect()}
        variant="contained"
        color="primary"
      >
        Log in
      </Button>
    </CardContent>
  );

  return (
    <Page className={classes.root} title="Login">
      <Container className={classes.cardContainer} maxWidth="sm">
        <Box mb={8} position="relative">
          <Avatar
            className={classes.avatar}
            variant="rounded"
            src="/static/images/logo_mark.png"
          />
          <Card>{error ? <ErrorContent /> : <LoginContent />}</Card>
        </Box>
      </Container>
    </Page>
  );
};

export default Login;
