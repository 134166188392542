import PropTypes from "prop-types";
import FormSuccess from "./FormSuccess";
import LoadingContent from "src/components/common/LoadingContent";
import FormError from "./FormError";
import FormSignUp from "./FormSignUp";

const FormWrapper = ({ children, formStatus, formName }) => {
  if (formStatus === "IS_LOADING") {
    return <LoadingContent />;
  }

  if (formStatus === "SUBMIT_ERROR") {
    return (
      <FormError
        errorMessage={`Unfortunately we're unable to submit your ${formName} form at this time. Please try again later.`}
      />
    );
  }

  if (formStatus === "PHONE_NUMBER_IN_USE_ERROR") {
    return <FormError errorMessage={`Phone number is already in use.`} />;
  }

  if (formStatus === "FORM_ALREADY_SUBMITTED") {
    return (
      <FormError
        errorMessage={`Oops, looks like you've already submitted this form.`}
      />
    );
  }

  if (formStatus === "LOAD_ERROR") {
    return (
      <FormError
        errorMessage={`An error occurred while loading the ${formName} form. Please try again.`}
      />
    );
  }

  if (formStatus === "SUCCESS") {
    return <FormSuccess formName={formName} />;
  }

  if (formStatus === "SHOW_SIGN_UP") {
    return <FormSignUp />;
  }

  if (formStatus === "SHOW_FORM") {
    return children;
  }

  return null;
};

export default FormWrapper;

FormWrapper.propTypes = {
  formStatus: PropTypes.oneOf([
    "IS_LOADING",
    "SUBMIT_ERROR",
    "LOAD_ERROR",
    "SUCCESS",
    "SHOW_FORM",
    "SHOW_SIGN_UP",
    "FORM_ALREADY_SUBMITTED",
  ]),
  formName: PropTypes.string.isRequired,
};
