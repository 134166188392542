import Chip from "@mui/material/Chip";
import { useTheme } from "@mui/material/styles";
import ToolTip from "../ToolTip";

const getTagColor = ({ label, palette }) => {
  switch (label) {
    case "TTC":
      return {
        backgroundColor: palette.lightBlue,
      };
    case "Pregnant":
      return {
        backgroundColor: palette.brightYellow,
      };
    case "At RE":
      return {
        backgroundColor: palette.brightOrange,
        color: "white",
      };
    case "At OBGYN":
      return {
        backgroundColor: palette.brightPurple,
        color: "white",
      };
    case "Egg Freezing":
      return {
        backgroundColor: palette.lightPink,
      };
    case "Egg Freezing Ready":
      return {
        backgroundColor: palette.neonPink,
      };
    case "RE Ready":
      return {
        backgroundColor: palette.neonBlue,
        color: "white",
      };
    case "IVF Ready":
      return {
        backgroundColor: palette.neonGreen,
      };
    case "Spanish":
      return {
        backgroundColor: palette.brightRed,
        color: "white",
      };

    default:
      return {
        backgroundColor: palette.secondary.light,
      };
  }
};

const Tag = ({ label, ...props }) => {
  const theme = useTheme();
  const palette = theme.palette;

  return (
    <ToolTip title={label || ""} placement="top">
      <Chip
        label={label}
        sx={{
          ...getTagColor({ label, palette }),
          ...props.sx,
        }}
        {...props}
      />
    </ToolTip>
  );
};

export default Tag;
