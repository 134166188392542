import { List, ListItem } from "@mui/material";

const listItems = [
  {
    id: 1,
    title: "I'm struggling with no answers",
  },
  {
    id: 2,
    title: "I'm struggling looking for answers",
  },
  {
    id: 3,
    title: "I'm aware I need to make changes - I have no plan",
  },
  {
    id: 4,
    title: "I'm making changes and need to be guided",
  },
  {
    id: 5,
    title:
      "I'm presently living my best self - I want to work with a coach for continued success",
  },
];

const FivePointList = () => {
  return (
    <List component="ol" sx={{ p: 0 }}>
      {listItems.map((item) => (
        <ListItem
          key={item.id}
          sx={{
            display: "flex",
            alignItems: "flex-start",
            gap: 1,
            p: "0 0 10px 0",
          }}
        >
          <strong>{`${item.id}:`}</strong>
          {item.title}
        </ListItem>
      ))}
    </List>
  );
};

export default FivePointList;
