import React, { useMemo, useContext } from "react";
import { ChatContext } from "src/context/ChatContext";
import PropTypes from "prop-types";
import { List } from "@mui/material";
import SearchItem from "../SearchItem";

const SearchList = ({ handleSelectMessage }) => {
  const { selectedMessage, messageSearchResults } = useContext(ChatContext);
  const sortedResults = useMemo(
    () =>
      messageSearchResults
        ? [...messageSearchResults].sort(
            (a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt)
          )
        : [],
    [messageSearchResults]
  );

  return (
    <List sx={{ pt: 0 }}>
      {sortedResults?.map((result) => (
        <SearchItem
          key={`searchItem${result?.id}`}
          result={result}
          onSelect={() => handleSelectMessage(result)}
          isActive={result?.id === selectedMessage?.id}
        />
      ))}
    </List>
  );
};

SearchList.propTypes = {
  handleSelectedMessage: PropTypes.func,
};

export default SearchList;
