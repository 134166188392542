import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";

const FormError = ({ errorMessage }) => {
  return (
    <Box
      position="absolute"
      left="50%"
      top="50%"
      style={{
        transform: "translate(-50%, -50%)",
      }}
    >
      <Typography variant="h4" color="error" align="center">
        {errorMessage}
      </Typography>
    </Box>
  );
};

export default FormError;

FormError.propTypes = {
  errorMessage: PropTypes.string.isRequired,
};
