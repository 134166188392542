import PropTypes from "prop-types";

import { Typography, Stack, Chip } from "@mui/material";
import ToolTip from "src/components/common/ToolTip";

const AtReStatus = ({ status, notAtReReasons }) => {
  const { otherNotAtReReasonContent } =
    notAtReReasons.find((reason) => reason.otherNotAtReReasonContent) || {};

  return (
    <Stack spacing={0.5} direction="column" alignItems="flex-start">
      <Chip
        sx={{
          py: 1.75,
          backgroundColor: (theme) => theme.palette.brightOrange,
          minWidth: 160,
          borderRadius: 2,
          color: "white",
        }}
        label={status}
      />

      {notAtReReasons?.length > 0 && (
        <>
          <Typography
            sx={{
              fontSize: 12,
            }}
            component="label"
            variant="h6"
          >
            Not At RE Reasons
          </Typography>
          <Stack
            direction="row"
            sx={{
              flexWrap: "wrap",
              overflow: "hidden",
              justifyContent: "flex-start",
            }}
          >
            {notAtReReasons?.map((reason) => (
              <Stack
                sx={{ alignItems: "flex-start", justifyContent: "flex-start" }}
                key={reason.id}
              >
                <ToolTip
                  title={reason?.otherNotAtReReasonContent || ""}
                  placement="top"
                >
                  <Chip
                    // Having to apply these margins because the wrap on the parent is messing with the spacing
                    sx={{
                      mb: 1,
                      mr: 1,
                    }}
                    key={reason.id}
                    label={reason.label}
                  />
                </ToolTip>
              </Stack>
            ))}
          </Stack>
        </>
      )}
      {otherNotAtReReasonContent && (
        <Stack>
          <Typography sx={{ fontSize: 12 }} variant="h6">
            Other Not at RE Reason
          </Typography>
          <Typography
            sx={{
              fontSize: 12,
            }}
            variant="body1"
          >
            {otherNotAtReReasonContent}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};

export default AtReStatus;

AtReStatus.propTypes = {
  status: PropTypes.string,
  notAtReReasons: PropTypes.array,
};
