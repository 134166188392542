import { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { Formik } from "formik";
import validationSchema from "./validationSchema";
import axios from "src/utils/axios";
import {
  wellnessStatusDescriptions,
  resourceDescriptions,
  currentTreatmentDescriptions,
} from "src/utils/constants";

import {
  ColumnSelect,
  MultiSelect,
  SingleRadioSelect,
  TextInput,
  HiddenLogicComponent,
  FivePointList,
  SubmitButton,
  RobynDisclaimer,
  ContentWrapper,
  Heading,
  FormWrapper,
  ErrorBanner,
} from "src/components/Forms/common";

const initialValues = {
  wellbeingPoint: {
    mindset: null,
    physical: null,
    emotional: null,
    social: null,
    financial: null,
  },
  wellnessStatuses: [],
  age: "",
  pregnancyAmount: null,
  liveBirthAmount: null,
  miscarriageAmount: null,
  resources: [],
  priorDiagnoses: [],
  otherPriorDiagnosis: "",
  currentTreatments: [],
  ivfCycleId: null,
  journeyDifficulties: [],
};

const getOptionsWithDescriptions = (options, descriptions) => {
  return options?.reduce((array, value) => {
    const desc = descriptions.find((desc) => desc.id === value.id);
    let newLabel = value.label;
    if (desc) {
      newLabel = `${value.label} ${desc?.description}`;
    }
    const newValue = {
      id: value.id,
      label: newLabel,
    };
    array.push(newValue);
    return array;
  }, []);
};

const getFormOptions = async () => {
  try {
    const { data: formOptions } = await axios.get("/api/crm/form-options", {
      params: {
        formName: "intake",
      },
    });
    return formOptions;
  } catch (error) {
    throw error;
  }
};

const getClient = async (clientId) => {
  try {
    const { data: client } = await axios.get("/api/clients/public", {
      params: {
        clientId,
      },
    });
    return client;
  } catch (error) {
    throw error;
  }
};

const getForm = async (clientId, formId) => {
  try {
    const { data: form } = await axios.get("/api/clients/public/form/intake", {
      params: {
        clientId,
        formId,
      },
    });

    return form;
  } catch (error) {
    throw error;
  }
};

const IntakeForm = ({ clientId, formId }) => {
  const [formStatus, setFormStatus] = useState("IS_LOADING");
  const [client, setClient] = useState({});

  const [formOptions, setFormOptions] = useState({});

  const handleSubmit = async (values) => {
    setFormStatus("IS_LOADING");
    try {
      const {
        emotional,
        financial,
        mindset,
        physical,
        social,
        wellnessStatuses,
        resources,
        priorDiagnoses,
        currentTreatments,
        journeyDifficulties,
        miscarriageAmount,
        liveBirthAmount,
        pregnancyAmount,
        ...rest
      } = values;

      const adjustedValues = {
        formJourneyPathId: client.formJourneyPathId,
        displayName: client.displayName,
        wellbeingPoint: {
          mindset: parseInt(mindset),
          physical: parseInt(physical),
          emotional: parseInt(emotional),
          social: parseInt(social),
          financial: parseInt(financial),
        },
        resourceIds: resources.map((resource) => resource.id),
        priorDiagnosisIds: priorDiagnoses.map((diagnosis) => diagnosis.id),
        currentTreatmentIds: currentTreatments.map((treatment) => treatment.id),
        journeyDifficultyIds: journeyDifficulties.map(
          (difficulty) => difficulty.id
        ),
        wellnessStatusIds: wellnessStatuses.map((status) => status.id),
        miscarriageAmountId: formOptions.amounts.find(
          (amount) => amount.label === miscarriageAmount
        )?.id,
        liveBirthAmountId: formOptions.amounts.find(
          (amount) => amount.label === liveBirthAmount
        )?.id,
        pregnancyAmountId: formOptions.amounts.find(
          (amount) => amount.label === pregnancyAmount
        )?.id,
        ...rest,
      };

      await axios.put(
        `/api/clients/${clientId}/intake/${formId}`,
        adjustedValues
      );

      setFormStatus("SUCCESS");
    } catch (error) {
      if (error.response.status === 409) {
        setFormStatus("FORM_ALREADY_SUBMITTED");
        return;
      }
      setFormStatus("SUBMIT_ERROR");
    }
  };

  useEffect(() => {
    (async () => {
      try {
        // IF MISSING QUERY PARAMETERS SHOW LOAD ERROR
        if (!clientId || !formId) {
          setClient(null);
          setFormStatus("LOAD_ERROR");
          return;
        }

        const client = await getClient(clientId);
        // IF CLIENT DOES NOT EXIST SHOW SIGN UP FORM
        if (!client) {
          setFormStatus("SHOW_SIGN_UP");
          return;
        }

        setClient(client);

        // IF CLIENT HAS ALREADY SUBMITTED FORM SHOW FORM ALREADY SUBMITTED
        const intakeForm = await getForm(clientId, formId);

        if (!intakeForm) {
          throw new Error("Form not found");
        }

        if (intakeForm.isComplete) {
          setFormStatus("FORM_ALREADY_SUBMITTED");
          return;
        }

        const newFormOptions = await getFormOptions();
        setFormOptions({ ...newFormOptions });

        setFormStatus("SHOW_FORM");
      } catch (error) {
        console.error(error.message);
        setFormStatus("LOAD_ERROR");
      }
    })();
  }, [clientId, formId]);

  return (
    <ContentWrapper>
      <FormWrapper formName="Intake Form" formStatus={formStatus}>
        {/* TODO: Prevent FormWrapper children from rendering without the following conditional wrapper below */}
        {Boolean(formStatus === "SHOW_FORM") && (
          <>
            <Heading
              title={`Welcome ${client?.firstName || ""}!`}
              subtitle="Help us get to know you! That way our fertility coaches can
                    provide support that is specifically for you."
            />

            <Formik
              validateOnBlur={false}
              validateOnChange={false}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({
                values,
                handleChange,
                setFieldError,
                setFieldValue,
                errors,
                handleSubmit,
                isValid,
              }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={2} sx={{ gap: 4.25 }}>
                      {/* WellnessStatus */}
                      <Grid item xs={12}>
                        <MultiSelect
                          heading="As an aspiring parent, have you experienced any of the following?"
                          onChange={(values) => {
                            setFieldValue("wellnessStatuses", values);
                            setFieldError("wellnessStatuses", undefined);
                          }}
                          values={values.wellnessStatuses}
                          options={getOptionsWithDescriptions(
                            formOptions.wellnessStatus,
                            wellnessStatusDescriptions
                          )}
                          hasError={Boolean(errors.wellnessStatuses)}
                        />
                      </Grid>
                      {/* Five Points of Wellbeing */}
                      <Grid item xs={12}>
                        <ColumnSelect
                          errors={errors?.wellbeingPoint || {}}
                          onChange={(name, value) => {
                            setFieldValue("wellbeingPoint", {
                              ...values.wellbeingPoint,
                              [name]: parseInt(value),
                            });
                            setFieldError(`wellbeingPoint[${name}]`, undefined);
                          }}
                          title="Please rate how you feel on a scale of 1-5 in terms of each of the following 5 points of wellbeing:"
                          subTitle={<FivePointList />}
                          options={["1", "2", "3", "4", "5"]}
                          rows={[
                            {
                              name: "mindset",
                              label: "Mindset",
                            },
                            {
                              name: "physical",
                              label: "Physical Self",
                            },
                            {
                              name: "emotional",
                              label: "Emotional Self",
                            },
                            {
                              name: "social",
                              label: "Social Self",
                            },
                            {
                              name: "financial",
                              label: "Financial Self",
                            },
                          ]}
                        />
                      </Grid>
                      {/* Pregnancy History */}
                      <Grid item xs={12}>
                        <ColumnSelect
                          errors={errors}
                          onChange={(name, value) => {
                            setFieldValue(name, value);
                            setFieldError(name, undefined);
                          }}
                          title="Pregnancy history"
                          subTitle="Please note number of miscarriages includes any prior pregnancies that resulted in loss or termination, i.e. ectopic pregnancies, miscarriages, stillbirths and abortions"
                          options={["0", "1", "2", "3", "4+"]}
                          rows={[
                            {
                              name: "pregnancyAmount",
                              label: "Number of pregnancies",
                            },
                            {
                              name: "liveBirthAmount",
                              label: "Number of live births",
                            },
                            {
                              name: "miscarriageAmount",
                              label: "Number of miscarriages",
                            },
                          ]}
                        />
                      </Grid>
                      {/* Fertility Services/Resources */}
                      <Grid item xs={12}>
                        <MultiSelect
                          heading="What additional fertility services or resources have you used during your fertility journey?"
                          onChange={(values) => {
                            setFieldValue("resources", values);
                            setFieldError("resources", undefined);
                          }}
                          values={values.resources}
                          options={getOptionsWithDescriptions(
                            formOptions.resource,
                            resourceDescriptions
                          )}
                          hasError={Boolean(errors.resources)}
                        />
                      </Grid>
                      {/* Prior Diagnosis */}
                      <Grid container item spacing={3}>
                        <Grid item xs={12}>
                          <MultiSelect
                            heading="Have you experienced or been diagnosed with any of the following?"
                            onChange={(values) => {
                              setFieldValue("priorDiagnoses", values);
                              setFieldError("priorDiagnoses", undefined);
                            }}
                            values={values.priorDiagnoses}
                            options={formOptions.priorDiagnosis}
                            hasError={Boolean(errors.priorDiagnoses)}
                          />
                        </Grid>
                        <HiddenLogicComponent
                          value={values.priorDiagnoses?.some((diagnosis) =>
                            ["Not listed"].some(
                              (value) => diagnosis.label === value
                            )
                          )}
                        >
                          <Grid item xs={12}>
                            <TextInput
                              type="text"
                              multiline={true}
                              minRows="2"
                              maxRows="4"
                              label="Please specify what you have experienced or been diagnosed with:"
                              name="otherPriorDiagnosis"
                              value={values?.otherPriorDiagnosis || ""}
                              onChange={(e) => {
                                handleChange(e);
                                setFieldError(e.target.name, undefined);
                              }}
                              error={Boolean(errors.age)}
                              variant="form"
                            />
                          </Grid>
                        </HiddenLogicComponent>
                        <Grid item xs={12}>
                          <TextInput
                            label="What is your age?"
                            inputProps={{
                              inputMode: "numeric",
                              pattern: "[0-9]*",
                              maxLength: "2",
                            }}
                            name="age"
                            value={values.age}
                            onChange={(e) => {
                              handleChange(e);
                              setFieldError(e.target.name, undefined);
                            }}
                            error={Boolean(errors.age)}
                            errorText={errors.age}
                            variant="form"
                          />
                        </Grid>
                      </Grid>
                      {/* Current Treatments */}
                      <Grid item xs={12}>
                        <MultiSelect
                          heading="Are you currently going through or have you previously gone through any type of fertility workup or treatment ?"
                          onChange={(values) => {
                            setFieldValue("currentTreatments", values);
                            setFieldError("currentTreatments", undefined);
                          }}
                          values={values.currentTreatments}
                          options={getOptionsWithDescriptions(
                            formOptions.currentTreatment,
                            currentTreatmentDescriptions
                          )}
                          hasError={Boolean(errors.currentTreatments)}
                        />
                      </Grid>
                      {/* IVF Cycles */}
                      <Grid item xs={12}>
                        <SingleRadioSelect
                          heading="If you've gone through IVF, how many cycles?"
                          value={values.ivfCycleId}
                          onChange={(value) => {
                            setFieldValue("ivfCycleId", value.id);
                            setFieldError("ivfCycleId", undefined);
                          }}
                          options={formOptions.ivfCycle}
                          hasError={Boolean(errors.ivfCycleId)}
                        />
                      </Grid>
                      {/* Journey Difficulty */}
                      <Grid item xs={12}>
                        <MultiSelect
                          heading="What have you found most difficult about the fertility journey?"
                          onChange={(values) => {
                            setFieldValue("journeyDifficulties", values);
                            setFieldError("journeyDifficulties", undefined);
                          }}
                          values={values.journeyDifficulties}
                          options={formOptions.journeyDifficulty}
                          hasError={Boolean(errors.journeyDifficulties)}
                        />
                      </Grid>
                      {!isValid && (
                        <Grid item xs={12}>
                          <ErrorBanner />
                        </Grid>
                      )}
                      <Grid item container justifyContent="center" xs={12}>
                        <SubmitButton disabled={!isValid} />
                      </Grid>
                      <Grid item xs={12}>
                        <RobynDisclaimer />
                      </Grid>
                    </Grid>
                  </form>
                );
              }}
            </Formik>
          </>
        )}
      </FormWrapper>
    </ContentWrapper>
  );
};

export default IntakeForm;
